import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteFriendReferredModal from "./modals/DeleteFriendReferredModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";
import AddFriendsReferredModal from "./modals/AddFriendsReferredModal";

function getFriendsReferred() {

    return fetch('https://back-api.therunningcollective.fr/friends-referred', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class FriendsReferredList extends Component {
    state = {
        errors: null,
        selectedFriendReferred: {
            code: "",
            nbOfCoins: "",
        },
    }

    async componentDidMount() {
        await getFriendsReferred().then(data => {
            if (data && data.length > 0){
                this.setState({friendsReferred: data})
            } else {
                this.setState({errors: "error while getting friendsReferred"})
            }
        }).catch(err =>
            this.setState({errors: "error while getting friendsReferred"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedFriendReferred: {
            title: "",
            body: "",
        }
    });


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter un code de parainage</Button>
                    </Col>
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.friendsReferred && this.state.friendsReferred.length > 0 && this.state.errors == null) ? <span>{this.state.friendsReferred.length} codes de parainage</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des codes de parainage, veuillez vous reconnecter"</p> : null}

                { (!this.state.friendsReferred && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Nombre de TRCoins</th>
                            <th>Lien</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.friendsReferred && this.state.friendsReferred.map(friendReferred => (
                            <tr>
                                <td>{friendReferred.code}</td>
                                <td>{friendReferred.nbOfCoins}</td>
                                <td>{friendReferred.link}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Modifier l'ajout du code de parainage</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                            onClick={(e) => this.setState({isOpen: true, selectedFriendReferred: friendReferred})}/>
                                    </td>
                                </OverlayTrigger>
                                
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer l'ajout du code de parainage</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedFriendReferred: friendReferred})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddFriendsReferredModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedFriendReferred={this.state.selectedFriendReferred}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}

                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteFriendReferredModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedFriendReferred}
                        selectedCategory="friendReferred"
                    />
                    :
                    null
                }
            </div>
        );

    }
}