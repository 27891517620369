import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Accordion, Tabs} from "react-bootstrap";
import {sleep} from "./AddBrandModal";
import {Trash} from "react-feather";
import Moment from "moment";
import {SketchPicker} from "react-color";

export default class UpdateWheelSpinLinkModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

        handleClose = () => {
            if (this.props.selectedWheelSpin.user){
                this.props.selectedWheelSpin.user = this.props.selectedWheelSpin.user[0]
                fetch('https://back-api.therunningcollective.fr/wheel-spin-link', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.props.selectedWheelSpin)
                })
                    .then(async response =>
                        await sleep(2000).then(
                            () => (
                                this.props.closeModal,
                                    window.location.reload(false)
                            )
                        ),
                    )
                    .catch( err =>
                        console.log(err)
                    )
            }

        }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un lien</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Utilisateur concerné : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.props.selectedWheelSpin.user = [...e.target.selectedOptions].map(o => this.props.availableUsers.find(user =>  user.userID === o.value))}}
                                                defaultValue={getSelectedItems(this.props.selectedWheelSpin.user)}
                                            >
                                                {this.props.availableUsers.map((user, index) =>
                                                    <option key={user.userID} value={user.userID}>{user.firstName + " " + user.name}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de fin de validité: </Form.Label>
                                            <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                          defaultValue={this.props.selectedWheelSpin.endDate ? Moment(this.props.selectedWheelSpin.endDate).format('YYYY-MM-DDTHH:mm').toString() : Moment().format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedWheelSpin.endDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.userID)
            )
        )
    }

    return arrayToReturn
}
