import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";


const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 80, label: '80' },
    { value: 80, label: '174' }
]

export default class AddGameModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedGame.logo,
            selectedCover: this.props.selectedGame.cover,
        };
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/game', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedGame)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "games/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedGame.logo = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "games/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedGame.cover = result.message
                        this.setState({selectedCover: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    requestRegion = (event) => {
        this.props.selectedGame.winnersCount = event.value
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un jeu concours</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedGame.name = e.target.value} defaultValue={this.props.selectedGame.name} placeholder="Ex : Alltricks"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadCover(e)}
                                                custom
                                            />
                                            {this.state.selectedCover ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedCover}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedGame.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedGame.description ? this.props.selectedGame.description : this.props.selectedGame.description} placeholder="5 dossards pour les 10km de Paris ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Texte à envoyer au(x) vainqueur(s) : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedGame.textToSend = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedGame.textToSend ? this.props.selectedGame.textToSend : this.props.selectedGame.textToSend} placeholder="RDV chez TTO à 19h"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Nombre de gagnant : </Form.Label>
                                            <Form.Row>
                                                <Select
                                                    options={options}
                                                    defaultValue={this.props.selectedGame.winnersCount ? { label: this.props.selectedGame.winnersCount, value: this.props.selectedGame.winnersCount } : { label: "0", value: 0 }}
                                                    onChange={(e) => this.requestRegion(e)}/>
                                            </Form.Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedGame.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedGame.beginDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de fin : </Form.Label>
                                            <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                          defaultValue={Moment(this.props.selectedGame.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedGame.endDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Afficher uniquement sur l'application : </Form.Label>
                                            <Form.Check aria-label="showProduct"
                                                        defaultChecked={this.props.selectedGame.onlyMobile}
                                                        onChange={(e) => this.props.selectedGame.onlyMobile = e.target.checked} />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}