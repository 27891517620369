import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import {HiOutlineViewList} from "react-icons/hi";
import Moment from "react-moment";

function getPricesNewsletter() {

    return fetch('https://backapi.therunningcollective.fr/api/protected/newsletter/prices', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class NewsletterPricesPageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricesNewsletter: null,
        }

    }

    async componentDidMount() {
        await getPricesNewsletter().then(data => {
            //data.sort((a,b) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0))

            this.setState({pricesNewsletter: data})
        }).catch(err =>
            this.setState({errors: "error while getting pricesNewsletter"})
        );
    };

    renderTooltipImg = (declination) => (
        <Tooltip id="button-tooltip" >
            {declination ?
                <div key={declination.reference}>
                    <div>
                        <img className="imgToDisplay" alt="Couverture" src={"https://img.therunningcollective.fr/" + declination.declinationImg}/>
                    </div>
                    <br/>
                </div>
                : "Indisponible" }
        </Tooltip>
    );


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des pricesNewsletter, veuillez vous reconnecter"</p> : null}

                { (!this.state.pricesNewsletter && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom du produit</th>
                            <th>Référence</th>
                            <th>Prix recherché</th>
                            <th>Email</th>
                            <th>Résolu</th>
                            <th>Date de demande</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.pricesNewsletter.map(price => (
                            <tr
                                key={price.title}
                                id={price.id}
                            >
                                <OverlayTrigger
                                    placement="top"
                                    overlay={this.renderTooltipImg(price)}
                                >
                                    <td>{price.productName}</td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={this.renderTooltipImg(price)}
                                >
                                    <td>{price.declinationReference}</td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={this.renderTooltipImg(price)}
                                >
                                    <td>{price.researchedPrice}</td>
                                </OverlayTrigger>
                                <td>{price.clientEmail} </td>
                                <td>{price.resolved ? "oui" : "non"}</td>
                                <td><Moment to={price.updatedDate}>{Date.now()}</Moment></td>
                                <td>{price.url}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
            </div>
        );

    }
}