import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import {slugify} from "./UpdateRaceModal";


export default class AddEventModal extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state ={
            selectedImage: this.props.selectedEvent.image
        };
    }

    handleClose = () => {
        this.props.selectedEvent.isAutomaticallyValidated = this.state.isAutomaticallyValidated

        fetch('https://back-api.therunningcollective.fr/physical-event', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedEvent)
        })
            .then(() => (
                    sleep(2000),
                        this.props.closeModal,
                        window.location.reload(false)
            )
                )
            .catch( err =>
                console.log(err)
            );
    }

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "physical-events/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedEvent.image = result.message
                        this.setState({selectedImage: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un évènement phyisque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedEvent.name = e.target.value)} defaultValue={this.props.selectedEvent.name} placeholder="Ex : Testing Magic Speed 3"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lieu : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedEvent.town = e.target.value, this.props.selectedEvent.slug = slugify(this.props.selectedEvent.name + "-"+e.target.value))} defaultValue={this.props.selectedEvent.town} placeholder="Ex : Rennes"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de l'évènement : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedEvent.date).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedEvent.date = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedImage ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedImage}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description courte de la vignette</Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedEvent.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedEvent.description ? this.props.selectedEvent.description : this.props.selectedEvent.description} placeholder="Envie de tester la magic speed 3?"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description longue de la page de l'évènement</Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedEvent.longDescription = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedEvent.longDescription ? this.props.selectedEvent.longDescription : this.props.selectedEvent.longDescription} placeholder="Venez tester la nouvelle ASICS Magic Speed 3 à Rennes, sur le parcours du Hill, le 23 octobre 2023 à 18h30. Felix Bour sera aussi présent pour répondre à vos questions. blablabla"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Validation automatique de la participation ? </Form.Label>
                                            <Form.Check aria-label="availableSoon"
                                                        defaultChecked={this.props.selectedEvent.isAutomaticallyValidated}
                                                        onChange={(e) => this.setState({isAutomaticallyValidated: e.target.checked})} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Nombre de places disponible : </Form.Label>
                                            <Form.Row>
                                                <NumericInput min={1}
                                                              max={100000}
                                                              value={this.props.selectedEvent.countOfAttendee}
                                                              onChange={(value) => this.props.selectedEvent.countOfAttendee = value}/>
                                                </Form.Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nombre de TRCoins pour la participation : </Form.Label>
                                            <Form.Row>
                                            <NumericInput min={1}
                                                          max={100000}
                                                          value={this.props.selectedEvent.countOfCoins}
                                                          onChange={(value) => this.props.selectedEvent.countOfCoins = value}/>
                                            </Form.Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL simplifiée : </Form.Label>
                                            <Form.Control disabled type="text" onChange={(e) => this.props.selectedEvent.slug = e.target.value} defaultValue={this.props.selectedEvent.slug ? this.props.selectedEvent.slug : slugify(this.props.selectedEvent.name+"-"+this.props.selectedEvent.town)} placeholder="course-des-saucisses"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}