import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import {slugify} from "./UpdateRaceModal";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';

export default class AddPhysicalShopOptionModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedImage: this.props.selectedPhysicalShopOption.logo,
            searchAddress: "",
            addressFeatures: [],

        };
    }

    handleClose = () => {
        fetch('https://back-api.therunningcollective.fr/physical-shop-option', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedPhysicalShopOption)
        })
            .then(() => (
                    sleep(2000),
                        this.props.closeModal,
                        window.location.reload(false)
            )
                )
            .catch( err =>
                console.log(err)
            );
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une offre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopOption.name = e.target.value)} defaultValue={this.props.selectedPhysicalShopOption.name} placeholder="Ex : Basic"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Prix mensuel (€) : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopOption.monthlyPrice = parseInt(e.target.value, 10))} defaultValue={this.props.selectedPhysicalShopOption.monthlyPrice} placeholder="100"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Prix d'un RDV (€) : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopOption.appointmentPrice = parseInt(e.target.value, 10))} defaultValue={this.props.selectedPhysicalShopOption.appointmentPrice} placeholder="5"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Taux de commission (%) : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopOption.commission = parseInt(e.target.value, 10))} defaultValue={this.props.selectedPhysicalShopOption.commission} placeholder="10"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>RDV offerts : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopOption.freeAppointment = parseInt(e.target.value, 10))} defaultValue={this.props.selectedPhysicalShopOption.freeAppointment} placeholder="5"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}