import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    allRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

import ScrollToTop from "../components/ScrollToTop";
import SignIn from "../pages/auth/SignIn";
import useToken from "../useToken";

const childRoutes = (Layout, routes) =>

    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ name, path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    name={name}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );



function Routes() {
    const { token, setToken } = useToken();

    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    {token ? childRoutes(DashboardLayout, allRoutes) : null}
                    <Route
                        render={() => (
                            <AuthLayout>
                                <SignIn setToken={setToken}/>
                            </AuthLayout>
                        )}
                    />
                </Switch>
            </ScrollToTop>
        </Router>
    );
}

export default Routes;
