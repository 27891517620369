import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import {slugify} from "./UpdateRaceModal";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';

export default class AddPhysicalShopModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedPhysicalShop: this.props.selectedPhysicalShop,
            selectedImage: this.props.selectedPhysicalShop.logo,
            selectedMapImage: this.props.selectedPhysicalShop.mapLogo,
            searchAddress: "",
            addressFeatures: [],
            town: this.props.selectedPhysicalShop.location.town,
            region: this.props.selectedPhysicalShop.location.region,
            zipcode: this.props.selectedPhysicalShop.location.zipCode,
            country: this.props.selectedPhysicalShop.location.country,
            address: this.props.selectedPhysicalShop.location.address,
            lat: this.props.selectedPhysicalShop.location.coordinates.lat,
            long: this.props.selectedPhysicalShop.location.coordinates.long,
            shoes: this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.shoes ? this.props.selectedPhysicalShop.productTypes.shoes : [],
            spikes: this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.spikes? this.props.selectedPhysicalShop.productTypes.spikes : [],
            apparel: this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.apparel? this.props.selectedPhysicalShop.productTypes.apparel : [],
            accessories: this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.accessories? this.props.selectedPhysicalShop.productTypes.accessories : [],
            electronic:  this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.electronic? this.props.selectedPhysicalShop.productTypes.electronic : [],
            food: this.props.selectedPhysicalShop.productTypes && this.props.selectedPhysicalShop.productTypes.food? this.props.selectedPhysicalShop.productTypes.food : [],
        };
        this.onTagsChange = _.debounce(this.onTagsChange,300);
    }

    handleCheckboxShoesChange = (event) => {
        if (event.target.checked) {
          if (!this.state.shoes.includes(event.target.value)) {
            this.setState(prevState => ({ shoes: [...prevState.shoes, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ shoes: prevState.shoes.filter(day => day !== event.target.value) }));
        }
    }
    handleCheckboxSpikesChange = (event) => {
        if (event.target.checked) {
          if (!this.state.spikes.includes(event.target.value)) {
            this.setState(prevState => ({ spikes: [...prevState.spikes, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ spikes: prevState.spikes.filter(day => day !== event.target.value) }));
        }
    }
    handleCheckboxApparelChange = (event) => {
        if (event.target.checked) {
          if (!this.state.apparel.includes(event.target.value)) {
            this.setState(prevState => ({ apparel: [...prevState.apparel, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ apparel: prevState.apparel.filter(day => day !== event.target.value) }));
        }
    }
    handleCheckboxFoodChange = (event) => {
        if (event.target.checked) {
          if (!this.state.food.includes(event.target.value)) {
            this.setState(prevState => ({ food: [...prevState.food, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ food: prevState.food.filter(day => day !== event.target.value) }));
        }
    }
    handleCheckboxElectronicChange = (event) => {
        if (event.target.checked) {
          if (!this.state.electronic.includes(event.target.value)) {
            this.setState(prevState => ({ electronic: [...prevState.electronic, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ electronic: prevState.electronic.filter(day => day !== event.target.value) }));
        }
    }
    handleCheckboxAccessoriesChange = (event) => {
        if (event.target.checked) {
          if (!this.state.accessories.includes(event.target.value)) {
            this.setState(prevState => ({ accessories: [...prevState.accessories, event.target.value]}))
          }
        } else {
          this.setState(prevState => ({ accessories: prevState.accessories.filter(day => day !== event.target.value) }));
        }
    }

    searchAddress = (search) => {
        fetch('https://api-adresse.data.gouv.fr/search/?limit=15&q=' + slugify(search), {
            method: 'GET',
        })
        .then(response => { return response.json();})
        .then(responseData => {return responseData;})
        .then(data => {this.setState({addressFeatures : data.features});})
        .catch( err =>
            console.log(err)
        );
    }

    handleClose = () => {
        this.state.selectedPhysicalShop.location.town = this.state.town
        this.state.selectedPhysicalShop.location.region = this.state.region
        this.state.selectedPhysicalShop.location.country = this.state.country
        this.state.selectedPhysicalShop.location.address = this.state.address
        this.state.selectedPhysicalShop.location.zipCode = this.state.zipcode
        this.state.selectedPhysicalShop.location.coordinates.lat = this.state.lat
        this.state.selectedPhysicalShop.location.coordinates.long = this.state.long
        this.state.selectedPhysicalShop.productTypes.shoes = this.state.shoes
        this.state.selectedPhysicalShop.productTypes.spikes = this.state.spikes
        this.state.selectedPhysicalShop.productTypes.apparel = this.state.apparel
        this.state.selectedPhysicalShop.productTypes.accessories = this.state.accessories
        this.state.selectedPhysicalShop.productTypes.electronic = this.state.electronic
        this.state.selectedPhysicalShop.productTypes.food = this.state.food

        console.log(this.state.selectedPhysicalShop)
        console.log(this.state.shoes)

        fetch('https://back-api.therunningcollective.fr/physical-shop', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.selectedPhysicalShop)
        })
            .then(() => (
                    sleep(2000),
                        this.props.closeModal,
                        window.location.reload(false)
            )
                )
            .catch( err =>
                console.log(err)
            );
    }

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "physical-shops/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.state.selectedPhysicalShop.logo = result.message
                        this.setState({selectedImage: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadMapLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "physical-shops/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.state.selectedPhysicalShop.mapLogo = result.message
                    this.setState({selectedMapImage: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    onTagsChange = (event, values) => {
        var new_str = values.replace(' ', '+')

        fetch('https://api-adresse.data.gouv.fr/search/?limit=100&q=' + new_str, {
            method: 'GET',
        })
        .then(response => { return response.json();})
        .then(responseData => {return responseData;})
        .then(data => {console.log(data.features); this.setState({addressFeatures : data.features});})
        .catch( err =>
            console.log(err)
        );
    }

    onSelectTag = (event, values) => {
        const region = values.properties.context.split(',');
        this.setState({town: values.properties.city})
        this.setState({zipcode: values.properties.postcode})
        this.setState({region: region[2]})
        this.setState({address: values.properties.name})
        this.setState({country: "France"})
        this.setState({lat: values.geometry.coordinates[1]})
        this.setState({long: values.geometry.coordinates[0]})
      }  

      handleChange(e, physicalShopOptions) {
        const optionID = e.target.value;
        const option = physicalShopOptions.find(u => u.name === optionID);
        this.state.selectedPhysicalShop.physicalShopOptionID = option.id;
        this.state.selectedPhysicalShop.offerType = option.name;
      }

    render(){
        if(!this.state.selectedPhysicalShop.productTypes){
            this.setState(prevState => {
                let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                selectedPhysicalShop.productTypes = {}                    // update the name property, assign a new value                 
                return { selectedPhysicalShop };                                 // return new object jasper object
              })
        }
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un magasin phyisque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Informations générales</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Horaire ouvertures </Nav.Link>
                                    </Nav.Item> 
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Types de produits </Nav.Link>
                                    </Nav.Item>                           
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.state.selectedPhysicalShop.name = e.target.value)} defaultValue={this.state.selectedPhysicalShop.name} placeholder="Ex : Terre De Running - Lannion"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Type de flux produit : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.state.selectedPhysicalShop.typeOfProductFeed = e.target.value}
                                                defaultValue={this.state.selectedPhysicalShop.typeOfProductFeed}
                                            >
                                                <option >Ajouter...</option>
                                                <option key="RC" value="RunningConseil">RunningConseil</option>
                                                <option key="TTO" value="tontonOutdoor">TontonOutdoor</option>
                                                <option key="TDR" value="TerreDeRunning">TerreDeRunning</option>
                                                <option key="Sohbi" value="SohbiSport">SohbiSport</option>
                                                <option key="irun" value="i-Run">i-Run</option>
                                                <option key="irun" value="Decathlon">Decathlon</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL du flux produit : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.state.selectedPhysicalShop.productsFeedURL = e.target.value)} defaultValue={this.state.selectedPhysicalShop.productsFeedURL} placeholder="https://toto.fr/url"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Offre : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={e => this.handleChange(e, this.props.physicalShopOptions)}
                                                defaultValue={this.state.selectedPhysicalShop.offerType}
                                            >
                                                <option >Ajouter...</option>
                                                {
                                                    this.props.physicalShopOptions && this.props.physicalShopOptions.map((option,index) => {
                                                        return(
                                                            <option key={option.id} value={option.name}>{option.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo du magasin : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedImage ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedImage}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo du magasin sur une carte : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadMapLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedMapImage ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedMapImage}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Autocomplete
                                                id="free-solo-demo"
                                                // calling the freeSolo prop inside the Autocomplete component
                                                freeSolo
                                                options={this.state.addressFeatures}
                                                defaultValue={{ properties: {name: this.state.address, postcode: this.state.zipcode, city: this.state.town} }}
                                                renderInput={(params) => <TextField {...params} label="Adresse" />}
                                                getOptionLabel={(option) => option.properties.name != "" &&  option.properties.postcode != "" && option.properties.city != "" &&
                                                    option.properties.name + " (" + option.properties.postcode + " - " + option.properties.city + ")" || ""
                                                }
                                                onChange={this.onSelectTag} // click on the show tags
                                                onInputChange={this.onTagsChange}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Code postal : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.zipcode} defaultValue={this.state.selectedPhysicalShop.location.zipCode} placeholder="Ex : 22300"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Ville : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.town} defaultValue={this.state.selectedPhysicalShop.location.town} placeholder="Ex : Lannion"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Région : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.region} defaultValue={this.state.selectedPhysicalShop.location.region} placeholder="Ex : Bretagne"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Pays : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.country} defaultValue={this.state.selectedPhysicalShop.location.country} placeholder="Ex : France"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Latitude : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.lat} defaultValue={this.state.selectedPhysicalShop.location.lat} placeholder="Ex : 5.123"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Longitude : </Form.Label>
                                            <Form.Control disabled type="text" value={this.state.long} defaultValue={this.state.selectedPhysicalShop.location.long} placeholder="Ex : -9.987"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <h2>Lundi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.monday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.monday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })}                                                                   
                                                            />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.monday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.monday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.monday.morning.begin = e.target.value                    // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })} 
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.monday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.monday.morning.end = e.target.value                    // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })} 
                                                                                                                                  />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.monday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.monday.evening.begin = e.target.value                    // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                             />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.monday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.monday.evening.end = e.target.value                    // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                             />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                            
                                            : null
                                        }                                                                          
                                        <h2>Mardi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.tuesday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.tuesday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })}                                                                />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.tuesday.closed ? 
                                            <div>
                                                <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Matin : </Form.Label>
                                                    <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                        defaultValue={this.state.selectedPhysicalShop.openingHours.tuesday.morning.begin}
                                                        onChange={(e) => 
                                                            this.setState(prevState => {
                                                                let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                selectedPhysicalShop.openingHours.tuesday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                return { selectedPhysicalShop };                                 // return new object jasper object
                                                              })}                                                       />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                        defaultValue={this.state.selectedPhysicalShop.openingHours.tuesday.morning.end}
                                                        onChange={(e) => 
                                                            this.setState(prevState => {
                                                                let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                selectedPhysicalShop.openingHours.tuesday.morning.end = e.target.value                    // update the name property, assign a new value                 
                                                                return { selectedPhysicalShop };                                 // return new object jasper object
                                                              })}                                                      />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Après-midi : </Form.Label>
                                                    <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                        defaultValue={this.state.selectedPhysicalShop.openingHours.tuesday.evening.begin}
                                                        onChange={(e) => 
                                                            this.setState(prevState => {
                                                                let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                selectedPhysicalShop.openingHours.tuesday.evening.begin = e.target.value                    // update the name property, assign a new value                 
                                                                return { selectedPhysicalShop };                                 // return new object jasper object
                                                              })}                                                      />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                        defaultValue={this.state.selectedPhysicalShop.openingHours.tuesday.evening.end}
                                                        onChange={(e) => 
                                                            this.setState(prevState => {
                                                                let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                selectedPhysicalShop.openingHours.tuesday.evening.end = e.target.value                    // update the name property, assign a new value                 
                                                                return { selectedPhysicalShop };                                 // return new object jasper object
                                                              })}                                                      />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                            </div>
                                            
                                            : null
                                        }                                               
                                        <h2>Mercredi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.wednesday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.wednesday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })}                                                                 />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.wednesday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.wednesday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.wednesday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}  
                                                                                                                                  />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.wednesday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.wednesday.morning.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.wednesday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.wednesday.evening.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.wednesday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.wednesday.evening.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>                                  
                                            : null
                                        } 
                                        
                                        <h2>Jeudi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.thursday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.thursday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })}                                                               
                                                                  />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.thursday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.thursday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.thursday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.thursday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.thursday.morning.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.thursday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.thursday.evening.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.thursday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.thursday.evening.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>                                  
                                            : null
                                        } 
                                        
                                        <h2>Vendredi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.friday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.friday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })}  
                                                                                                                              />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.friday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.friday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.friday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.friday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.friday.morning.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.friday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.friday.evening.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.friday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.friday.evening.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>                                  
                                            : null
                                        } 
                                        
                                        <h2>Samedi</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.saturday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.saturday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })} 
                                                                                                                              />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.saturday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.saturday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.saturday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.saturday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.saturday.morning.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.saturday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.saturday.evening.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.saturday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.saturday.evening.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>                                  
                                            : null
                                        } 
                                        
                                        <h2>Dimanche</h2>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Fermé ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                            defaultChecked={this.state.selectedPhysicalShop.openingHours.sunday.closed}
                                                            onChange={(e) => 
                                                                this.setState(prevState => {
                                                                    let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                    selectedPhysicalShop.openingHours.sunday.closed = e.target.checked;                     // update the name property, assign a new value                 
                                                                    return { selectedPhysicalShop };                                 // return new object jasper object
                                                                  })} 
                                                                                                                              />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.selectedPhysicalShop.openingHours.sunday.closed ? 
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Matin : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.sunday.morning.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.sunday.morning.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.sunday.morning.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.sunday.morning.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Après-midi : </Form.Label>
                                                            <Form.Control key="beginDate" type="time" name="begin" placeholder="Début"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.sunday.evening.begin}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.sunday.evening.begin = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>&nbsp;</Form.Label>
                                                            <Form.Control key="endDate" type="time" name="end" placeholder="Fin"
                                                                defaultValue={this.state.selectedPhysicalShop.openingHours.sunday.evening.end}
                                                                onChange={(e) => 
                                                                    this.setState(prevState => {
                                                                        let selectedPhysicalShop = Object.assign({}, prevState.selectedPhysicalShop);  // creating copy of state variable jasper
                                                                        selectedPhysicalShop.openingHours.sunday.evening.end = e.target.value                     // update the name property, assign a new value                 
                                                                        return { selectedPhysicalShop };                                 // return new object jasper object
                                                                      })}                                                              />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>                                  
                                            : null
                                        }                        
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Row>
                                            <Col xs={3}>
                                                <div>Chaussures</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Route"
                                                            name="Route"
                                                            value="Route"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.shoes && this.state.selectedPhysicalShop.productTypes.shoes.includes("Route")}
                                                            onChange={(e) => this.handleCheckboxShoesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Chemin"
                                                            name="Chemin"
                                                            value="Chemin"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.shoes && this.state.selectedPhysicalShop.productTypes.shoes.includes("Chemin")}
                                                            onChange={(e) => this.handleCheckboxShoesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Trail"
                                                            name="Trail"
                                                            value="Trail"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.shoes && this.state.selectedPhysicalShop.productTypes.shoes.includes("Trail")}
                                                            onChange={(e) => this.handleCheckboxShoesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Carbone"
                                                            name="Carbone"
                                                            value="Carbone"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.shoes && this.state.selectedPhysicalShop.productTypes.shoes.includes("Carbone")}
                                                            onChange={(e) => this.handleCheckboxShoesChange(e)} 
                                                    />
                                                    </Form.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <div>Pointes</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Cross"
                                                            name="Cross"
                                                            value="Cross"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.spikes && this.state.selectedPhysicalShop.productTypes.spikes.includes("Cross")}
                                                            onChange={(e) => this.handleCheckboxSpikesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Demi-fond"
                                                            name="Demi-fond"
                                                            value="Demi-fond"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.spikes && this.state.selectedPhysicalShop.productTypes.spikes.includes("Demi-fond")}
                                                            onChange={(e) => this.handleCheckboxSpikesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Sprint"
                                                            name="Sprint"
                                                            value="Sprint"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.spikes && this.state.selectedPhysicalShop.productTypes.spikes.includes("Sprint")}
                                                            onChange={(e) => this.handleCheckboxSpikesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Sauts"
                                                            name="Sauts"
                                                            value="Sauts"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.spikes && this.state.selectedPhysicalShop.productTypes.spikes.includes("Sauts")}
                                                            onChange={(e) => this.handleCheckboxSpikesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Lancers"
                                                            name="Lancers"
                                                            value="Lancers"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.spikes && this.state.selectedPhysicalShop.productTypes.spikes.includes("Lancers")}
                                                            onChange={(e) => this.handleCheckboxSpikesChange(e)} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <div>Vêtements</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Collants"
                                                            name="Collants"
                                                            value="Collants"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Collants")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                     <Form.Check aria-label="availableSoon"
                                                            label="Compression"
                                                            name="Compression"
                                                            value="Compression"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Compression")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Débardeurs"
                                                            name="Débardeurs"
                                                            value="Débardeurs"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Débardeurs")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="T-Shirts"
                                                            name="T-Shirts"
                                                            value="T-Shirts"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("T-Shirts")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Pull Zippé"
                                                            name="Pull Zippé"
                                                            value="Pull Zippé"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Pull Zippé")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />                                             
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Shorts"
                                                            name="Shorts"
                                                            value="Shorts"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Shorts")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Cuissards"
                                                            name="Cuissards"
                                                            value="Cuissards"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Cuissards")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Sous-vêtements"
                                                            name="Sous-vêtements"
                                                            value="Sous-vêtements"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Sous-vêtements")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Training"
                                                            name="Training"
                                                            value="Training"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Training")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Vestes"
                                                            name="Vestes"
                                                            value="Vestes"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.apparel && this.state.selectedPhysicalShop.productTypes.apparel.includes("Vestes")}
                                                            onChange={(e) => this.handleCheckboxApparelChange(e)} 
                                                    />
                                                    </Form.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <div>Accessoires</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Batons de marche"
                                                            name="Batons de marche"
                                                            value="Batons de marche"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Batons de marche")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Bonnets"
                                                            name="Bonnets"
                                                            value="Bonnets"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Bonnets")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Gants"
                                                            name="Gants"
                                                            value="Gants"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Gants")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Porte dossard"
                                                            name="Porte dossard"
                                                            value="Porte dossard"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Ceinture / Porte dossard")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Bandeaux"
                                                            name="Bandeaux"
                                                            value="Bandeaux"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Bandeaux")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Casquettes"
                                                            name="Casquettes"
                                                            value="Casquettes"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Casquettes")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Chaussettes"
                                                            name="Chaussettes"
                                                            value="Chaussettes"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Chaussettes")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Lunettes de vitesse"
                                                            name="Lunettes de vitesse"
                                                            value="Lunettes de vitesse"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Lunettes de vitesse")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Manchettes"
                                                            name="Manchettes"
                                                            value="Manchettes"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Manchettes")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Sac à dos"
                                                            name="Sac à dos"
                                                            value="Sac à dos"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.accessories && this.state.selectedPhysicalShop.productTypes.accessories.includes("Sac à dos")}
                                                            onChange={(e) => this.handleCheckboxAccessoriesChange(e)} 
                                                    />
                                                    </Form.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <div>Electronique</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Montres GPS"
                                                            name="Montres GPS"
                                                            value="Montres GPS"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Montres GPS")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Casques audio"
                                                            name="Casques audio"
                                                            value="Casques audio"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Casques audio")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Electrostimulation"
                                                            name="Electrostimulation"
                                                            value="Electrostimulation"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Electrostimulation")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Lampes frontales"
                                                            name="Lampes frontales"
                                                            value="Lampes frontales"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Lampes frontales")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Récupération"
                                                            name="Récupération"
                                                            value="Récupération"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Récupération")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Pressothérapie"
                                                            name="Pressothérapie"
                                                            value="Pressothérapie"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("Pressothérapie")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="GPS Randonnée"
                                                            name="GPS Randonnée"
                                                            value="GPS Randonnée"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.electronic && this.state.selectedPhysicalShop.productTypes.electronic.includes("GPS Randonnée")}
                                                            onChange={(e) => this.handleCheckboxElectronicChange(e)} 
                                                    />
                                                    </Form.Group>
                                            </Col>
                                            <Col xs={3}>
                                                <div>Nutrition</div>
                                                <Form.Group>
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Barres"
                                                            name="Barres"
                                                            value="Barres"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.food && this.state.selectedPhysicalShop.productTypes.food.includes("Barres")}
                                                            onChange={(e) => this.handleCheckboxFoodChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Boissons"
                                                            name="Boissons"
                                                            value="Boissons"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.food && this.state.selectedPhysicalShop.productTypes.food.includes("Boissons")}
                                                            onChange={(e) => this.handleCheckboxFoodChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Compléments"
                                                            name="Compléments"
                                                            value="Compléments"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.food && this.state.selectedPhysicalShop.productTypes.food.includes("Compléments")}
                                                            onChange={(e) => this.handleCheckboxFoodChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Gels"
                                                            name="Gels"
                                                            value="Gels"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.food && this.state.selectedPhysicalShop.productTypes.food.includes("Gels")}
                                                            onChange={(e) => this.handleCheckboxFoodChange(e)} 
                                                    />
                                                    <Form.Check aria-label="availableSoon"
                                                            label="Récupération"
                                                            name="Récupération"
                                                            value="Récupération"
                                                            defaultChecked={this.state.selectedPhysicalShop.productTypes && this.state.selectedPhysicalShop.productTypes.food && this.state.selectedPhysicalShop.productTypes.food.includes("Récupération")}
                                                            onChange={(e) => this.handleCheckboxFoodChange(e)} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                                
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>                     
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}