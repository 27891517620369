import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Mail, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";

function getAttendeeLeagues() {

    return fetch('https://back-api.therunningcollective.fr/attendees-forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getForecasts() {

    return fetch('https://back-api.therunningcollective.fr/forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getForecastName(forecastID, forecasts){
    let output 
    forecasts.map(forecast => {
        if (forecast.id == forecastID){
            output = forecast.name
        }
    })
    //console.log(output)
    return output
}

export class AttendeeLeaguesList extends Component{
    state = {
        leagues: [],
        errors: null,
        selectedLeague: {},
    }

    async componentDidMount() {
        await getForecasts().then(data => {
            this.setState({forecasts: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecasts"})
        );
        await getAttendeeLeagues().then(data => {
            this.setState({ leagues: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting leagues"})
        );
    };

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={6} className="products-buttons">
                        <span>Nombre de participants : {this.state.leagues.length}</span>
                    </Col>
                </Row>
                { (!this.state.leagues) ? <p> Aucun participant.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Pseudo</th>
                            <th>Prénom</th>
                            <th>Nom</th>
                            <th>Email</th>
                            <th>Evenement</th>
                            <th>Date de création</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.leagues.length > 0 && this.state.leagues.map(reward => (
                            <tr key={reward.userID}>
                                <td>{reward.pseudo}</td>
                                <td>{reward.userInfo.firstName}</td>
                                <td>{reward.userInfo.lastName}</td>
                                <td>{reward.userInfo.email}</td>
                                <td>{getForecastName(reward.id, this.state.forecasts)}</td>
                                <td>{Moment(reward.createdDate).format('DD-MM-YYYY').toString()}</td>
                                <td className="table-action" style={{cursor: "pointer"}}>
                                    <Mail className="align-middle" size={18}
                                          onClick={() => window.location = 'mailto:'+reward.userInfo.email}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }
            </div>
        );

    }

}