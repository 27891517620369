import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';

export default class AddQRCodeModal extends Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/qr-code', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedQRCode)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );
    
    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Créer un qr code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedQRCode.name = e.target.value} defaultValue={this.props.selectedQRCode.name} placeholder="Page Podcasts"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedQRCode.link = e.target.value} defaultValue={this.props.selectedQRCode.link} placeholder="https://app.therunningcollective.fr/podcasts"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => this.handleClose()}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}