import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";


export default class AddAdidasVouchers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploaded: false,
        }
    }
     uploadAdidasVouchers = (e) => {
        let file = e.target.files[0]
        const formData = new FormData();
        formData.append('file', file);

        fetch('https://backapi.therunningcollective.fr/api/protected/adidas-vouchers', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            },
            body: formData
        }).then((res) =>
            this.setState({isUploaded: true})
        )
            .catch( err =>
                console.log(err)
            );
    }

    handleClose = () => {
        window.location.reload(false)
    }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter des coupons de réductions adidas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        {!this.state.isUploaded ?
                                            <Form.Group >
                                                <Form.Label>Fichiers de coupons : </Form.Label>
                                                <Form.File
                                                    id="custom-first-img-translate-html"
                                                    label="Ajouter un fichier"
                                                    data-browse="Ajouter"
                                                    onChange= {(e) => {this.uploadAdidasVouchers(e)}}
                                                    custom
                                                />
                                            </Form.Group>
                                        :
                                            <Form.Group >
                                                <Form.Label>Fichier téléchargé</Form.Label>

                                            </Form.Group>
                                    }


                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}