import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddLanguageModalForm from "./modals/AddLanguageModal";
import DeleteLanguageModal from "./modals/DeleteLanguageModal";
import Logout from "./Logout";

function getLanguages() {

    return fetch('https://back-products-api.therunningcollective.fr/languages', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class LanguagesList extends Component{
    state = {
        languages: null,
        isOpen: false,
        errors: null,
        isDelete: false,
        selectedLanguage: {
            name : ""
        }
    }

    async componentDidMount() {
        await getLanguages().then(data => {
            this.setState({ languages: data })
        }).catch(err =>
            this.setState({errors: "error while getting languages"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedLanguage: { name : "", } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une langue</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup  value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode : e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                

                { !this.state.languages ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Logo</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.languages.map(language => (
                            <tr>
                                <td>{language.name}</td>
                                <td><img className="imgToDisplay" alt="Logo..." src={language.s3Flag}/></td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedLanguage : language})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18} onClick={(e) => this.setState({isDelete : true, selectedLanguage : language})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddLanguageModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedLanguage={this.state.selectedLanguage}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteLanguageModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedLanguage}
                        selectedCategory="language"
                    />
                    :
                    null
                }
            </div>
        );

    }

}