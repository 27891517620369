import React, {useState} from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import avatar from "../../assets/img/brands/LOGO-TRC-9.jpg";

async function loginUser(credentials) {
  return fetch('https://back-api.therunningcollective.fr/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
      .then(data => data.json())
}

async function loginDIYUser(credentials) {
  return fetch('https://back-api.therunningcollective.fr/diy-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
      .then(data => data.json())
}

function SignIn({ setToken }) {
  const [login, setUserName] = useState();
  const [password, setPassword] = useState();
  const [diy, setDIY] = useState();
  const [isLogged, setIsLogged] = useState(false);
  const [error, setError] = useState("");
  const [requestID, setRequestID] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    const logged = await loginUser({
      login,
      password
    });
    if (logged.message){
      setError(logged.message)
    } else {
      setRequestID(logged.requestID);
      setIsLogged(true);
    }
  }

  const handleDIYSubmit = async e => {
    e.preventDefault();
    const token = await loginDIYUser({
      login,
      diy,
      requestID
    });
    if (token.msg && token.msg === "invalid user credentials"){
      setError(token.msg)
    } else {
      setToken(token);
    }
  }

  return(
    <React.Fragment>
      {
        !isLogged ?
            <Card style={{border: "2px solid black"}}>
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <img
                        src={avatar}
                        alt="Chris Wood"
                        className="img-fluid rounded-circle"
                        width="132"
                        height="132"
                    />
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label>Login</Label>
                      <Input
                          bsSize="lg"
                          type="email"
                          name="email"
                          placeholder="Entrez votre login"
                          onChange={e => setUserName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          placeholder="Entrez votre mot de passe"
                          onChange={e => setPassword(e.target.value)}
                      />
                    </FormGroup>
                    {error && error !== "" ? <span> Retente ta chance :)</span> : null}
                    <div className="text-center mt-3">
                      <Link to="/">
                        <Button color="primary" size="lg"onClick={handleSubmit} style={{background: "black", border: "1px solid black"}}>
                          Se connecter
                        </Button>
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
            :
            <Card>
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center">
                    <img
                        src={avatar}
                        alt="Chris Wood"
                        className="img-fluid rounded-circle"
                        width="132"
                        height="132"
                    />
                  </div>
                  <Form onSubmit={handleDIYSubmit}>
                    <FormGroup>
                      <Label>Code reçu</Label>
                      <Input
                          bsSize="lg"
                          type="email"
                          name="email"
                          placeholder="Entrez le code reçu"
                          onChange={e => setDIY(e.target.value)}
                      />
                    </FormGroup>
                    {error && error !== "" ? <span> Mauvais code :)</span> : null}
                    <div className="text-center mt-3">
                      <Link to="/">
                        <Button color="primary" size="lg"onClick={handleDIYSubmit} style={{background: "black", border: "1px solid black"}}>
                          Se connecter
                        </Button>
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
      }
    </React.Fragment>
  );
}

export default SignIn;
