import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";


const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 80, label: '80' },
    { value: 80, label: '174' }
]

export default class AddForecastAthleteModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            forecasts: this.props.forecasts,
        };
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/forecast-athlete', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedForecastAthlete)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        );

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un athlète</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecastAthlete.name = e.target.value} defaultValue={this.props.selectedForecastAthlete.name} placeholder="Ex : Wayde VAN NIEKERK"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Sexe : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecastAthlete.gender = e.target.value}
                                                defaultValue={this.props.selectedForecastAthlete.gender}
                                            >
                                                <option >Ajouter...</option>
                                                <option value="Hommes">Hommes</option>
                                                <option value="Femmes">Femmes</option>
                                                <option value="Mixte">Mixte</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>World Athletics Link : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecastAthlete.worldAthleticsLink = e.target.value} defaultValue={this.props.selectedForecastAthlete.worldAthleticsLink} placeholder="Ex : https://worldathletics.org/athletes/south-africa/wayde-van-niekerk-14417677"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date d'anniversaire : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedForecastAthlete.birthday).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedForecastAthlete.birthday = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}