import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddCateogryModalForm from "./modals/AddCategoryModal";
import DeleteCategoryModal from "./modals/DeleteCategoryModal";
import Logout from "./Logout";

function getCategories(headCategory) {
    let parentCategory = headCategory.split("/category").pop()
    return fetch('https://back-products-api.therunningcollective.fr/categories?headCategory='+parentCategory, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class CategoriesList extends Component{
    state = {
        categories: null,
        isDelete: false,
        errors: null,
        isOpen: false,
        availableCategories: null,
        headCategory: this.props.location.pathname,
        selectedCategory: {
            name : "",
            subCategory: ""
        }
    }

    async componentDidMount() {
        await getCategories(this.state.headCategory).then(data => {
            this.setState({ categories: data })
        }).catch(() =>
            this.setState({errors: "error while getting categories"})
        );
    };

    closeSubCategoryModal = () => this.setState({ isSubCategoryOpen: false, selectedCategory: { name : "", } });
    closeModal = () => this.setState({ isOpen: false, selectedCategory: { name : "", } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit" onClick={() => this.setState({isOpen : true})}>Ajouter une categorie</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup  value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode : e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                

                { !this.state.categories ? <p> Aucune valeur disponible ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th> </th>
                            <th> </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.categories.map((category,id) => (
                            <tr key={category.id}>
                                <td>{category}</td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={() => this.setState({isOpen : true, selectedCategory : category})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18} onClick={() => this.setState({isDelete : true, selectedCategory : category})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddCateogryModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedCategory={this.state.selectedCategory}
                        headCategory={this.state.headCategory}
                        availableCategories={this.state.categories}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteCategoryModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedCategory}
                        selectedCategory={this.state.headCategory? this.state.headCategory.split("/category").pop() :  ""}
                    />
                    :
                    null
                }
            </div>
        );

    }

}