import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton,Spinner, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeletePushNotificationModal from "./modals/DeletePushNotificationModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";
import InfiniteScroll from "react-infinite-scroll-component";

function getPushNotifications(lastEvaluatedKey) {
    let body = null
    if (lastEvaluatedKey) {
        body = JSON.stringify(lastEvaluatedKey)
    }

    return fetch('https://back-api.therunningcollective.fr/push-notifications-not-sent', {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getUsersWithPushNotificationsTokens() {

    return fetch('https://back-api.therunningcollective.fr/front-users-with-push-notification-token', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(notification, checked) {
    notification.active = checked
    return fetch('https://back-api.therunningcollective.fr/push-notification', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(notification)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class PushNotificationsList extends Component {
    state = {
        errors: null,
        selectedNotification: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: "",
            types: [""]
        },
        availableTypes: ["Athlétisme", "Running", "Trail"]
    }

    async componentDidMount() {
        await getPushNotifications().then(data => {
            this.setState({notifications: data.pushNotifications, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false,})

        }).catch(err =>
            this.setState({errors: "error while getting notifications"})
        );

        await getUsersWithPushNotificationsTokens().then(data => {
            this.setState({availableUsers: data})
        }).catch(err =>
            this.setState({errors: "error while getting users with tokens"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedNotification: {
            title: "",
            body: "",
            sendDate: "",
            link: "",
        }
    });

    async fetchMorePushNotification(lastEvaluatedKey){
        await getPushNotifications(lastEvaluatedKey).then(data => (  
            this.setState({notifications: [...this.state.notifications, ...data.pushNotifications ], lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false,})
        )).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
            }
        );
    }


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    {
                        this.state.availableUsers && this.state.availableUsers.length > 0 ?
                            <Col sm={11} className="products-buttons">
                                <Button className="add-product-button " variant="secondary" type="submit"
                                        onClick={(e) => this.setState({isOpen: true})}>Programmer l'envoi d'une notification</Button>
                            </Col>
                        : null
                    }
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.notifications && this.state.notifications.length > 0 && this.state.errors == null) ? <span>{this.state.notifications.length} notifications</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des notifications, veuillez vous reconnecter"</p> : null}

                { (!this.state.notifications && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    this.state.notifications.length == 0 ?
                        <p> Aucune notification n'est programmée.</p>
                        :
                        <InfiniteScroll
                                scrollThreshold="50%"
                                style={{overflow: "inherit"}}
                                dataLength={this.state.notifications.length}
                                next={() => this.fetchMorePushNotification(this.state.lastEvaluatedKey)}
                                hasMore={this.state.hasMoreValue}
                                loader={<div style={{textAlign: 'center'}}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>}
                            >
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Corps de texte</th>
                            <th>Lien</th>
                            <th>Date d'envoi</th>
                            <th>Utilisateurs concernés</th>
                            <th>Envoyé</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.notifications.map(notification => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher la notification sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={notification.active}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(notification, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{notification.title}</td>
                                <td>{notification.body}</td>
                                <td>{notification.link}</td>
                                <td>{new Date(notification.sendDate).toLocaleDateString() + " à " + new Date(notification.sendDate).toLocaleTimeString()}</td>
                                <td>{notification.users ? notification.users.length : 0}</td>
                                <td>{notification.sent == false ? "Non" : "Oui"}</td>
                                {
                                this.state.availableUsers && this.state.availableUsers.length > 0 ?
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Modifier la notification</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                            onClick={(e) => this.setState({isOpen: true, selectedNotification: notification})}/>
                                    </td>
                                </OverlayTrigger>
                                : null
                            }
                                
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer la notification</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedNotification: notification})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </InfiniteScroll>
                }
                {this.state.isOpen ?
                    <AddNotificationModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedNotification={this.state.selectedNotification}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}

                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeletePushNotificationModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedNotification}
                        selectedCategory="push-notification"
                    />
                    :
                    null
                }
            </div>
        );

    }
}

function isSent(arr) {
    return arr.filter(function(x) {
        return x.sent === false;
    });
}