import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Settings,
  User,
} from "react-feather";

import avatar1 from "../assets/img/avatars/avatar.jpg";
import axios from "axios";
import {CiWarning} from "react-icons/ci";

const NavbarComponent = ({ dispatch }) => {

    function getAttendeeRewards() {
        let query = 'https://back-api.therunningcollective.fr/user-attendee-rewards-not-sent'
        axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            }
            ,})
            .then((res) => {
                console.log(res); setAttendeeRewards(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getAttendeeRewards()
    }, [])

    const [attendeeRewards, setAttendeeRewards] = useState(null)

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
        {
            attendeeRewards && attendeeRewards.length > 0 ?
                <div style={{marginLeft: "200px", cursor: "pointer"}} onClick={() => window.location = "/attendee-rewards"}>
                    <CiWarning size={"2em"}/>    <a style={{color : "red"}}>{attendeeRewards.length}</a> récompenses à envoyer
                </div>
                : null
        }
      <Collapse navbar>
        <Nav className="ml-auto" navbar>

          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                {JSON.parse(localStorage.getItem('token')).img ?
                    <img
                        className="avatar img-fluid rounded-circle mr-1"
                        alt="Couverture actuelle"
                        src={JSON.parse(localStorage.getItem('token')).img}
                    />
                    :
                    <img
                        src={avatar1}
                        className="avatar img-fluid rounded-circle mr-1"
                        alt="Chris Wood"
                    />
                }
                <span className="text-dark">{JSON.parse(localStorage.getItem('token')).firstName} {JSON.parse(localStorage.getItem('token')).name}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem href="/profile">
                <User size={18} className="align-middle mr-2" />
                Mon profil
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="/logout">Se déconnecter</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
