import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import {slugify} from "./UpdateProductModal";


export default class AddLandingPageModalForm extends Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/landing-page', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedLandingPage)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une landing page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Titre : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedLandingPage.title = e.target.value} defaultValue={this.props.selectedLandingPage.title} placeholder="Ex : Black Friday"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control  as="textarea" rows={4} onChange={(e) => this.props.selectedLandingPage.description = e.target.value} defaultValue={this.props.selectedLandingPage.description} placeholder="Ex : Cette page..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Mots clés : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedLandingPage.keywords = e.target.value} defaultValue={this.props.selectedLandingPage.keywords} placeholder="Ex : Maxi promotions"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Catégorie de produits : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => this.props.selectedLandingPage.productsCategory = e.target.value}
                                                defaultValue={this.props.selectedLandingPage.productsCategory}
                                            >
                                                {
                                                    this.props.productCategories && this.props.productCategories.map((category, index) => {
                                                        return (
                                                            <option key={index} value={category}>{category}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Catégorie Wordpress : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={(e) => this.props.selectedLandingPage.blogCategory = parseInt(e.target.value)}
                                                defaultValue={this.props.selectedLandingPage.blogCategory}
                                            >
                                                <option key="" value="">Ajouter...</option>
                                                {
                                                    this.props.blogCategories && this.props.blogCategories.map(category => {
                                                        return (
                                                            <option key={parseInt(category.id)} value={parseInt(category.id)}>{category.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL simplifiée : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedLandingPage.slug = e.target.value} defaultValue={this.props.selectedLandingPage.slug ? this.props.selectedLandingPage.slug : slugify(this.props.selectedLandingPage.title)} placeholder="pegasus-37"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}