import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";


export default class AddBrandModalForm extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedBrand.logoUrl
        };
    }

    handleClose = () => fetch('https://back-products-api.therunningcollective.fr/brand', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedBrand)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
            ))
        .catch( err =>
            console.log(err)
        );

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "brands/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedBrand.logoUrl = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une marque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedBrand.name = e.target.value} defaultValue={this.props.selectedBrand.name} placeholder="Ex : Nike"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Catégories</Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedBrand.categories = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedBrand.categories}
                                            >
                                                <option value="/products/shoes">Chaussures</option>
                                                <option value="/products/spikes">Pointes</option>
                                                <option value="/products/apparel">Vêtements</option>
                                                <option value="/products/accessories">Accessoires</option>
                                                <option value="/products/electronic">Electronique</option>
                                                <option value="/products/nutrition">Nutrition</option>
                                                <option value="/products/vouchers">Carte cadeau</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}