import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";

function getForecasts() {

    return fetch('https://back-api.therunningcollective.fr/forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export default class AddForecastLeagueModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedForecastLeague && this.props.selectedForecastLeague.logo ? this.props.selectedForecastLeague.logo : "",
        };
    }

    async componentDidMount() {
        await getForecasts().then(data => {
            this.setState({forecasts: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecasts"})
        );
    };

    handleClose = () => fetch('https://back-api.therunningcollective.fr/forecast-league', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedForecastLeague)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        );

    uploadCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecasts/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedForecastLeague.imageURL = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une ligue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecastLeague.name = e.target.value} defaultValue={this.props.selectedForecastLeague.name} placeholder="Ex : LIFA"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Pronostic : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecastLeague.forecastID = e.target.value}
                                                defaultValue={this.props.selectedForecastLeague.forecastID}
                                            >
                                                <option >Ajouter...</option>
                                                {
                                                    this.state.forecasts ? 
                                                    this.state.forecasts.map(forecast => {
                                                       return(<option value={forecast.id}>{forecast.name}</option>)
                                                    }) : null
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadCover(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}