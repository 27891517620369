import React, {useEffect, useRef, useState} from "react";

import {
    Card,
    CardHeader,
    CardTitle, Col, Row, Spinner,
    Table
} from "reactstrap";

import unslug from 'unslug'
import {Download} from "react-feather";

function getViewedPages(beginDate, endDate){

  if (beginDate && endDate){
    return fetch('https://back-api.therunningcollective.fr/products-detailed-reporting?beginDate='+beginDate.toLocaleDateString()+'&endDate='+endDate.toLocaleDateString(), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
      },

    })
        .then(data => data.json())
        .catch(function(err){
              console.log(err)
            }
        )
  }
}

export const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

const ProductsTraffic = (date) => {
  const [pagesDatas, setPagesDatas] = useState(null);


  useEffect(() => {
      if (date && date.startDate && date.endDate){
          getViewedPages(date.startDate, date.endDate).then(locations => {
              let sorted = locations.sort((a, b) => b.Count - a.Count);
              setPagesDatas(sorted)
          }).catch(err =>
              console.log(err)
          );
      }
  }, [date])

    const exportToCsv = e => {
        if (date && date.startDate && date.endDate){
            e.preventDefault()

            // Headers for each column
            let headers = ['Product Name,Count']

            // Convert users data to a csv
            let usersCsv = pagesDatas.reduce((acc, user) => {
                const {Object, Count} = user
                acc.push([unslug(Object), Count].join(','))
                return acc
            }, [])
            downloadFile({
                data: [...headers, ...usersCsv].join('\n'),
                fileName: 'products_count_'+date.startDate.toLocaleDateString()+'_to_'+date.endDate.toLocaleDateString()+'.csv',
                fileType: 'text/csv',
            })
        }
    }

  return (
      <div className="flex-fill w-100">
          {
              date.loading ?
                  <Card className="flex-fill w-100">
                      <CardHeader>
                          <CardTitle tag="h5" className="mb-0" style={{textAlign: "center"}}>
                              <Spinner/>
                          </CardTitle>
                      </CardHeader>
                  </Card>
                  :
                  <Card className="flex-fill w-100">
                      <CardHeader>
                          <CardTitle tag="h5" className="mb-0">
                              <Row>
                                  <Col xs={11}>
                                      Produits achetés (25 max)
                                  </Col>
                                  <Col xs={1}>
                                    <Download style={{cursor:"pointer"}} onClick={exportToCsv}/>
                                  </Col>
                              </Row>
                          </CardTitle>
                      </CardHeader>
                      <Table striped className="my-0">
                          <thead>
                          <tr>
                              <th>Nom du produit</th>
                              <th className="text-right">Nombre</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              pagesDatas && pagesDatas.length > 0 ?
                                  pagesDatas.slice(0, 25).map((page) => {
                                      return (
                                          <tr>
                                              <td>{page.key}</td>
                                              <td className="text-right">{page.value}</td>
                                          </tr>
                                      );
                                  }) : null
                          }
                          </tbody>
                      </Table>
                  </Card>
          }
      </div>
      )
};

export default ProductsTraffic;