import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteVoucherInfoModal from "./modals/DeleteVoucherInfoModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getVouchersInfo() {

    return fetch('https://back-api.therunningcollective.fr/voucher-infos', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getProducts() {

    return fetch('https://back-products-api.therunningcollective.fr/small-products?headCategories=/products/shoes,/products/apparel,/products/spikes,/products/electronic,/products/accessories,/products/nutrition', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getBrands() {
    return fetch('https://back-products-api.therunningcollective.fr/brands', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class VouchersInfoList extends Component{
    state = {
        vouchers: [],
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        availableHeadCategories: [
            {
                id: "/products/apparel",
                name: "Vêtements"
            },
            {
                id: "/products/shoes",
                name: "Chaussures"
            },
            {
                id: "/products/spikes",
                name: "Pointes"
            },
            {
                id: "/products/accessories",
                name: "Accessoires"
            },
            {
                id: "/products/electronic",
                name: "Electronique"
            },
            {
                id: "/products/nutrition",
                name: "Diététique"
            },
        ],
        selectedVoucher: {
            name : "",
            merchand: {
                name: ""
            },
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: "",
        },
        availableProducts: []
    }

    async componentDidMount() {
        await getVouchersInfo().then(data => {
            this.setState({ vouchers: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting vouchers"})
        );
        await getMerchands().then(data => {
            this.setState({ availableMerchands: data })
        })
        await getBrands().then(data => {
            this.setState({ availableBrands: data })
        })
        await getProducts().then(data => {
            this.setState({ availableProducts: data })
        })
    };

    closeModal = () => this.setState({ isOpen: false, selectedVoucher: {
            name : "",
            merchand: {
                name: ""
            },
            errors: null,
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: ""
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={5} className="products-buttons">
                        {
                            (this.state.availableProducts && this.state.availableProducts.length > 0) ?
                                <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une info de réduction</Button>
                                : null
                        }
                    </Col>
                </Row>
                { (!this.state.vouchers) ? <p> Aucuns coupons disponibles.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Marchand</th>
                            <th>Description : </th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>Produits éligibles : </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.vouchers.length > 0 && this.state.vouchers.map(voucher => (
                            <tr key={voucher.id}>
                                <td>{voucher.merchantName ? voucher.merchantName : ""}</td>
                                <td>{voucher.description}</td>
                                <td>{Moment(voucher.beginDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{Moment(voucher.endDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{voucher.products ? voucher.products.length : 0}</td>
                                <td className="table-action">
                                    {
                                        this.state.availableProducts && this.state.availableProducts.length > 0 ?
                                            <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedVoucher : voucher})}/>
                                            : null
                                    }
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedVoucher: voucher})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                { this.state.isOpen ?
                    <AddVoucherInfoModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedVoucher={this.state.selectedVoucher}
                        availableMerchands={this.state.availableMerchands}
                        availableProducts={this.state.availableProducts}
                        availableVouchers={this.state.vouchers}
                        availableBrands={this.state.availableBrands}
                        availableHeadCategories={this.state.availableHeadCategories}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteVoucherInfoModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedVoucher}
                        selectedCategory="voucher-info"
                    />
                    :
                    null
                }
            </div>
        );

    }

}