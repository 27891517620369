import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Badge, Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import DeletePhysicalEventAttendeeModal from "./modals/DeleteAttendeeEventModal";
import AddEventModal from "./modals/AddEventModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {AiOutlineQrcode} from "react-icons/ai";

function getEvents() {

    return fetch('https://back-api.therunningcollective.fr/physical-event-attendees', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(event, checked) {
    event.isValidated = checked
    return fetch('https://back-api.therunningcollective.fr/physical-event-attendee', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(event)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class AttendeeEventsList extends Component {
    state = {
        errors: null,
        selectedEvent: {
            login: "",
            email: "",
            password: "",
            name: "",
            phoneNumber: "",
        }
    }

    async componentDidMount() {
        await getEvents().then(data => {
            this.setState({events: data})
            console.log(data)
        }).catch(err =>
            this.setState({errors: "error while getting events"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedEvent: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: ""
        }
    });

    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.events && this.state.events.length > 0 && this.state.errors == null) ? <span>{this.state.events.length} participation{this.state.events.length == 1 ? "": "s"}</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des participations, veuillez vous reconnecter"</p> : null}

                { (!this.state.events && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <th>QRCode validé</th>
                        <th>QR Code</th>
                        <th>Évènement</th>
                        <th>Date de l'évènement</th>
                        <th>Utilisateur</th>
                        <th>Email</th>
                        <th>Vêtements</th>
                        <th>Chaussures</th>
                        <th>Date de demande</th>
                        <th>Participation validée</th>
                        <th></th>
                        </thead>
                        <tbody>
                        {this.state.events.map(event => (
                            <tr>
                                <td>{event.isPhysicalValidated === true?
                                    <Badge pill variant="success">
                                    A participé
                                </Badge> :
                                    event.isPhysicalValidated === false && (new Date(event.event.date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) ?
                                    <Badge pill variant="warning">
                                    En attente
                                </Badge> : <Badge pill variant="danger">
                                    N'a pas participé
                                    </Badge> }</td>
                                <td>
                                    <a href={event.QRCode} target="_blank"><AiOutlineQrcode size="1rem"/></a>
                                </td>
                                <td>{event.event.name}</td>
                                <td>{event.event.date}</td>
                                <td>{event.frontUser.firstName} {event.frontUser.name}</td>
                                <td>{event.frontUser.email}</td>
                                <td>{event.frontUser.apparelSize}</td>
                                <td>{event.frontUser.shoeSize}</td>
                                <td>{event.createdDate}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Participation validée</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={event.isValidated}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(event, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer la participation</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedEvent: event})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isDelete ?
                    <DeletePhysicalEventAttendeeModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedEvent}
                        selectedCategory="attendee-physical-event"
                    />
                    :
                    null
                }
            </div>
        );

    }
}