import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import {slugify} from "./UpdateRaceModal";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';

export default class AddPhysicalShopActivationModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedPhysicalShopActivation.image
        };
    }

    handleClose = () => {
        console.log(this.props.selectedPhysicalShopActivation)
        fetch('https://back-api.therunningcollective.fr/physical-shop-activation', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedPhysicalShopActivation)
        })
            .then(() => (
                    sleep(2000),
                        this.props.closeModal,
                        window.location.reload(false)
            )
                )
            .catch( err =>
                console.log(err)
            );
    }

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "brands/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedPhysicalShopActivation.image = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    render(){
        console.log(this.props.selectedPhysicalShopActivation)

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une activation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedPhysicalShopActivation.name = e.target.value)} defaultValue={this.props.selectedPhysicalShopActivation.name} placeholder="Ex : Basic"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedPhysicalShopActivation.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedPhysicalShopActivation.beginDate = new Date(e.target.value).toISOString()}
                                            />                                        
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Date de fin : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedPhysicalShopActivation.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedPhysicalShopActivation.endDate = new Date(e.target.value).toISOString()}
                                            />                                        
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Type de magasins : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedPhysicalShopActivation.shopType = e.target.value}
                                                defaultValue={this.props.selectedPhysicalShopActivation.shopType}
                                            >
                                                <option >Ajouter...</option>
                                                <option key="RC" value="RunningConseil">RunningConseil</option>
                                                <option key="TTO" value="tontonOutdoor">TontonOutdoor</option>
                                                <option key="TDR" value="TerreDeRunning">TerreDeRunning</option>
                                                <option key="Sohbi" value="SohbiSport">SohbiSport</option>
                                                <option key="irun" value="i-Run">i-Run</option>
                                                <option key="decathlon" value="Decathlon">Decathlon</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Produits associés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.props.selectedPhysicalShopActivation.productsSlug = [...e.target.selectedOptions].map(o => this.props.availableProducts.find(product =>  product.shortURL === o.value))}}
                                                defaultValue={getSelectedItems(this.props.selectedPhysicalShopActivation.productsSlug)}
                                            >
                                                {this.props.availableProducts.map((product, index) =>
                                                    <option key={index} value={product.shortURL}>{product.name}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.shortURL)
            )
        )
    }

    return arrayToReturn
}