import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import DeleteModal from "./modals/DeleteModal";
import AddHomeAppearanceSlide from "./modals/AddHomeAppearanceSlide";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {ReactSortable} from "react-sortablejs";

function getSlides(headCategory) {

    return fetch('https://backapi.therunningcollective.fr/api/protected/appearance/home?shopType=athletisme&appearanceType='+headCategory, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(slide, checked) {
    slide.enable = checked
    return fetch('https://backapi.therunningcollective.fr/api/protected/appearance/home', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(slide)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class AppearanceAthletismePageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopType: this.props.location.pathname.split("/athletisme").shift(),
            headCategory: this.props.location.pathname.split("/athletisme").pop(),
            isDelete: false,
            errors: null,
            slides: null,
            dragId: null,
            selectedSlide: {
                title:"",
                image: "",
                subtitle: "",
                textColor: {
                    r: 255,
                    g: 255,
                    b: 255,
                    a: 100,
                },
                buttonText: "",
                url: ""
            }
        }

    }

    async componentDidMount() {
        await getSlides(this.state.headCategory).then(data => {
            this.setState({slides: data})
        }).catch(err =>
            this.setState({errors: "error while getting slides"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        selectedSlide: {
            imgURL: "",
            text: "",
            order:0,
            textColor: {
                r: 255,
                g: 255,
                b: 255,
                a: 100,
            },
            buttonText: "",
            buttonURL: ""
        }
    });

    handleDropSlides = (list) => {
        const newBoxState = list.map((slide, index) => {
            slide.order = index

            fetch('https://backapi.therunningcollective.fr/api/protected/appearance/home', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(slide)
            }).then(this.props.closeModal)
                .catch( err =>
                    console.log(err)
                )

            return slide;
        });

        this.setState({slides : newBoxState});

    };


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter une slide</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des slides, veuillez vous reconnecter"</p> : null}

                { (!this.state.slides && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <tbody>
                        <ReactSortable list={this.state.slides} setList={(list) => this.handleDropSlides(list)}>
                            {this.state.slides && this.state.slides.sort((a, b) => a.order > b.order).map(slide => (
                                <tr
                                    key={slide.order}
                                    id={slide.id}
                                    style={{textAlign: 'center'}}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={slide.enable}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(slide, checked)
                                        }}
                                    /></td>
                                    <td>
                                        {slide.shopTypeNG && slide.shopTypeNG.length > 0
                                            ?
                                            slide.shopTypeNG.map(slide => {
                                                return(
                                                    <div>{slide}</div>
                                                )
                                            })
                                            : null
                                        }
                                    </td>
                                    <td>{slide.title}</td>
                                    <td>
                                        <img style={{
                                            maxWidth: '100px',
                                        }} src={'https://img.therunningcollective.fr/' + slide.image}/>
                                    </td>
                                    <td>{slide.subtitle}</td>
                                    <td> <div style={{
                                        width: '36px',
                                        height: '14px',
                                        borderRadius: '2px',
                                        backgroundColor: `rgba(${ slide.textColor.r }, ${ slide.textColor.g }, ${ slide.textColor.b }, ${ slide.textColor.a })`,
                                    }}></div></td>
                                    <td>{slide.buttonText}</td>
                                    <td>{slide.url}</td>
                                    <td className="table-action" style={{cursor: 'pointer'}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                               onClick={(e) => this.setState({isOpen: true, selectedSlide: slide})}/>
                                    </td>
                                    <td className="table-action" style={{cursor: 'pointer'}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedSlide: slide})}/>
                                    </td>
                                </tr>
                            ))}
                        </ReactSortable>
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddHomeAppearanceSlide
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedSlide={this.state.selectedSlide}
                        headCategory={this.state.headCategory}
                        slidersLength={this.state.slides ? this.state.slides.length : 0}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedSlide}
                        selectedCategory="appearance/home"
                    />
                    :
                    null
                }
            </div>
        );

    }
}