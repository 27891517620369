import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteCoinModal from "./modals/DeleteCoinModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";
import AddCoinsModal from "./modals/AddCoinsModal";

function getCoins() {

    return fetch('https://back-api.therunningcollective.fr/coins', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getUsers() {

    return fetch('https://back-api.therunningcollective.fr/csv-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class CoinsList extends Component {
    state = {
        errors: null,
        selectedCoin: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: "",
            types: [""]
        },
        availableTypes: ["Athlétisme", "Running", "Trail"]
    }

    async componentDidMount() {
        await getCoins().then(data => {
            this.setState({coins: data})
        }).catch(err =>
            this.setState({errors: "error while getting coins"})
        );

        await getUsers().then(data => {
            this.setState({availableUsers: data})
        }).catch(err =>
            this.setState({errors: "error while getting users with tokens"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedCoin: {
            title: "",
            body: "",
        }
    });


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    {
                        this.state.availableUsers && this.state.availableUsers.length > 0 ?
                            <Col sm={11} className="products-buttons">
                                <Button className="add-product-button " variant="secondary" type="submit"
                                        onClick={(e) => this.setState({isOpen: true})}>Ajouter des TRCoins</Button>
                            </Col>
                        : <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        </div>
                    }
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.coins && this.state.coins.length > 0 && this.state.errors == null) ? <span>{this.state.coins.length} ajouts de coins</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des coins, veuillez vous reconnecter"</p> : null}

                { (!this.state.coins && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Description</th>
                            <th>Nombre de TRCoins</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.coins.map(coin => (
                            <tr>
                                <td>{coin.name}</td>
                                <td>{coin.description}</td>
                                <td>{coin.count}</td>
                                {
                                    this.state.availableUsers && this.state.availableUsers.length > 0 ?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Modifier l'ajout de coins</Tooltip>}
                                    >
                                        <td className="table-action" style={{cursor: "pointer"}}>
                                            <Edit2 className="align-middle mr-1" size={18}
                                                onClick={(e) => this.setState({isOpen: true, selectedCoin: coin})}/>
                                        </td>
                                    </OverlayTrigger>
                                    : null
                                }
                                
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer l'ajout de coins</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedCoin: coin})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddCoinsModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedCoin={this.state.selectedCoin}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}

                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteCoinModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedCoin}
                        selectedCategory="coin"
                    />
                    :
                    null
                }
            </div>
        );

    }
}