import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import {SketchPicker} from "react-color";
import reactCSS from 'reactcss'
import {sleep} from "./AddBrandModal";


export default class AddLandingPageContentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPickerText: false,
            displayColorPickerButton: false,
            colorText: {
                r: 255,
                g: 255,
                b: 255,
                a: 100,
            },
            homeAppeareanceImg: "",
        };
        this.uploadHomeAppearanceImg = this.uploadHomeAppearanceImg.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }


    handleClickText = () => {
        this.setState({ displayColorPickerText: !this.state.displayColorPickerText })
    };

    handleCloseColorText = () => {
        this.setState({ displayColorPickerText: false })
    };

    handleChangeTextColor = (color) => {
        this.props.selectedSlide.textColor = color.rgb
        this.setState({ colorText: color.rgb })
    };

    uploadHomeAppearanceImg(e, slide) {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "landing-pages/"+fileName
                })}).then((res) =>
            res.json().then( result =>{
                slide.image = result.message
                sleep(1000)
                this.setState({homeAppeareanceImg:  result.message});
            }))}
    }


    handleClose() {
        fetch('https://back-api.therunningcollective.fr/landing-page-content', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedSlide)
        })
            .then( () => (
                this.props.closeModal, window.location.reload(false)
                ))
            .catch( err =>
                console.log(err)
            );
    }

    render(){
        this.props.selectedSlide.order = this.props.slidersLength;
        this.props.selectedSlide.type = this.props.typeOfContent;
        console.log()

        const stylesText = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `rgba(${ this.props.selectedSlide.textColor.r }, ${ this.props.selectedSlide.textColor.g }, ${ this.props.selectedSlide.textColor.b }, ${ this.props.selectedSlide.textColor.a })`,
                },
                swatch: {
                    padding: '5px',
                    marginLeft: '15px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une slide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Titre : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedSlide.title = e.target.value} defaultValue={this.props.selectedSlide.title} placeholder="Ex : Home 1"/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-first-img-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => {this.uploadHomeAppearanceImg(e, this.props.selectedSlide)}}
                                                custom
                                            />
                                            {this.props.selectedSlide.image ?
                                                <img className="imgToDisplay" alt="Couverture actuelle" src={this.props.selectedSlide.image}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Contraste : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedSlide.contrast = e.target.value}
                                                defaultValue={this.props.selectedSlide.contrast}
                                            >
                                                <option value="0">0</option>
                                                <option value="0.1">0.1</option>
                                                <option value="0.2">0.2</option>
                                                <option value="0.3">0.3</option>
                                                <option value="0.4">0.4</option>
                                                <option value="0.5">0.5</option>
                                                <option value="0.6">0.6</option>
                                                <option value="0.7">0.7</option>
                                                <option value="0.8">0.8</option>
                                                <option value="0.9">0.9</option>
                                                <option value="1">1</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {
                                            this.props.typeOfContent && this.props.typeOfContent == "slider" ?
                                                <Form.Group >
                                                    <Form.Label>Texte : </Form.Label>
                                                    <Form.Control as="textarea" rows={4} onChange={(e) => this.props.selectedSlide.subtitle = e.target.value} defaultValue={this.props.selectedSlide.subtitle} placeholder="Cette chaussure est ..."/>
                                                </Form.Group>
                                                : null
                                        }

                                        <Form.Group>
                                            <Form.Label>Couleur du texte :
                                                <div style={ stylesText.swatch } onClick={ this.handleClickText }>
                                                    <div style={ stylesText.color } />
                                                </div>
                                                { this.state.displayColorPickerText ? <div style={ stylesText.popover }>
                                                    <div style={ stylesText.cover } onClick={ this.handleCloseColorText }/>
                                                    <SketchPicker color={ this.props.selectedSlide.textColor } onChange={ this.handleChangeTextColor } />
                                                </div> : null }
                                            </Form.Label>
                                        </Form.Group>
                                        {
                                            this.props.typeOfContent && this.props.typeOfContent == "slider" ?
                                                <Form.Group >
                                                    <Form.Label>Texte du bouton : </Form.Label>
                                                    <Form.Control type="text" onChange={(e) => this.props.selectedSlide.buttonText = e.target.value} defaultValue={this.props.selectedSlide.buttonText} placeholder="Ex : Découvrir"/>
                                                </Form.Group>
                                                : null
                                        }

                                        <Form.Group >
                                            <Form.Label>Lien du bouton : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedSlide.url = e.target.value} defaultValue={this.props.selectedSlide.url} placeholder="Ex : https://therunningcollective.fr/"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Landing page : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedSlide.shopTypeNG = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedSlide.shopTypeNG}
                                            >
                                                {
                                                    this.props.list && this.props.list.map(page => {
                                                        return (
                                                            <option key={page.title} value={page.title}>{page.title}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}