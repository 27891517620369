import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";

function getPushNotifications() {

    return fetch('https://back-api.therunningcollective.fr/push-notifications-sent', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data =>
            data.json()
        )
        .catch( err =>
            console.log(err)
        )
}

function getUsersWithPushNotificationsTokens() {

    return fetch('https://back-api.therunningcollective.fr/front-users-with-push-notification-token', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class PushNotificationsSentList extends Component {
    state = {
        errors: null,
        selectedNotification: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: "",
            types: [""]
        },
        notifications: null,
        availableTypes: ["Athlétisme", "Running", "Trail"]
    }

    async componentDidMount() {
        await getPushNotifications().then(data => {
            data.pushNotifications = data.pushNotifications.sort((a, b) => new Date(b.sendDate) - new Date(a.sendDate));
            this.setState({notifications: data.pushNotifications, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false,})
        }).catch(err =>
            this.setState({errors: "error while getting notifications"})
        );

        await getUsersWithPushNotificationsTokens().then(data => {
            this.setState({availableUsers: data})
        }).catch(err =>
            this.setState({errors: "error while getting users with tokens"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedNotification: {
            title: "",
            body: "",
            sendDate: "",
            link: "",
        }
    });


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.notifications && this.state.notifications.length > 0 && this.state.errors == null) ? <span>{this.state.notifications.length} notifications</span> : null }
                    </Col>
                </Row>

                { (this.state.notifications == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Corps de texte</th>
                            <th>Lien</th>
                            <th>Date d'envoi</th>
                            <th>Utilisateurs concernés</th>
                            <th>Envoyé</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.notifications.map(notification => (
                            <tr>
                                <td>{notification.title}</td>
                                <td>{notification.body}</td>
                                <td>{notification.link}</td>
                                <td>{new Date(notification.sendDate).toLocaleDateString() + " à " + new Date(notification.sendDate).toLocaleTimeString()}</td>
                                <td>{notification.countOfUsers}</td>
                                <td>{notification.sent == false ? "Non" : "Oui"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddNotificationModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedNotification={this.state.selectedNotification}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}

                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedNotification}
                        selectedCategory="push-notification"
                    />
                    :
                    null
                }
            </div>
        );

    }
}

function isSent(arr) {
    return arr.filter(function(x) {
        return x.sent === true;
    });
}