import React, {Component, forwardRef} from 'react';
import {Col, Row, Table} from "reactstrap";
import Moment from "react-moment";
import 'moment/locale/fr';
import './Products.css'
import UpdateModalForm from "./modals/UpdateProductModal";
import {ChevronDown, ChevronUp, Edit2, Power, Shuffle, Trash} from "react-feather";
import DeleteProductRatingModal from "./modals/DeleteProductRatingModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {Badge, FormControl, OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Logout from "./Logout";
import {ReactSortable} from "react-sortablejs";
import Loader from "./Loader";


function getProductRatings() {
    return fetch('https://back-api.therunningcollective.fr/product-ratings', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(product, checked) {
    product.approved = checked
    return fetch('https://back-api.therunningcollective.fr/product-rating', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class ProductRatingsList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            productRatings: null,
            errors: "",
            loading: true,
            selectedRating: null
        }
    }

    async componentDidMount() {
        await getProductRatings().then(data => {
            this.setState({ productRatings: data, errors : "", loading: false })
        }).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
            }
        );
    };

    render(){
        return(
            <div className="products-list">
                {this.state.productRatings == null ? <p> Chargement ...</p> :

                <div>
                    <Col sm={8} className="products-buttons">
                        <span>Nombre d'avis produits : {this.state.productRatings.length}</span>
                    </Col>
                    <Table responsive>

                        <tbody>

                        

                        {(this.state.loading !== true)
                            ?
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>Produit</th>
                                    <th>Note</th>
                                    <th>Avis</th>
                                    <th>Nom</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.productRatings.map(rating => (
                                    <tr>
                                        <td>{rating.productName}</td>
                                        <td>{rating.stars}</td>
                                        <td>{rating.message}</td>
                                        <td>{rating.name}</td>
                                        <td>{rating.email}</td>
                                        <td><Moment to={rating.createdDate}>{Date.now()}</Moment></td>
                                        <td><BootstrapSwitchButton
                                            checked={rating.approved}
                                            onlabel='oui'
                                            onstyle='success'
                                            offlabel='non'
                                            offstyle='danger'
                                            size='xs'
                                            onChange={(checked: boolean) => {
                                                updateState(rating, checked)
                                            }}
                                        /></td>
                                        <td className="table-action">
                                            <Trash className="align-middle" size={18}
                                                   onClick={(e) => this.setState({isDelete: true, selectedRating: rating})}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        :
                        null }
                        </tbody>
                    </Table>
                </div>
                }
                { this.state.isDelete ?
                    <DeleteProductRatingModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedRating}
                        selectedCategory="product-rating"
                    />
                    :
                    null
                }
            </div>
        );
    }
}