import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {sleep} from "./AddBrandModal";

async function deleteItem(selectedItem, itemCategory) {
    return fetch('https://back-products-api.therunningcollective.fr/category', {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: selectedItem, headCategory : itemCategory})
    })
        .then(() => (data => data.json()))
        .catch( err =>
            console.log(err)
        )
}

export default class DeleteCategoryModal extends Component {
    handleDelete = async (item, itemCategory) => {
        deleteItem(item, itemCategory).then( () => {
                sleep(2000)
                window.location.reload(false)
            }
        ).catch(
            console.error("error while deleting item" + item)
        );
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Suppresion de la catégorie {this.props.selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Voulez vous vraiment supprimer l'item {this.props.selectedItem.name} ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={(e) => this.handleDelete(this.props.selectedItem, this.props.selectedCategory)}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
