import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteWheelSpinModal from "./modals/DeleteWheelSpinModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import UpdateRaceForm from "./modals/UpdateRaceModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {CiWarning} from "react-icons/ci";
import UpdateWheelSpinModal from "./modals/UpdateWheelSpinModal";

function getWheelSpins() {

    return fetch('https://back-api.therunningcollective.fr/wheel-spins', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(wheelSpin, checked) {
    wheelSpin.state = checked
    return fetch('https://back-api.therunningcollective.fr/wheel-spin', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(wheelSpin)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

function getRewards() {

    return fetch('https://back-api.therunningcollective.fr/rewards', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class WheelSpinList extends Component{
    state = {
        wheelsSpin: [],
        rewards: [],
        isOpen: false,
        errors: null,
        selectedWheelSpin : {
            events: [],
            name: "",
            description: "",
            metaTitle:"",
            metaDescription:"",
        }
    }

    async componentDidMount() {
        await getWheelSpins().then(data => {
            this.setState({ wheelsSpin: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting wheelsSpin"})
        );
        await getRewards().then(data => {
            this.setState({rewards: data})
        }).catch(err =>
            this.setState({errors: "error while getting rewards"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedRace: {
            events: [],
            name: "",
            description: "",
            metaTitle:"",
            metaDescription:"",
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={3} className="products-buttons">
                        <span>Nombre de croissants : {this.state.wheelsSpin ? this.state.wheelsSpin.length : 0}</span>
                    </Col>

                    <Col sm={3} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un croissant</Button>
                    </Col>
                </Row>
                { (!this.state.wheelsSpin) ? <p> Aucun croissant disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Reward associé</th>
                            <th>Couleur du texte</th>
                            <th>Couleur du fond</th>
                            <th>Afficher sur la roue</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.wheelsSpin.length > 0 && this.state.wheelsSpin.map(wheelSpin => (
                            <tr key={wheelSpin.id}>
                                <td>{wheelSpin.name}</td>
                                <td>{wheelSpin.associatedReward.description}</td>
                                <td>{wheelSpin.color}</td>
                                <td>{wheelSpin.backgroundColor}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le croissant sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={wheelSpin.state}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(wheelSpin, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedWheelSpin : wheelSpin})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedWheelSpin: wheelSpin})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                { this.state.isOpen ?
                    <UpdateWheelSpinModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedWheelSpin={this.state.selectedWheelSpin}
                        availableRewards={this.state.rewards}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteWheelSpinModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedWheelSpin}
                        selectedCategory="wheel-spin"
                    />
                    :
                    null
                }
            </div>
        );

    }

}