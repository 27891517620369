import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddBrandModalForm from "./modals/AddBrandModal";
import DeleteBrandModal from "./modals/DeleteBrandModal";
import Logout from "./Logout";

function getBrands() {

    return fetch('https://back-products-api.therunningcollective.fr/brands', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class BrandsList extends Component{
    state = {
        brands: null,
        isOpen: false,
        errors: null,
        isDelete: false,
        selectedBrand: {
            name : ""
        }
    }

    async componentDidMount() {
        await getBrands().then(data => {
            this.setState({ brands: data })
        }).catch(err =>
            this.setState({errors: "error while getting brands"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedBrand: { name : "", } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une marque</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup  value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode : e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                

                { !this.state.brands ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Logo</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.brands.map(brand => (
                            <tr>
                                <td>{brand.name}</td>
                                <td><img className="imgToDisplay" alt="Logo..." src={brand.logoUrl}/></td>
                                <td>{brand.categories && brand.categories.map(item => {
                                    return(
                                        <div>{item}</div>
                                    )
                                })}</td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedBrand : brand})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18} onClick={(e) => this.setState({isDelete : true, selectedBrand : brand})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddBrandModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedBrand={this.state.selectedBrand}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteBrandModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedBrand}
                        selectedCategory="brand"
                    />
                    :
                    null
                }
            </div>
        );

    }

}