import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import DeleteForecastContentModal from "./modals/DeleteForecastContentModal";
import AddForecastContentModal from "./modals/AddForecastContentModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Moment from "moment";

function getForecastContentsList() {

    return fetch('https://back-api.therunningcollective.fr/forecast-contents', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getForecasts() {

    return fetch('https://back-api.therunningcollective.fr/forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}


function updateState(forecastContent, checked) {
    forecastContent.active = checked
    return fetch('https://back-api.therunningcollective.fr/forecast-content', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(forecastContent)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

function getForecastName(forecastID, forecasts){
    let output 
    forecasts.map(forecast => {
        if (forecast.id == forecastID){
            output = forecast.name
        }
    })
    //console.log(output)
    return output
}

export class ForecastContentsList extends Component {
    state = {
        errors: null,
        selectedForecastContent: {
            name: "",
            type: "",
            beginDate: "",
            forecastID: "",
            gender: "",
            athletes: [],
        },
    }

    async componentDidMount() {
        await getForecasts().then(data => {
            this.setState({forecasts: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecasts"})
        );
        await getForecastContentsList().then(data => {
            this.setState({forecastContents: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecastContents"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedForecast: {
            title: "",
            body: "",
        }
    });


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter une épreuve</Button>
                    </Col>
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.forecastContents && this.state.forecastContents.length > 0 && this.state.errors == null) ? <span>{this.state.forecastContents.length} pronostic(s)</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des épreuves, veuillez vous reconnecter"</p> : null}

                { (!this.state.forecastContents && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Afficher</th>
                            <th>ID</th>
                            <th>Evenement</th>
                            <th>Nom</th>
                            <th>Sexe</th>
                            <th>Type</th>
                            <th>Date de début</th>
                            <th>Nb d'athlètes</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.forecastContents.map(forecastContent => (
                            <tr>
                                <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Afficher l'épreuve sur le site</Tooltip>}
                                        >
                                            <td><BootstrapSwitchButton
                                                checked={forecastContent.active}
                                                onlabel='oui'
                                                onstyle='success'
                                                offlabel='non'
                                                offstyle='danger'
                                                size='xs'
                                                onChange={(checked: boolean) => {
                                                    updateState(forecastContent, checked)
                                                }}
                                            /></td>
                                        </OverlayTrigger>
                                <td>{this.state.forecasts && this.state.forecasts.length > 0 ? getForecastName(forecastContent.forecastID, this.state.forecasts) : null}</td>
                                <td>{forecastContent.id}</td>
                                <td>{forecastContent.name}</td>
                                <td>{forecastContent.gender}</td>
                                <td>{forecastContent.type}</td>
                                <td>{Moment(forecastContent.beginDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{forecastContent.timeTable && forecastContent.timeTable.length > 0 ? forecastContent.timeTable.map(data => {
                                    return(
                                        <div> {data.name} - {data.athletes ? data.athletes.length : 0}</div>
                                    )
                                }) : 0}</td>
                                <td>
                                    {
                                        forecastContent.cover && forecastContent.cover != "" ?
                                            <img style={{
                                                maxWidth: '100px',
                                            }} src={forecastContent.cover}/>
                                        : null
                                    }
                                </td>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Modifier l'ajout de pronsotic</Tooltip>}
                                    >
                                        <td className="table-action" style={{cursor: "pointer"}}>
                                            <Edit2 className="align-middle mr-1" size={18}
                                                onClick={(e) => this.setState({isOpen: true, selectedForecastContent: forecastContent})}/>
                                        </td>
                                    </OverlayTrigger>
                                
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer l'ajout du pronsotic</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedForecastContent: forecastContent})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddForecastContentModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedForecastContent={this.state.selectedForecastContent}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}
                        forecasts={this.state.forecasts}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteForecastContentModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedForecastContent}
                        selectedCategory="forecastContent"
                    />
                    :
                    null
                }
            </div>
        );

    }
}