import Modal from 'react-bootstrap/Modal'
import React, { Component }  from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';

async function deleteItem(selectedItem,setShow) {
    return fetch('https://back-api.therunningcollective.fr/appearance', {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedItem)
    })
        .then(() => (data => data.json()))
        .catch( err =>
            console.log(err)
        )
        .finally(() => {
            setShow(false)
        })
}

function DeleteAppearanceModal(props) {
    const [show, setShow] = useState(props.isOpen);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

        return(
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Suppresion de la slide {props.selectedItem.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Voulez vous vraiment supprimer l'item {props.selectedItem.title} ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={(e) => deleteItem(props.selectedItem, setShow)}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
}

export default DeleteAppearanceModal;