import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import {sleep} from "./AddBrandModal";


export default class AddUserModalForm extends Component {

    handleClose = () => fetch('https://back-api.therunningcollective.fr/back-user', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedUser)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
            ))
        .catch( err =>
            console.log(err)
        );

    uploadFirstImg(e, user) {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "back-users/"+fileName
                })}).then((res) =>
            res.json().then( result =>{
                user.img = result.message
                sleep(1000)
                this.setState({profileImg:  result.message});
            })
        )
            .catch( err =>
                console.log(err)
            );
        }
    }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedUser.name = e.target.value} defaultValue={this.props.selectedUser.name} placeholder="Provost"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Prénom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedUser.firstName = e.target.value} defaultValue={this.props.selectedUser.firstName} placeholder="Antoine"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Login : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedUser.login = e.target.value} defaultValue={this.props.selectedUser.login} placeholder="AP"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Email : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedUser.email = e.target.value} defaultValue={this.props.selectedUser.email} placeholder="antoine.provost@therunningcollective.fr"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Numéro de téléphone : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedUser.phoneNumber = e.target.value} defaultValue={this.props.selectedUser.phoneNumber} placeholder="0624914541"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Photo de profil : </Form.Label>
                                            <Form.File
                                                id="custom-first-img-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => {this.uploadFirstImg(e, this.props.selectedUser)}}
                                                custom
                                            />
                                            {this.props.selectedUser.img ?
                                                <img className="imgToDisplay" alt="Couverture actuelle" src={this.props.selectedUser.img}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Role : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                defaultValue={this.props.selectedUser.role}
                                                onChange={(e) => this.props.selectedUser.role = e.target.value}
                                            >
                                                <option >Ajouter...</option>
                                                <option key="user" value="user">Utilisateur</option>
                                                <option key="admin" value="admin">Administrateur</option>
                                                <option key="superadmin" value="superadmin">Super Administrateur</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nouveau mot de passe : </Form.Label>
                                            <Form.Control type="password" onChange={(e) => this.props.selectedUser.password = e.target.value} defaultValue={this.props.selectedUser.password} placeholder="***************"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Retapez le nouveau mot de passe : </Form.Label>
                                            <Form.Control type="password" onChange={(e) => this.props.selectedUser.otherPassword = e.target.value} defaultValue={this.props.selectedUser.password} placeholder="***************"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}