import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";


export default class AddCategoryModalForm extends Component {

    handleClose = () => fetch('https://back-products-api.therunningcollective.fr/category', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedCategory)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    render(){
        this.props.selectedCategory.headCategory = this.props.headCategory.split("/category").pop()

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedCategory.name = e.target.value} defaultValue={this.props.selectedCategory.name} placeholder="Ex : Chaussures"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Sous Catégorie : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedCategory.subCategories = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedCategory.subCategories}
                                            >
                                                {(this.props.availableCategories) ? this.props.availableCategories.map(category =>
                                                    <option value={category.name}>{category.name}</option>
                                                ) : <option>Aucunes catégories disponibles</option>}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Type de produit : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedCategory.shopType = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedCategory.shopType}
                                            >
                                                <option key="athletisme" value="athletisme">Athlétisme</option>
                                                <option key="running" value="running">Running</option>
                                                <option key="trail" value="trail">Trail</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}