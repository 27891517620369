import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import Moment from "moment";
import AddForecastLeagueModal from './modals/AddForecastLeagueModal';

function getForecastLeaguesList() {

    return fetch('https://back-api.therunningcollective.fr/forecast-leagues', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getForecasts() {

    return fetch('https://back-api.therunningcollective.fr/forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}


function getForecastName(forecastID, forecasts){
    let output 
    forecasts.map(forecast => {
        if (forecast.id == forecastID){
            output = forecast.name
        }
    })
    //console.log(output)
    return output
}

export class ForecastLeaguesList extends Component {
    state = {
        errors: null,
        selectedForecastLeague: {
            name: "",
            logo: "",
            forecastID: "",
        },
    }

    async componentDidMount() {
        await getForecasts().then(data => {
            this.setState({forecasts: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecasts"})
        );
        await getForecastLeaguesList().then(data => {
            this.setState({forecastLeagues: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecastLeagues"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedForecast: {
            title: "",
            body: "",
        }
    });


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter une ligue</Button>
                    </Col>
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.forecastLeagues && this.state.forecastLeagues.length > 0 && this.state.errors == null) ? <span>{this.state.forecastLeagues.length} ligue(s)</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des ligues, veuillez vous reconnecter"</p> : null}

                { (!this.state.forecastLeagues && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Jeu de pronostics</th>
                            <th>Nombre de participants</th>
                            <th>Date de création</th>
                            <th>Lien de partage</th>
                            <th>Password</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.forecastLeagues.map(forecastLeague => (
                            <tr>
                                {
                                    forecastLeague.imageURL && forecastLeague.imageURL != "" ?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                <div><img style={{maxHeight: "300px"}} src={forecastLeague.imageURL}/></div>
                                            </Tooltip>
                                        }
                                    >
                                        <td>{forecastLeague.name}</td>
                                    </OverlayTrigger>
                                    : 
                                    <td>{forecastLeague.name}</td>
                                }
                                
                                <td>{getForecastName(forecastLeague.forecastID, this.state.forecasts)}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            {forecastLeague.attendees ?
                                                forecastLeague.attendees.map((declination) => {return (<div key={declination.pseudo}>{declination.pseudo} ({declination.firstName} {declination.lastName}) </div>)})
                                                : "Indisponible" }
                                        </Tooltip>
                                    }
                                >
                                    <td>{forecastLeague.attendees ? forecastLeague.attendees.length : 0} participants</td>
                                </OverlayTrigger>
                                <td>{Moment(forecastLeague.createdDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{forecastLeague.leagueLink}</td>
                                <td>{forecastLeague.password}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }

                {this.state.isOpen ?
                    <AddForecastLeagueModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedForecastLeague={this.state.selectedForecastLeague}
                    />
                    :
                    null
                }
            </div>
        );

    }
}