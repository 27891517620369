import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteDynamicLinkModal from "./modals/DeleteDynamicLinkModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";
import AddDynamicLinksModal from "./modals/AddDynamicLinksModal";

function getDynamicLinks() {

    return fetch('https://back-api.therunningcollective.fr/dynamic-links', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class DynamicLinksList extends Component {
    state = {
        errors: null,
        selectedDynamicLink: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: "",
            types: [""]
        },
    }

    async componentDidMount() {
        await getDynamicLinks().then(data => {
            this.setState({dynamicLinks: data})
        }).catch(err =>
            this.setState({errors: "error while getting dynamicLinks"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedDynamicLink: {
            title: "",
            body: "",
        }
    });


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Créer un dynamic link</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.dynamicLinks && this.state.dynamicLinks.length > 0 && this.state.errors == null) ? <span>{this.state.dynamicLinks.length} ajouts de dynamicLinks</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des dynamicLinks, veuillez vous reconnecter"</p> : null}

                { (!this.state.dynamicLinks && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Lien</th>
                            <th>Dynamic Link</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.dynamicLinks.map(coin => (
                            <tr>
                                <td>{coin.name}</td>
                                <td>{coin.link}</td>
                                <td>{coin.dynamicLink}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Modifier l'ajout de dynamicLinks</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                               onClick={(e) => this.setState({isOpen: true, selectedDynamicLink: coin})}/>
                                    </td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer l'ajout de dynamicLinks</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedDynamicLink: coin})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddDynamicLinksModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedDynamicLink={this.state.selectedDynamicLink}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}

                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteDynamicLinkModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedDynamicLink}
                        selectedCategory="dynamic-link"
                    />
                    :
                    null
                }
            </div>
        );

    }
}