import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Download, Edit2, Mail, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import Moment from "moment";
import CsvDownloadButton from 'react-json-to-csv'
import InfiniteScroll from "react-infinite-scroll-component";

function getUsers(lastEvaluatedKey) {
    let body = null
    if (lastEvaluatedKey) {
        body = JSON.stringify(lastEvaluatedKey)
    }

    return fetch('https://back-api.therunningcollective.fr/strava-athletes', {
        method: 'POST',
        body: body,
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class StravaAthleteList extends Component {
    state = {
        errors: null,
        csvUsers: null,
        lastEvaluatedKey: null,
    }

    async componentDidMount() {
        await getUsers().then(data => {
            this.setState({users: data.athletes,lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false})
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );
    };

    async fetchMoreUsers(lastEvaluatedKey){
        console.log(lastEvaluatedKey)
                await getUsers(lastEvaluatedKey).then(data => (  
            console.log(data.lastEvaluatedKey ? true : false),  
            this.setState({ users: [...this.state.users, ...data.athletes ], lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        )).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
                }
        );
    }


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.users) ? <span>{this.state.users.length} comptes Strava connectés</span> : null }
                    </Col>
                    <Col xs={1}>
                        <CsvDownloadButton
                            data={this.state.csvUsers}
                            filename={"front_users_"+ new Date().toLocaleDateString()+"_"+new Date().toLocaleTimeString()+".csv"}
                            style={{
                                background: "none",
                                border: "none"
                            }}
                        >
                            <Download style={{cursor:"pointer"}}/>
                        </CsvDownloadButton>
                    </Col>
                </Row>

                { (!this.state.users && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <InfiniteScroll
                            scrollThreshold="10%"
                            style={{overflow: "inherit"}}
                            dataLength={this.state.users.length}
                            next={() => this.fetchMoreUsers(this.state.lastEvaluatedKey)}
                            hasMore={this.state.hasMoreValue}
                            loader={<div style={{textAlign: 'center'}}>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>}
                            >
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Sexe</th>
                            <th>Date de MAJ</th>
                            <th>Chaussures</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map(user => (
                            <tr>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.sex == "M" ? "Homme" : "Femme"}</td>
                                <td>{Moment(user.updated_trc_at).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            {user.shoes ?
                                                user.shoes.map((declination) => {return (<div key={declination.name}>{declination.name} ({declination.converted_distance}km)</div>)})
                                                : "Indisponible" }
                                        </Tooltip>
                                    }
                                >
                                    <td>{user.shoes && user.shoes.length > 0 ? user.shoes.length : "0" }</td>
                                </OverlayTrigger>
                            </tr>
                        ))}
                        </tbody>
                        </InfiniteScroll>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddUserModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedUser={this.state.selectedUser}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedUser}
                        selectedCategory="user"
                    />
                    :
                    null
                }
            </div>
        );

    }
}
