import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddMerchandModalForm from "./modals/AddMerchandModal";
import DeleteMerchantModal from "./modals/DeleteMerchantModal";
import Logout from "./Logout";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getLanguages() {

    return fetch('https://back-products-api.therunningcollective.fr/languages', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(merchant, checked) {
    merchant.enable = checked
    
    return fetch('https://back-products-api.therunningcollective.fr/merchant', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(merchant)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class MerchandsList extends Component{
    state = {
        merchands: null,
        languages: [],
        isOpen: false,
        errors: null,
        isDelete: false,
        selectedMerchand: {
            name : "",
            s3logoUrl: ""
        }
    }

    async componentDidMount() {
        await getMerchands().then(data => {
            this.setState({ merchands: data })
        }).catch(err =>
            this.setState({errors: "error while getting merchands"})
        );
        await getLanguages().then(data => {
            this.setState({ languages: data })
        }).catch(err =>
            this.setState({errors: "error while getting languages"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedMerchand: { name : "", } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un site marchand</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup  value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode : e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                    
                </Row>
                
               
                { this.state.merchands ? 
                <div>{this.state.merchands.length} marchands disponibles </div> : null}
                <br/>

                { !this.state.merchands ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Logo</th>
                            <th>Temps livraison</th>
                            <th>Délais de retour</th>
                            <th>URL affiliée</th>
                            <th>Affil ID</th>
                            <th>Pays disponibles</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.merchands.map(merchant => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le magasin sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={merchant.enable}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(merchant, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{merchant.name}</td>
                                <td><img className="imgToDisplay" alt="Logos..." src={merchant.s3LogoUrl}/></td>
                                <td>{merchant.deliveryTime}</td>
                                <td>{merchant.returningTime}</td>
                                <td>{truncateTxt(merchant.url,50)}</td>
                                <td>{merchant.affilID}</td>
                                <td>
                                    {merchant.availableLanguages ? merchant.availableLanguages.map(language => {
                                        return(<span>{language}, </span>)
                                    }) : null}
                                </td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedMerchand : merchant})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete : true, selectedMerchand : merchant})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddMerchandModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedMerchand={this.state.selectedMerchand}
                        languages={this.state.languages}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteMerchantModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedMerchand}
                        selectedCategory="merchant"
                    />
                    :
                    null
                }
            </div>
        );

    }

}

function truncateTxt(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};