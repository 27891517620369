import React, {Component} from 'react';
import {
    Badge,
    Table
} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Col, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import ReactPaginate from 'react-paginate';
import {FancyButton, getUnique} from "./DetailedReportingList";
import unslug from "unslug";
import {downloadFile} from "../pages/dashboards/Default/ProductsTraffic";
import {Download} from "react-feather";

registerLocale('fr', fr)

export function GetUserClientData(pageNumber, startDate, endDate) {
    if (startDate != null && endDate != null){
        return fetch('https://back-api.therunningcollective.fr/user-data-with-email?beginDate='+startDate.toLocaleDateString()+'&endDate='+endDate.toLocaleDateString(), {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    }
}

function exportToCsv(e, startDate, endDate, data){
    if (data && startDate && endDate){
        e.preventDefault()

        // Headers for each column
        let headers = ['IP']

        // Convert users data to a csv
        let usersCsv = data.reduce((acc, user) => {
            const {query} = user
            acc.push(query)
            return acc
        }, [])
        usersCsv = getUnique(usersCsv)
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'query_'+startDate.toLocaleDateString()+'_to_'+endDate.toLocaleDateString()+'.csv',
            fileType: 'text/csv',
        })
    }
}

export class DetailedUserClientDataList extends Component {

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.renderRefusalReason = this.renderRefusalReason.bind(this);
    }

    state = {
        currentItems: null,
        errors: null,
        pageNumber: 1,
        startDate: new Date(),
        endDate: new Date(),
        pageCount: 0,
        itemOffset: 0,
        itemsPerPage: 15,
        currentsItems: null,
        options: {
            maintainAspectRatio: false,
            cutoutPercentage: 70,
            legend: {
                display: false
            }
        }
    }

    async componentDidMount() {
        await GetUserClientData(this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
            const uniqueData = data.userData
                .filter(e => e.lastName !== "");
            this.setState({userDatas: getUnique(uniqueData)})
            if (uniqueData){
                const endOffset = this.state.itemOffset +  this.state.itemsPerPage;
                this.setState({currentItems : uniqueData.slice(this.state.itemOffset, endOffset)});
                this.setState({pageCount : Math.ceil(uniqueData.length / this.state.itemsPerPage)});
            }
        }).catch(err =>{

                this.setState({errors: "error while getting userDatas"})
        }
        );
    };

    async componentDidUpdate(prevProps, prevState) {
        if(prevState.itemOffset !== this.state.itemOffset){
            const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
            this.setState({currentItems : this.state.userDatas.slice(this.state.itemOffset, endOffset2)});
            this.setState({pageCount : Math.ceil(this.state.userDatas.length / this.state.itemsPerPage)});
        }
        if ((prevState.pageNumber !== this.state.pageNumber) || (prevState.startDate !== this.state.startDate) || (prevState.endDate !== this.state.endDate))  {
            if (this.state.startDate != null && this.state.endDate != null){
                await GetUserClientData(this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
                    const uniqueData = data.userData
                        .filter(e => e.lastName !== "");
                    this.setState({userDatas: getUnique(uniqueData)})
                    if(uniqueData){
                        const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
                        this.setState({currentItems : uniqueData.slice(this.state.itemOffset, endOffset2)});
                        this.setState({pageCount : Math.ceil(uniqueData.length / this.state.itemsPerPage)});
                    }
                }).catch(err =>
                    this.setState({errors: "error while getting userDatas"})
                );
            }
        }
    }


    handlePageClick(event){
        const newOffset = (event.selected * this.state.itemsPerPage) % this.state.userDatas.length;
        this.setState({itemOffset: newOffset});
    }

    renderRefusalReason = (refusalReason) => (
        <Tooltip id="button-tooltip">
            <div key={refusalReason}>{refusalReason}</div>
        </Tooltip>
    );



    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1>Non autorisé</h1>
            </div>
        }

        return (
            <div>
                <br/>
                <Row>
                    <Col md={9}>
                        <DatePicker
                            selectsRange={true}
                            todayButton="Aujourd'hui"
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(update) => {
                                this.setState({startDate: update[0], endDate: update[1]});
                            }}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <FancyButton/>
                            }/>

                    </Col>
                    <Col md={2}>
                        {this.state.userDatas && this.state.userDatas.length ?
                            <span>{this.state.userDatas.length} pages visisonnées</span>
                        : null}
                    </Col>
                    <Col xs={1}>
                        <Download style={{cursor:"pointer"}} onClick={(e) => exportToCsv(e, this.state.startDate, this.state.endDate, this.state.userDatas)}/>
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des userDatas, veuillez vous reconnecter"</p> : null}

                { (!this.state.currentItems && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive className="products-list">
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>IP</th>
                            <th>Email</th>
                            <th>Appareil</th>
                            <th>Pays</th>
                            <th>Region</th>
                            <th>Ville</th>
                            <th>Code Postal</th>
                            <th>Page visionnée</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.currentItems && this.state.currentItems!= null && this.state.currentItems.length > 0 && this.state.currentItems.map(userData => (
                            <tr>
                                <td>{userData.lastName}</td>
                                <td>{userData.firstName}</td>
                                <td>{userData.query}</td>
                                <td>{userData.email}</td>
                                <td>{userData.mobile ? "Mobile" : "Ordinateur"}</td>
                                <td>{userData.country}</td>
                                <td>{userData.regionName}</td>
                                <td>{userData.city}</td>
                                <td>{userData.zip}</td>
                                <td>{userData.location}</td>
                                <td>{new Date(userData.requestDate).toLocaleDateString()} à {new Date(userData.requestDate).toLocaleTimeString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
            <div style={{'display': 'flex', 'justify-content': 'center'}}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="suivant >"
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={this.state.pageCount}
                    previousLabel="< précédent"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </div>
            </div>

        );

    }
}

