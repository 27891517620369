import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Moment from "moment";
import DeletePhysicalShopActivationModal from "./modals/DeletePhysicalShopActivationModal";
import AddPhysicalShopActivationModal from "./modals/AddPhysicalShopActivationModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function getPhysicalShopActivationsList() {

    return fetch('https://back-api.therunningcollective.fr/physical-shop-activations', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getProducts() {

    return fetch('https://back-products-api.therunningcollective.fr/small-products?headCategories=/products/shoes,/products/apparel,/products/spikes,/products/electronic,/products/accessories,/products/nutrition', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(physicalShopActivation, checked) {
    physicalShopActivation.state = checked
    return fetch('https://back-api.therunningcollective.fr/physical-shop-activation', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(physicalShopActivation)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class PhysicalShopActivationsList extends Component{
    state = {
        physicalShopActivationsList: [],
        countOfEmptyDescription: 0,
        countOfEmptyEvents: 0,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        selectedPhysicalShopActivation : {
            name: "",
            beginDate: "",
            endDate: "",
            shopType: "",
            productsSlug: [],
            image: "",
        }
    }

    async componentDidMount() {
        await getPhysicalShopActivationsList().then(data => {
            this.setState({ physicalShopActivationsList: data ? data : [] })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting physicalShopActivationsList"})
        );
        await getProducts().then(data => {
            this.setState({ availableProducts: data })
        })
    };

    closeModal = () => this.setState({ isOpen: false, selectedPhysicalShopActivation : {
        name: "",
    } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={9} className="products-buttons">
                        <span>Nombre d'offre : {this.state.physicalShopActivationsList.length}</span>
                    </Col>
                    

                    <Col sm={3} className="products-buttons">
                    {
                        this.state.availableProducts ?
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une activation</Button>
                        : null
                    }
                    </Col>
                </Row>
                { (!this.state.physicalShopActivationsList) ? <p> Aucune activation disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Image</th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>Type de magasins</th>
                            <th>Produits associés</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.physicalShopActivationsList.length > 0 && this.state.physicalShopActivationsList.map(physicalShopActivation => (
                            <tr key={physicalShopActivation.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Activer l'offre</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={physicalShopActivation.state}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(physicalShopActivation, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{physicalShopActivation.name}</td>
                                <td><img className="imgToDisplay" alt="Logo..." src={physicalShopActivation.image}/></td>
                                <td>{Moment(physicalShopActivation.beginDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{Moment(physicalShopActivation.endDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{physicalShopActivation.shopType}</td>
                                <td>
                                    <ul>
                                    {physicalShopActivation.productsSlug && physicalShopActivation.productsSlug.map(item => {
                                        if(item.shortURL != ""){
                                            return (
                                                <li>{item.shortURL}</li>
                                            )
                                        }
                                    })}
                                    </ul>
                                 </td>
                                {
                                    this.state.availableProducts ?
                                        <td className="table-action">
                                            <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedPhysicalShopActivation : physicalShopActivation})}/>
                                        </td>
                                    : null
                                }
                                {
                                    this.state.availableProducts ?
                                        <td className="table-action">
                                            <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedPhysicalShopActivation: physicalShopActivation})} />
                                        </td> 
                                    : null 
                                }
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

    
                { this.state.isOpen ?
                    <AddPhysicalShopActivationModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        availableProducts={this.state.availableProducts}
                        selectedPhysicalShopActivation={this.state.selectedPhysicalShopActivation}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeletePhysicalShopActivationModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedPhysicalShopActivation}
                        selectedCategory="physical-shop-option"
                    />
                    :
                    null
                }
            </div>
        );

    }

}