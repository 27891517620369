import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteModal from "./modals/DeleteModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";

function getVouchers() {

    return fetch('https://backapi.therunningcollective.fr/api/protected/adidas-vouchers', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class AdidasVouchersList extends Component{
    state = {
        vouchers: null,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        selectedVoucher: {
            name : "",
            merchand: {
                name: ""
            },
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: ""
        }
    }

    async componentDidMount() {
        await getVouchers().then(data => {
            this.setState({ vouchers: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting vouchers"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedVoucher: {
            name : "",
            errors: null,
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: ""
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={5} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isVouchersOpen : true})}>Ajouter des coupons de réductions adidas</Button>
                    </Col>
                </Row>
                { (!this.state.vouchers) ? <p> Aucuns coupons disponibles.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Utilisé</th>
                            <th>Date d'utilisation : </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.vouchers.map(voucher => (
                            <tr key={voucher.id}>
                                <td>{voucher.name}</td>
                                <td>{voucher.used ? "oui" : "non"}</td>
                                <td>{voucher.usedDate}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
            </div>
        );

    }

}