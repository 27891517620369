import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteRaceModal from "./modals/DeleteRaceModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import UpdateRaceForm from "./modals/UpdateRaceModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {CiWarning} from "react-icons/ci";
import InfiniteScroll from "react-infinite-scroll-component";

function getRaces(lastEvaluatedKey) {

    let body = null
    if (lastEvaluatedKey) {
        body = JSON.stringify(lastEvaluatedKey)
    }

    return fetch('https://back-api.therunningcollective.fr/paginated-races', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
        body: body,
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(race, checked) {
    race.state = checked
    return fetch('https://back-api.therunningcollective.fr/race', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(race)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class RacesList extends Component{
    state = {
        races: [],
        countOfEmptyDescription: 0,
        countOfEmptyEvents: 0,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        lastEvaluatedKey: null,
        selectedRace : {
            events: [],
            name: "",
            description: "",
            metaTitle:"",
            metaDescription:"",
        }
    }

    async componentDidMount() {
        await getRaces().then(data => {
            var count = 0
            var countEvents = 0

            data.races.map((race) => {
                if (race.description == ""){
                    count++
                }
                if (!race.events || (race.events && race.events.length == 0)){
                    countEvents++
                }
            })
            this.setState({countOfEmptyEvents: countEvents})
            this.setState({countOfEmptyDescription: count})
            this.setState({ races: data.races, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting races"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedRace: {
            events: [],
            name: "",
            description: "",
            metaTitle:"",
            metaDescription:"",
        } });

    async fetchMoreRaces(lastEvaluatedKey){
        console.log(lastEvaluatedKey)
                await getRaces(lastEvaluatedKey).then(data => (  
            console.log(data.lastEvaluatedKey ? true : false),  
            this.setState({ races: [...this.state.races, ...data.races ], lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        )).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
                }
        );
    }

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={3} className="products-buttons">
                        <span>Nombre de course : {this.state.races.length}</span>
                    </Col>
                    {
                        this.state.countOfEmptyDescription > 0 ?
                            <Col sm={3} className="products-buttons">
                                <CiWarning size={"2em"}/>    <a style={{color : "red"}}>{this.state.countOfEmptyDescription}</a> courses sans description.
                            </Col>
                            : null
                    }
                    {
                        this.state.countOfEmptyEvents > 0 ?
                            <Col sm={3} className="products-buttons">
                                <CiWarning size={"2em"}/>    <a style={{color : "red"}}>{this.state.countOfEmptyEvents}</a> courses sans évènement.
                            </Col>
                            : null
                    }

                    <Col sm={3} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une course</Button>
                    </Col>
                </Row>
                { (!this.state.races) ? <p> Aucune course disponible.</p>
                    :
                    <Table responsive>
                        <InfiniteScroll
                            scrollThreshold="10%"
                            style={{overflow: "inherit"}}
                            dataLength={this.state.races.length}
                            next={() => this.fetchMoreRaces(this.state.lastEvaluatedKey)}
                            hasMore={this.state.hasMoreValue}
                            loader={<div style={{textAlign: 'center'}}>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>}
                            >
                                <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Nom</th>
                            <th>Partenaire</th>
                            <th>Epreuves</th>
                            <th>Label</th>
                            <th>Ranking</th>
                            <th>Type</th>
                            <th>Sous-Catégorie</th>
                            <th>Région</th>
                            <th>Département</th>
                            <th>Ville</th>
                            <th>Pays</th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                                {!this.state.loading ? this.state.races.length > 0 && this.state.races.map(race => (
                            <tr key={race.id}>
                                {
                                    race.description == "" ?
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Description manquante</Tooltip>}
                                        >
                                        <td><CiWarning size={"1.5em"}/></td>
                                        </OverlayTrigger>: <td></td>
                                }
                                {
                                    !race.events || (race.events && race.events.length == 0) ?
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Evenement manquant</Tooltip>}
                                        >
                                            <td><CiWarning size={"1.5em"}/></td>
                                        </OverlayTrigger>: <td></td>
                                }
                                <td>{race.name}</td>
                                <td>{race.partnerName}</td>
                                <td>{race.events ? race.events.length : 0}</td>
                                <td>{race.label}</td>
                                <td>{race.ranking}</td>
                                <td>{race.type}</td>
                                <td>{race.subCategory}</td>
                                <td>{race.region}</td>
                                <td>{race.department}</td>
                                <td>{race.town}</td>
                                <td>{race.country}</td>
                                <td>{Moment(race.beginDate).format('DD-MM-YYYY').toString()}</td>
                                <td>{Moment(race.endDate).format('DD-MM-YYYY').toString()}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le produit sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={race.state}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(race, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedRace : race})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedRace: race})} />
                                </td>
                            </tr>
                        )) : null}
                                                </tbody>
                        </InfiniteScroll>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                { this.state.isOpen ?
                    <UpdateRaceForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedRace={this.state.selectedRace}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteRaceModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedRace}
                        selectedCategory="race"
                    />
                    :
                    null
                }
            </div>
        );

    }

}