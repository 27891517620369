import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteVoucherModal from "./modals/DeleteVoucherModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getVouchers() {

    return fetch('https://back-products-api.therunningcollective.fr/vouchers', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getProducts() {

    return fetch('https://back-products-api.therunningcollective.fr/small-products?headCategories=/products/shoes,/products/apparel,/products/spikes,/products/electronic,/products/accessories,/products/nutrition', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getBrands() {
    return fetch('https://back-products-api.therunningcollective.fr/brands', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class VouchersList extends Component{
    state = {
        vouchers: null,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        availableTypes:["athletisme", "running", "trail"],
        availableHeadCategories: [
            {
                id: "/products/apparel",
                name: "Vêtements"
            },
            {
                id: "/products/shoes",
                name: "Chaussures"
            },
            {
                id: "/products/spikes",
                name: "Pointes"
            },
            {
                id: "/products/accessories",
                name: "Accessoires"
            },
            {
                id: "/products/electronic",
                name: "Electronique"
            },
            {
                id: "/products/nutrition",
                name: "Diététique"
            },
        ],
        selectedVoucher: {
            name : "",
            merchand: {
                name: ""
            },
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: "",

        }
    }

    async componentDidMount() {
        await getVouchers().then(data => {
            this.setState({ vouchers: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting vouchers"})
        );
        await getMerchands().then(data => {
            this.setState({ availableMerchands: data })
        })
        await getBrands().then(data => {
            this.setState({ availableBrands: data })
        })
        await getProducts().then(data => {
            this.setState({ availableProducts: data })
        })
    };

    closeModal = () => this.setState({ isOpen: false, selectedVoucher: {
            name : "",
            merchand: {
                name: ""
            },
            errors: null,
            products: [],
            cumulativeVouchers: [],
            isPermanent: false,
            beginDate: "",
            endDate: ""
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={5} className="products-buttons">
                        {
                            this.state.availableProducts ?
                                <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un coupon de réduction</Button>
                                : null
                        }
                    </Col>
                </Row>
                { (!this.state.vouchers) ? <p> Aucuns coupons disponibles.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Marchand</th>
                            <th>Date de début</th>
                            <th>Date de fin</th>
                            <th>Réduction : </th>
                            <th>Produits éligibles : </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.vouchers.map(voucher => (
                            <tr key={voucher.id}>
                                <td>{voucher.name}</td>
                                <td>{voucher.merchantName}</td>
                                <td>{Moment(voucher.beginDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{Moment(voucher.endDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{voucher.reductionAmount}{voucher.voucherType}</td>
                                <td>{voucher.products ? voucher.products.length : 0}</td>
                                {
                                    this.state.availableProducts ?
                                        <td className="table-action">
                                            <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedVoucher : voucher})}/>
                                        </td>
                                        : null
                                }
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedVoucher: voucher})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                { this.state.isOpen ?
                    <AddVoucherModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedVoucher={this.state.selectedVoucher}
                        availableMerchands={this.state.availableMerchands}
                        availableProducts={this.state.availableProducts}
                        availableVouchers={this.state.vouchers}
                        availableBrands={this.state.availableBrands}
                        availableHeadCategories={this.state.availableHeadCategories}
                        availableTypes={this.state.availableTypes}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteVoucherModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedVoucher}
                        selectedCategory="voucher"
                    />
                    :
                    null
                }
            </div>
        );

    }

}