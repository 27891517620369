import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component, useEffect, useState }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';
import { Spinner } from 'reactstrap';

function ModifyAmbassadorModal({isOpen, closeModal, user}) {
    const [selectedUser, setSelectedUser] = useState(user);
    const [address, setAddress] = useState(user && user.address ? user.address : "");
    const [zipCode, setZipCode] = useState(user && user.zipCode ? user.zipCode : "");
    const [town, setTown] = useState(user && user.town ? user.town : "");
    const [type, setType] = useState(user && user.type ? user.type : "");
    const [shoeSize, setShoeSize] = useState(user && user.shoeSize ? user.shoeSize : "");
    const [apparelSize, setApparelSize] = useState(user && user.apparelSize ? user.apparelSize : "");

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const handleClose = (selectedUser) => {
        setLoadingSubmit(true)
        let user = selectedUser
        user.town = town
        user.type = type
        user.shoeSize = shoeSize
        user.apparelSize = apparelSize
        user.address = address
        user.zipCode = zipCode
        console.log(user)

        return fetch('https://back-api.therunningcollective.fr/ambassador', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(() => {
            window.location.reload();
        })
    }


    return(
        <Modal
            show={isOpen}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Modifier un ambassadeur ({user && user.firstName} {user && user.name})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Form.Group >
                                        <Form.Group >
                                            <Form.Label>Adresse : </Form.Label>
                                            <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="34 Avenue du Kerzu"/>
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Group >
                                            <Form.Label>Code postal : </Form.Label>
                                            <Form.Control type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="29200"/>
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Group >
                                            <Form.Label>Ville : </Form.Label>
                                            <Form.Control type="text" value={town} onChange={(e) => setTown(e.target.value)} placeholder="Brest"/>
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Type de pratique </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setType(e.target.value)}
                                            defaultValue={type}
                                        >
                                            <option key="" value="">...</option>
                                            <option key="Athlétisme" value="Athlétisme">Athlétisme</option>
                                            <option key="Running" value="Running">Running</option>
                                            <option key="Trail" value="Trail">Trail</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Taille de chaussures </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setShoeSize(e.target.value)}
                                            defaultValue={shoeSize}
                                        >
                                            <option key="" value="">...</option>
                                            {
                                                GetAvailableSizes().map((size, index) => {
                                                    return(
                                                        <option key={index} value={size}>{size}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Taille de vêtements </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setApparelSize(e.target.value)}
                                            defaultValue={apparelSize}
                                        >
                                            <option key="" value="">...</option>
                                            <option key="0" value="XXS">XXS</option>
                                            <option key="1" value="XS">XS</option>
                                            <option key="2" value="S">S</option>
                                            <option key="3" value="M">M</option>
                                            <option key="4" value="L">L</option>
                                            <option key="5" value="XL">XL</option>
                                            <option key="6" value="XXL">XXL</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                {
                    loadingSubmit ?
                    <Row style={{textAlign:"center"}}>
                        <Col>
                            <div style={{textAlign: 'center'}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>
                    :
                    <>
                        <Button variant="secondary" type="cancel" onClick={closeModal}>
                            Annuler
                        </Button>
                        {
                            selectedUser ?
                                <Button variant="primary" type="submit" onClick={() => handleClose(selectedUser)}>
                                    Valider
                                </Button>
                                : <Button variant="primary" type="submit" disabled>
                                    Valider
                                </Button>

                        }
                    </>
                
            }
                
            </Modal.Footer>
        </Modal>
    )
}

export default ModifyAmbassadorModal;

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.userID)
            )
        )
    }

    return arrayToReturn
}

function getSelectedItem(item){
    var arrayToReturn = []
    if(item && item.userID && item.userID != ""){
        arrayToReturn.push(item.userID)
    }
    return arrayToReturn
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export function GetAvailableSizes(){
    return [
        "36",
        "36½",
        "37",
        "37½",
        "38",
        "38½",
        "39",
        "39½",
        "40",
        "40½",
        "41",
        "41½",
        "42",
        "42½",
        "43",
        "43½",
        "44",
        "44½",
        "45",
        "45½",
        "46",
        "46½",
        "47",
        "47½",
        "48",
        "48½",
        "49",
        "49½",
        "50",
        "50½",
        "51",
        "51½"
    ]
}

export const ConstructDistance = (type) => {
    if (type.includes("Athlétisme")){
        return [...distanceRunning, ...distanceAthletisme]
    } else if (type.includes("Running")){
        return distanceRunning
    } else if (type.includes("Trail")){
        return distanceTrail
    } else if (type.includes("Triathlon")){
        return distanceTriathlon
    } else {
        return [...distanceRunning, ...distanceTriathlon, ...distanceAthletisme]
    }
}

const distanceRunning = [
    "5km",
    "10km",
    "20km",
    "Semi-Marathon",
    "Marathon",
    "100km"
]

const distanceTrail = [
    "- de 10km",
    "de 10 à 20km",
    "entre 20 et 40km",
    "de 40 à 80km",
    "80km et +"
]

const distanceTriathlon = [
    "XXS",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL"
]

const distanceAthletisme = [
    "50m",
    "60m",
    "50m haies",
    "60m haies",
    "100m",
    "100m haies",
    "110m haies",
    "200m",
    "400m",
    "400m haies",
    "800m",
    "1000m",
    "1000m marche",
    "1500m",
    "Mile",
    "2000m",
    "2000m marche",
    "3000m",
    "3000m marche",
    "3000m Steeple",
    "2 Miles",
    "5000m",
    "5000m marche",
    "10000m",
    "10000m marche",
    "Disque",
    "Marteau",
    "Javelot",
    "Poids",
    "Hauteur",
    "Longueur",
    "Triple-saut"
]