import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeletePhysicalShopModal from "./modals/DeletePhysicalShopModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import AddPhysicalShopModal from "./modals/AddPhysicalShopModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {CiWarning} from "react-icons/ci";

function getPhysicalShops() {

    return fetch('https://back-api.therunningcollective.fr/physical-shops', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getPhysicalShopOptions() {

    return fetch('https://back-api.therunningcollective.fr/physical-shop-options', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(physicalShop, checked) {
    physicalShop.state = checked
    
    return fetch('https://back-api.therunningcollective.fr/physical-shop', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(physicalShop)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class PhysicalShopsList extends Component{
    state = {
        physicalShops: [],
        countOfEmptyDescription: 0,
        countOfEmptyEvents: 0,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        selectedPhysicalShop : {
            openingHours: {
                closed: false,
                monday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                tuesday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                wednesday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                thursday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                friday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                saturday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
                sunday: {
                    closed: false,
                    morning: {
                        begin: "",
                        end: "", 
                        closed: false
                    }, 
                    evening: {
                        begin: "",
                        end: "", 
                        closed: false
                    }
                },
            },
            location: {
                town: "",
                region: "",
                zipcode: "",
                country: "",
                address: "",
                coordinates: {
                    lat:"", 
                    long:""
                },
            },
            name: "",
        }
    }

    async componentDidMount() {
        await getPhysicalShops().then(data => {
            this.setState({ physicalShops: data ? data : [] })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting physicalShops"})
        );
        await getPhysicalShopOptions().then(data => {
            this.setState({ physicalShopOptions: data ? data : [] })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting physicalShopOptions"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedPhysicalShop : {
        location: {
            town: "",
            region: "",
            zipcode: "",
            country: "",
            address: "",
            coordinates: {
                lat:"", 
                long:""
            }
        },
        name: "",
    } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={9} className="products-buttons">
                        <span>Nombre de magasins physiques : {this.state.physicalShops.length}</span>
                    </Col>
                    

                    <Col sm={3} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un magasin physique</Button>
                    </Col>
                </Row>
                { (!this.state.physicalShops) ? <p> Aucun magasin physique disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Adresse</th>
                            <th>Coordonnées</th>
                            <th>Type</th>
                            <th>Flux produits ?</th>
                            <th>Offre</th>
                            <th>Logo</th>
                            <th>Logo sur carte</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.physicalShops.length > 0 && this.state.physicalShops.map(physicalShop => (
                            <tr key={physicalShop.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le magasin sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={physicalShop.state}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(physicalShop, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{physicalShop.name}</td>
                                <td>
                                    <div>{physicalShop.location.address}</div>
                                    <div>{physicalShop.location.zipCode} - {physicalShop.location.town}</div>
                                </td>
                                <td>
                                    <div>Lat: {physicalShop.location.coordinates.lat}</div>
                                    <div>Long: {physicalShop.location.coordinates.long}</div>
                                </td>
                                <td>{physicalShop.typeOfProductFeed}</td>
                                <td>{physicalShop.productsFeedURL != "" ? "Oui" : "Non"}</td>
                                <td>{physicalShop.offerType}</td>
                                <td>
                                    <img style={{
                                        maxWidth: '100px',
                                    }} src={physicalShop.logo}/>
                                </td>
                                <td>
                                    <img style={{
                                        maxWidth: '30px',
                                    }} src={physicalShop.mapLogo}/>
                                </td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedPhysicalShop : physicalShop})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedPhysicalShop: physicalShop})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

    
                { this.state.isOpen ?
                    <AddPhysicalShopModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedPhysicalShop={this.state.selectedPhysicalShop}
                        physicalShopOptions={this.state.physicalShopOptions}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeletePhysicalShopModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedPhysicalShop}
                        selectedCategory="physical-shop"
                    />
                    :
                    null
                }
            </div>
        );

    }

}