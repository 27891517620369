import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';

export default class AddFriendsReferredModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedUsers: this.props.selectedFriendReferred.user ? [this.props.selectedFriendReferred.user] : null,
        };
    }

    handleClose = (friendReferred) => {
        fetch('https://back-api.therunningcollective.fr/friend-referred', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(friendReferred)
        })
            .then( this.props.closeModal)
            .catch( err =>
                console.log(err)
            );
    }
    
    render(){
        console.log(this.props.selectedFriendReferred)
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter des TRFriendReferreds à un utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedFriendReferred.code = e.target.value} defaultValue={this.props.selectedFriendReferred.code} placeholder="CAMPUS"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nombre de TRCoins offerts : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedFriendReferred.nbOfCoins = parseInt(e.target.value,10)} defaultValue={this.props.selectedFriendReferred.nbOfCoins} placeholder="100"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => this.handleClose(this.props.selectedFriendReferred)}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.userID)
            )
        )
    }

    return arrayToReturn
}

function getSelectedItem(item){
    var arrayToReturn = []
    if(item && item.userID && item.userID != ""){
        arrayToReturn.push(item.userID)
    }
    return arrayToReturn
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}