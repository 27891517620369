import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Spinner, Tabs} from "react-bootstrap";

import Moment from "moment";

function getForecastAthletesList(distance, gender) {

    distance = distance.split(" - ", 1)[0];
    let query = 'https://back-api.therunningcollective.fr/forecast-athletes'
    if (distance) {
        query = query + "?distance="+distance
    }
    if (gender) {
        query = query + "&gender="+gender
    }
    return fetch(query, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}


const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 80, label: '80' },
    { value: 80, label: '174' }
]

export default class AddForecastContentModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            forecasts: this.props.forecasts,
            selectedAthletes: this.props.selectedForecastContent.athletes ? [this.props.selectedForecastContent.athletes] : null,
            loading: true,
            numberOfNewDeclination: 1,
            newStartlist: {
                name : "",
                athletes: [],
            },
        };
    }

    async componentDidMount() {
        await getForecastAthletesList(this.props.selectedForecastContent.name, this.props.selectedForecastContent.gender).then(data => {
            this.setState({forecastAthletes: data, loading: false,})
        }).catch(err =>
            this.setState({errors: "error while getting forecastAthletes"})
        );
    };

    handleClose = () => fetch('https://back-api.therunningcollective.fr/forecast-content', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedForecastContent)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        );

    addNewDeclination(content){
        if (!content.timeTable){
            content.timeTable = []
        }
        content.timeTable.push(this.state.newStartlist)
        this.setState({numberOfNewDeclination: content.timeTable.length})
    }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une épreuve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                        <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Informations générales</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Start-list</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Pronostic : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecastContent.forecastID = e.target.value}
                                                defaultValue={this.props.selectedForecastContent.forecastID}
                                            >
                                                <option >Ajouter...</option>
                                                {
                                                    this.state.forecasts ? 
                                                    this.state.forecasts.map(forecast => {
                                                       return(<option value={forecast.id}>{forecast.name}</option>)
                                                    }) : null
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecastContent.name = e.target.value} defaultValue={this.props.selectedForecastContent.name} placeholder="Ex : JO Paris 2024"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Sexe : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecastContent.gender = e.target.value}
                                                defaultValue={this.props.selectedForecastContent.gender}
                                            >
                                                <option >Ajouter...</option>
                                                <option value="Hommes">Hommes</option>
                                                <option value="Femmes">Femmes</option>
                                                <option value="Mixte">Mixte</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Type : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecastContent.type = e.target.value}
                                                defaultValue={this.props.selectedForecastContent.type}
                                            >
                                                <option >Ajouter...</option>
                                                <option value="Qualifications">Qualifications</option>
                                                <option value="1er tour">1er tour</option>
                                                <option value="Repêchages">Repêchages</option>
                                                <option value="Séries">Séries</option>
                                                <option value="Demi-finales">Demi-finales</option>
                                                <option value="Finale">Finale</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedForecastContent.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedForecastContent.beginDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group> 
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Tabs id="uncontrolled-tab-example">
                                            {this.props.selectedForecastContent.timeTable && this.props.selectedForecastContent.timeTable.map((declinaiton, index) =>(
                                                    <Tab key={index} eventKey={index} title={index}>
                                                        <Form.Group >
                                                            <Form.Label>Nom : </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                custom
                                                                onChange={(e) => declinaiton.name = e.target.value}
                                                                defaultValue={declinaiton.name}
                                                            >
                                                                <option >Ajouter...</option>
                                                                <option value={"Groupe " + (index+1)}>Groupe {index+1}</option>
                                                                <option value={"Série " + (index+1)}>Série {index+1}</option>
                                                                <option value="Finale">Finale</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group >
                                                        <Form.Label>Athlètes : </Form.Label>
                                                            {
                                                                this.state.forecastAthletes ? 
                                                                    <Form.Control
                                                                        as="select"
                                                                        multiple
                                                                        onChange={(e) => {declinaiton.athletes = [...e.target.selectedOptions].map(o => this.state.forecastAthletes.find(athlete =>  athlete.name === o.value))}}
                                                                        defaultValue={getSelectedItems(declinaiton.athletes ? declinaiton.athletes : [])}
                                                                    >
                                                                        {this.state.forecastAthletes.map((athlete, index) =>
                                                                            <option key={athlete.id} value={athlete.name}>{athlete.name} ({athlete.country})</option>
                                                                        )}
                                                                    </Form.Control>
                                                                : <div style={{textAlign: 'center'}}>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden"></span>
                                                                </Spinner>
                                                            </div> 
                                                            }
                                                        </Form.Group>
                                                    </Tab>
                                            ))}
                                            <Tab eventKey={this.props.selectedForecastContent.timeTable ? this.props.selectedForecastContent.timeTable.length : 0} title={<Button variant= "secondary" onClick={() => this.addNewDeclination(this.props.selectedForecastContent)}>+</Button>}>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.name)
            )
        )
    }

    return arrayToReturn
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}