import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";


export default class AddPodcastModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedPodcast.image,
            etiquettes: [
                "Course",
                "Conseils",
                "Portrait",
                "Témoignage",
                "Entrainement",
                "Athlétisme",
                "Running",
                "Trail",
                "Coach",
                "TRC"
            ],
        };
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/podcast', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedPodcast)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    uploadLogo(e) {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
        fetch('https://back-api.therunningcollective.fr/upload-file', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            },
            body: JSON.stringify({
                blob:reader.result,
                fileName: "podcasts/"+fileName
            })
        }).then((res) =>
            res.json().then( result =>{
                    sleep(2000)
                    this.props.selectedPodcast.image = result.message
                    this.setState({selectedLogo: result.message})
                }
            )
        )
            .catch( err =>
                console.log(err)
            );
    }}

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un podcast</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.name = e.target.value} defaultValue={this.props.selectedPodcast.name} placeholder="Ex : Nicolas Navarro"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedPodcast.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedPodcast.description ? this.props.selectedPodcast.description : this.props.selectedPodcast.description} placeholder="Premier coureur français aux JO de 2020 ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Durée de l'épisode : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.duration = e.target.value} defaultValue={this.props.selectedPodcast.duration} placeholder="Ex : 42mn24s"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Auteur : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.author = e.target.value} defaultValue={this.props.selectedPodcast.author} placeholder="Ex : Bastien Diaz"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Etiquettes : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.props.selectedPodcast.tags = [...e.target.selectedOptions].map(o => this.state.etiquettes.find(etiquette =>  etiquette === o.value))}}
                                                defaultValue={getSelectedItems(this.props.selectedPodcast.tags)}
                                            >
                                                {this.state.etiquettes.map((etiquette, index) =>
                                                    <option key={index} value={etiquette}>{etiquette}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien Apple : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.appleLink = e.target.value} defaultValue={this.props.selectedPodcast.appleLink} placeholder="Ex : https://applepodcast.com"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien Amazon : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.amazonLink = e.target.value} defaultValue={this.props.selectedPodcast.amazonLink} placeholder="Ex : https://amazonpodcast.com"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien Deezer : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.deezerLink = e.target.value} defaultValue={this.props.selectedPodcast.deezerLink} placeholder="Ex : https://deezer.com"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien Google : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.googleLink = e.target.value} defaultValue={this.props.selectedPodcast.googleLink} placeholder="Ex : https://google.com"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien Spotify : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedPodcast.spotifyLink = e.target.value} defaultValue={this.props.selectedPodcast.spotifyLink} placeholder="Ex : https://spotify.com"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item)
            )
        )
    }

    return arrayToReturn
}