import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";


export default class AddMerchantModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedMerchantPlace.logo,
            selectedCover: this.props.selectedMerchantPlace.cover,
        };
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/merchant-place', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedMerchantPlace)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "games/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedMerchantPlace.logo = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "games/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedMerchantPlace.cover = result.message
                        this.setState({selectedCover: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    requestRegion = (event) => {
        this.props.selectedMerchantPlace.winnersCount = event.value
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une offer Corner TRC</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedMerchantPlace.name = e.target.value} defaultValue={this.props.selectedMerchantPlace.name} placeholder="Ex : Alltricks"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadCover(e)}
                                                custom
                                            />
                                            {this.state.selectedCover ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedCover}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedMerchantPlace.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedMerchantPlace.description ? this.props.selectedMerchantPlace.description : this.props.selectedMerchantPlace.description} placeholder="5 dossards pour les 10km de Paris ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL de redirection : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedMerchantPlace.redirectLink = e.target.value} defaultValue={this.props.selectedMerchantPlace.redirectLink} placeholder="Ex : https://top4running.fr/?a_box=55zm62fc"/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckboxIsTemporary">
                                            <Form.Check onChange={(e) => this.props.selectedMerchantPlace.isPrivateSale = e.target.checked}
                                                        defaultChecked={this.props.selectedMerchantPlace.isPrivateSale}
                                                        type="checkbox" label="Vente privée ?" />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Partenaire : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedMerchantPlace.merchantName = e.target.value}
                                                defaultValue={this.props.selectedMerchantPlace.merchantName}
                                            >
                                                <option >Ajouter...</option>
                                                <option key="PSS" value="PrivateSportShop">PrivateSportShop</option>
                                                <option key="ShowroomPrive" value="Showroomprive">Showroom Privé</option>
                                                <option key="Zalando" value="Privé by Zalando">Privé by Zalando</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Date de début : </Form.Label>
                                                    <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                                defaultValue={Moment(this.props.selectedMerchantPlace.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                                onChange={(e) => this.props.selectedMerchantPlace.beginDate = new Date(e.target.value).toISOString()}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Date de fin : </Form.Label>
                                                    <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                                defaultValue={Moment(this.props.selectedMerchantPlace.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                                onChange={(e) => this.props.selectedMerchantPlace.endDate = new Date(e.target.value).toISOString()}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}