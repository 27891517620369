import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";


const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 80, label: '80' },
    { value: 80, label: '174' }
]

export default class AddForecastModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedForecast.logo,
            selectedCover: this.props.selectedForecast.cover,
            selectedBackgroundImage: this.props.selectedForecast.backgroundImage,
            selectedBannerImage: this.props.selectedForecast.bannerImage,
            selectedDesktopCover: this.props.selectedForecast.desktopCover,
        };
    }

    handleClose = () => fetch('https://back-api.therunningcollective.fr/forecast', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedForecast)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        );

    uploadCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecasts/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedForecast.cover = result.message
                        this.setState({selectedCover: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadDesktopCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecasts/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedForecast.desktopCover = result.message
                        this.setState({selectedDesktopCover: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadBackgroundImage = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecasts/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedForecast.backgroundImage = result.message
                        this.setState({selectedBackgroundImage: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadBannerImage = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "forecasts/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedForecast.bannerImage = result.message
                        this.setState({selectedBannerImage: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    requestRegion = (event) => {
        this.props.selectedForecast.winnersCount = event.value
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un pronotisc</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecast.name = e.target.value} defaultValue={this.props.selectedForecast.name} placeholder="Ex : JO Paris 2024"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de fond : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadBackgroundImage(e)}
                                                custom
                                            />
                                            {this.state.selectedBackgroundImage ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedBackgroundImage}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadCover(e)}
                                                custom
                                            />
                                            {this.state.selectedCover ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedCover}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture (format carré): </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadDesktopCover(e)}
                                                custom
                                            />
                                            {this.state.selectedDesktopCover ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedDesktopCover}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Bannière : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadBannerImage(e)}
                                                custom
                                            />
                                            {this.state.selectedBannerImage ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedBannerImage}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description brève : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedForecast.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedForecast.description ? this.props.selectedForecast.description : this.props.selectedForecast.description} placeholder="Tentez de gagner 1000€ en pronotisquant ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description du jeu : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedForecast.bigDescription = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedForecast.bigDescription ? this.props.selectedForecast.bigDescription : this.props.selectedForecast.bigDescription} placeholder="Les règles du jeu sont ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Règles du jeu : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedForecast.rules = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedForecast.rules ? this.props.selectedForecast.rules : this.props.selectedForecast.rules} placeholder="Les règles du jeu sont ..."/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedForecast.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedForecast.beginDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de fin : </Form.Label>
                                            <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                          defaultValue={Moment(this.props.selectedForecast.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedForecast.endDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Ouverture des inscriptions : </Form.Label>
                                            <Form.Check aria-label="showProduct"
                                                        defaultChecked={this.props.selectedForecast.openSignIn}
                                                        onChange={(e) => this.props.selectedForecast.openSignIn = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lancement du jeu : </Form.Label>
                                            <Form.Check aria-label="showProduct"
                                                        defaultChecked={this.props.selectedForecast.officiallyLaunched}
                                                        onChange={(e) => this.props.selectedForecast.officiallyLaunched = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nombre de points pour la première place : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecast.firstPlaceCount = parseInt(e.target.value,10)} defaultValue={this.props.selectedForecast.firstPlaceCount} placeholder="100"/>
                                        </Form.Group>
                                        <Form.Group >
                                        <Form.Label>Nombre de points pour la seconde place : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecast.secondPlaceCount = parseInt(e.target.value,10)} defaultValue={this.props.selectedForecast.secondPlaceCount} placeholder="80"/>
                                        </Form.Group>
                                        <Form.Group >
                                        <Form.Label>Nombre de points pour la troisème place : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecast.thirdPlaceCount = parseInt(e.target.value,10)} defaultValue={this.props.selectedForecast.thirdPlaceCount} placeholder="50"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Liens des résultats : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedForecast.resultsLink = e.target.value} defaultValue={this.props.selectedForecast.resultsLink} placeholder="Ex : https://google.fr"/>
                                        </Form.Group>
                                        <Form.Group >
                                        <Form.Label>Site des résultats : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedForecast.resultsType = e.target.value}
                                                defaultValue={this.props.selectedForecast.resultsType}
                                            >
                                                <option value="">Sélectionner...</option>
                                                <option value="world-athletics">World Athletics</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}