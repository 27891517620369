import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteRewardModal from "./modals/DeleteRewardModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {ReactSortable} from "react-sortablejs";
import AddAmbassadorRewardModal from './modals/AddAmbassadorRewardModal';

function getAmbassadorRewards() {

    return fetch('https://back-api.therunningcollective.fr/ambassador-rewards', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}



function updateState(reward, checked) {
    reward.active = checked
    return fetch('https://back-api.therunningcollective.fr/ambassador-reward', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reward)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class AmbassadorRewardsList extends Component {
    state = {
        errors: null,
        selectedReward: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: "",
            headCategory: "/products/shoes"
        }
    }

    async componentDidMount() {
        await getAmbassadorRewards().then(data => {
            this.setState({rewards: data})
        }).catch(err =>
            this.setState({errors: "error while getting rewards"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedReward: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: ""
        }
    });

    handleDropAmbassadorRewards = (list) => {
        const newBoxState = list.map((reward, index) => {
            reward.order = index

            fetch('https://back-api.therunningcollective.fr/ambassador-reward', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reward)
            }).then(this.props.closeModal)
                .catch( err =>
                    console.log(err)
                )

            return reward;
        });

        this.setState({rewards : newBoxState});

    };


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter une récompense</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.rewards && this.state.rewards.length > 0 && this.state.errors == null) ? <span>{this.state.rewards.length} récompenses</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des récompenses, veuillez vous reconnecter"</p> : null}

                { (!this.state.rewards && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <tbody>
                        <ReactSortable list={this.state.rewards} setList={(list) => this.handleDropAmbassadorRewards(list)}>

                        {this.state.rewards.map(reward => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher la récompense sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={reward.active}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(reward, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{reward.name}</td>
                                <td>{reward.description}</td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Date de début</Tooltip>}
                                >
                                    <td>{reward.beginDate}</td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Date de fin</Tooltip>}
                                >
                                    <td>{reward.endDate}</td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Nombre de gagnants</Tooltip>}
                                >
                                    <td>{reward.winnersCount}</td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Nombre de participants</Tooltip>}
                                >
                                    <td>{reward.numberOfAttendes}</td>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Modifier la récompense</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                               onClick={(e) => this.setState({isOpen: true, selectedReward: reward})}/>
                                    </td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer la récompense</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedReward: reward})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </ReactSortable>
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddAmbassadorRewardModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedReward={this.state.selectedReward}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteRewardModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedReward}
                        selectedCategory="reward"
                    />
                    :
                    null
                }
            </div>
        );

    }
}
