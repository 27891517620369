import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Download, Edit2, Mail, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import Moment from "moment";
import CsvDownloadButton from 'react-json-to-csv'
import InfiniteScroll from "react-infinite-scroll-component";

function getUsers(lastEvaluatedKey) {
    let body = null
    if (lastEvaluatedKey) {
        body = JSON.stringify(lastEvaluatedKey)
    }

    return fetch('https://back-api.therunningcollective.fr/paginated-front-users', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
        body: body,
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getCSVUsers() {

    return fetch('https://back-api.therunningcollective.fr/csv-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class FrontUsersList extends Component {
    state = {
        errors: null,
        csvUsers: null,
    }

    async componentDidMount() {
        await getUsers().then(data => {
            const uniqueNames = data.frontUsers
                .map(e => e['email'])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => data.frontUsers[e]).map(e => data.frontUsers[e]);
            this.setState({ errors: null, users: uniqueNames, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })

        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );

        await getCSVUsers().then(data => {
            this.setState({csvUsers: data, errors: null})
        })
    };

    async fetchMoreFrontUsers(lastEvaluatedKey){
        console.log(lastEvaluatedKey)
        await getUsers(lastEvaluatedKey).then(data => (  
            console.log(data.lastEvaluatedKey ? true : false),  
            this.setState({ errors: null, users: [...this.state.users, ...data.frontUsers ], lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        )).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
            }
        );
    }


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.csvUsers && this.state.errors == null) ? <span>{this.state.csvUsers.length} comptes client</span> : null }
                    </Col>
                    {
                        this.state.csvUsers ?
                        <Col xs={1}>
                            <CsvDownloadButton
                                data={this.state.csvUsers}
                                filename={"front_users_"+ new Date().toLocaleDateString()+"_"+new Date().toLocaleTimeString()+".csv"}
                                style={{
                                    background: "none",
                                    border: "none"
                                }}
                            >
                                <Download style={{cursor:"pointer"}}/>
                            </CsvDownloadButton>
                        </Col>
                        : <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                    }
                    
                </Row>

                { (!this.state.users && this.state.errors == null) ? <p> Chargement ...</p>
                    :
            
                        <InfiniteScroll
                                scrollThreshold="50%"
                                style={{overflow: "inherit"}}
                                dataLength={this.state.users.length}
                                next={() => this.fetchMoreFrontUsers(this.state.lastEvaluatedKey)}
                                hasMore={this.state.hasMoreValue}
                                loader={<div style={{textAlign: 'center'}}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>}
                            >
                                <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Adresse</th>
                            <th>Sexe</th>
                            <th>Âge</th>
                            <th>Email</th>
                            <th>Téléphone</th>
                            <th>Langue</th>
                            <th>Version</th>
                            <th>Type</th>
                            <th>Date de création</th>
                            <th>Notifications</th>
                            <th>Activé</th>
                            <th>Strava</th>
                        </tr>
                        </thead>
                        <tbody></tbody>
                                {!this.state.loading ? this.state.users.map((user) => (
                            <tr>
                                <td>{user.name}</td>
                                <td>{user.firstName}</td>
                                <th>
                                    <div>{user.address + " " + user.zipCode+ " " + user.town + " " + user.country}</div>
                                </th>
                                <td>{user.gender}</td>
                                <td>{user.birthdayDate != "0001-01-01T00:00:00Z" ? calculateAge(user.birthdayDate) + " ans" : ""}</td>
                                <td className="table-action" style={{cursor: "pointer"}}>
                                    <Mail className="align-middle" size={18}
                                          onClick={() => window.location = 'mailto:'+user.email}/>
                                </td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.language}</td>
                                <td>{user.actualVersion}</td>
                                <td>{user.type}</td>
                                <td>{Moment(user.creation_date).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{user.iOSPushID != "" ? "Oui (iOS)" : user.androidPushID != "" ? "Oui (android)" : "Non"}</td>
                                {
                                    user.activated ?
                                        <td>{user.activated ? "Oui" : "Non"}</td>
                                        : <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Activer le compte</Tooltip>}
                                        >
                                            <td><a href={"https://app.therunningcollective.fr/activate-user?userID="+user.id} target={"_blank"}>{user.activated ? "Oui" : "Non"}</a></td>
                                        </OverlayTrigger>
                                }
                                 <td>{user.stravaAthleteID != "" ? "Oui (Strava)" : null}</td>
                            </tr>
                        )): null}
                    </Table>
                        </InfiniteScroll>
                        
                }
                {this.state.isOpen ?
                    <AddUserModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedUser={this.state.selectedUser}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedUser}
                        selectedCategory="user"
                    />
                    :
                    null
                }
            </div>
        );

    }
}

const calculateAge = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
        age_now--;
    }
    return age_now;
}