import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, FormControl, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import AddRewardModal from "./modals/AddRewardModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import AddNotificationModal from "./modals/AddNotificationModal";
import AddDynamicLinksModal from "./modals/AddDynamicLinksModal";

function getProductsFeed(retailerName) {
    let parentCategory = retailerName.split("/products-feed/").pop()

    return fetch('https://backapi.therunningcollective.fr/api/protected/products-feed?retailerName='+parentCategory, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class ProductsFeedList extends Component {
    state = {
        errors: null,
        initialProducts: null,
        productsFeed: null,
        retailerName: this.props.location.pathname,
    }

    async componentDidMount() {
        await getProductsFeed(this.state.retailerName).then(data => {
            this.setState({productsFeed: data, initialProducts: data})
        }).catch(err =>
            this.setState({errors: "error while getting productsFeed"})
        );
    };

    renderTooltipBestDeclination = (item) => (
        <Tooltip id="button-tooltip" >
            <img className="imgToDisplay" alt="Couverture actuelle" src={item.productImgURL}/>
        </Tooltip>
    );


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col md={8} className="products-buttons">
                        {(this.state.productsFeed && this.state.productsFeed.length > 0 && this.state.errors == null) ? <span>{this.state.productsFeed.length} produits</span> : null }
                    </Col>
                </Row>
                <Row>

                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des produits, veuillez vous reconnecter"</p> : null}

                { (!this.state.productsFeed && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Référence</th>
                            {this.state.retailerName == "/products-feed/iRun" ?
                                <th>Coupon applicable</th>
                                : null }
                            <th>Lien affilié</th>
                            <th>Tailles dispo</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.productsFeed.map(product => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={this.renderTooltipBestDeclination(product)}
                                >
                                    <td>{product.productName}</td>
                                </OverlayTrigger>
                                <td>{product.productID}</td>
                                {this.state.retailerName == "/products-feed/iRun" ?
                                    <td>{product.voucherAvailable == true ? "Oui" : "Non"}</td>
                                    : null }
                                <td>
                                    <a href={product.affiliateLink} target={"_blank"}>
                                        {product.affiliateLink}
                                    </a>
                                </td>
                                <td>{product.sizes}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
            </div>
        );

    }
}