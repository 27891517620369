import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {sleep} from "./AddBrandModal";

async function drawGame(selectedItem) {
    return fetch('https://back-api.therunningcollective.fr/draw-game', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedItem)
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

async function validGame(selectedItem, selectedWinners) {
    selectedItem.winners = selectedWinners
    return fetch('https://back-api.therunningcollective.fr/valid-game', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedItem)
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export default class LaunchGame extends Component {
    state = {
        errors: null,
        selectedWinners: null,
    }

    handleDrawing = async (item) => {
        drawGame(item).then( (res) => {
            console.log(res)
            this.setState({selectedWinners: res})
            }
        ).catch(
            this.setState({errors: "error while drawing item"}),
        );
    }

    handleValidating = async (item, selectedWinners) => {
        validGame(item, selectedWinners).then(
            () => (
                this.props.closeModal, 
                window.location.reload(false)
                )
        ).catch(
            this.setState({errors: "error while drawing item"}),
        );
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Lancer le jeu concours {this.props.selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.selectedWinners ?
                        <div>
                            <p>Voici le tirage au sort : </p>
                            {this.state.selectedWinners.map(winner => {
                                return (
                                    <div>
                                        <span>{winner.firstName} {winner.name} ({winner.email}) (Chaussures : {winner.shoeSize}) (Vêtements : {winner.apparelSize})</span>
                                    </div>
                                )
                            })}
                        </div>
                    : <p>Voulez vous vraiment lancer le jeu concours "<b>{this.props.selectedItem.description}</b>" ?</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    {
                        this.state.selectedWinners ?
                            <Button variant="primary" type="submit" onClick={(e) => this.handleDrawing(this.props.selectedItem)}>
                                Rejouer
                            </Button>
                            : null
                    }
                    {
                        this.state.selectedWinners ?
                            <Button variant="primary" type="submit" onClick={(e) => this.handleValidating(this.props.selectedItem, this.state.selectedWinners)}>
                                Valider le tirage
                            </Button>
                            : <Button variant="primary" type="submit" onClick={(e) => this.handleDrawing(this.props.selectedItem)}>
                                Valider
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}
