import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Download, Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import Moment from "moment";
import CsvDownloadButton from 'react-json-to-csv'
import unslug from "unslug";

function getFavoritesUsers() {

    return fetch('https://back-api.therunningcollective.fr/favorite-by-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getUsers() {

    return fetch('https://back-api.therunningcollective.fr/csv-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class FrontUsersFavoritesList extends Component {
    state = {
        errors: null,
    }

    async componentDidMount() {
        await getFavoritesUsers().then(data => {
            this.setState({favorites: data})
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );

        await getUsers().then(data => {
            if(data){
                const uniqueNames = data
                .map(e => e['email'])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => data[e]).map(e => data[e]);
            this.setState({users: uniqueNames})
            }
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );
    };


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.favorites && this.state.errors == null) ? <span>{this.state.favorites.length} produits mis en favoris</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des utilisateurs, veuillez vous reconnecter"</p> : null}

                { (!this.state.favorites && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom du produit</th>
                            <th>Référence</th>
                            <th>Utilisateur</th>
                            <th>Date de création</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.favorites.map(item => (
                            <tr>
                                <td>{unslug(item.productName)}</td>
                                <td>{item.reference}</td>
                                <td>{getClientNameByUserID(item.userID, this.state.users)}</td>
                                <td>{Moment(item.createdDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddUserModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedUser={this.state.selectedUser}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedUser}
                        selectedCategory="user"
                    />
                    :
                    null
                }
            </div>
        );

    }
}

function getClientNameByUserID(userID, users) {
    if (userID != "" && users && users.length > 0) {
        let user = users.filter(user => user.userID == userID)
        if (user.length > 0){
            return user[0].firstName + " " + user[0].name
        }
    }
}