import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Spinner, Tabs} from "react-bootstrap";
import Moment from "moment";

function getForecastContentsList(forecastID) {
    console.log(forecastID)
    if(forecastID){
        let query = 'https://back-api.therunningcollective.fr/forecast-contents?forecastID='+forecastID
        return fetch(query, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    }
}


const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 80, label: '80' },
    { value: 80, label: '174' }
]

export default class AddForecastContentResultModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            forecasts: this.props.forecasts,
            selectedForecastID: this.props.selectedForecastContent.forecastID,
            selectedForecastContentID: this.props.selectedForecastContent.forecastContentID,
            selectedForecastContent: this.props.selectedForecastContent,
            selectedAthletes: this.props.selectedForecastContent.athletes ? [this.props.selectedForecastContent.athletes] : null,
            loading: true,
            declinations: this.props.selectedForecastContent.timeTable ? this.props.selectedForecastContent.timeTable : [],
        };
    }

    async componentDidMount() {
        if(this.state.selectedForecastID){
            await getForecastContentsList(this.state.selectedForecastID).then(data => {
                this.setState({forecastContents: data, loading: false,})
            }).catch(err =>
                this.setState({errors: "error while getting forecastContents"})
            );
        }
       
    };

    async componentDidUpdate(prevProps, prevState) {
        if(this.state.selectedForecastID && prevState.selectedForecastID != this.state.selectedForecastID){
            await getForecastContentsList(this.state.selectedForecastID).then(data => {
                this.setState({forecastContents: data, loading: false,})
            }).catch(err =>
                this.setState({errors: "error while getting forecastContents"})
            );
        }
      }

    handleClose = () => {
        let output = {}
        output.forecastID = this.state.selectedForecastID
        output.forecastContentID = this.state.selectedForecastContentID
        let content = getSelectedItem(this.state.forecastContents, this.state.selectedForecastContentID)
        if(content && content.timeTable && content.timeTable.length > 0 && this.state.declinations && this.state.declinations.length > 0){
            var timeTable = []
            this.state.declinations.map((data, index) => {
                if(content.timeTable[index] && content.timeTable[index].athletes && content.timeTable[index].athletes.length > 0){
                    let athletes = content.timeTable[index].athletes
                    let output = {
                        name: data.name,
                    }
                    if(data.firstPlaceAthlete.id){
                        output.firstPlaceAthlete = getSelectedItem(athletes, data.firstPlaceAthlete.id)
                    } else {
                        output.firstPlaceAthlete = getSelectedItem(athletes, data.firstPlaceAthlete)
                    }
                    if(data.secondPlaceAthlete.id){
                        output.secondPlaceAthlete = getSelectedItem(athletes, data.secondPlaceAthlete.id)
                    } else {
                        output.secondPlaceAthlete = getSelectedItem(athletes, data.secondPlaceAthlete)
                    }
                    if(data.thirdPlaceAthlete.id){
                        output.thirdPlaceAthlete = getSelectedItem(athletes, data.thirdPlaceAthlete.id)
                    } else {
                        output.thirdPlaceAthlete = getSelectedItem(athletes, data.thirdPlaceAthlete)
                    }

                    if (!timeTable[index]){
                        timeTable[index] = {}
                    }
                    timeTable[index] = output
                    console.log(timeTable)

                }
            })
            console.log(timeTable)
            output.timeTable = timeTable
        }
       fetch('https://back-api.therunningcollective.fr/forecast-content-result', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(output)
        })
        .then( () => (
            this.props.closeModal, window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        );
    }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un résultat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Informations générales</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Start-list</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <Form.Group >
                                        <Form.Label>Pronostic : </Form.Label>
                                        <Form.Control
                                            as="select"
                                            custom
                                            onChange={(e) => this.setState({selectedForecastID: e.target.value})}
                                            defaultValue={this.state.selectedForecastID}
                                        >
                                            <option >Choix...</option>
                                            {
                                                this.state.forecasts ? 
                                                this.state.forecasts.map(forecast => {
                                                    return(<option value={forecast.id}>{forecast.name}</option>)
                                                }) : null
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Épreuves : </Form.Label> 
                                            {
                                                this.state.forecastContents ? 
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    onChange={(e) => this.setState({selectedForecastContentID: e.target.value})}
                                                    defaultValue={this.state.selectedForecastContentID ? this.state.selectedForecastContentID : null}
                                                >
                                                    <option >Choix...</option>
                                                {this.state.forecastContents.map(content => {
                                                    return(<option value={content.id}>({content.gender == "Hommes" ? "H" : content.gender == "Femmes" ? "F" : "Mixte"}) {content.name} - {content.type}</option>)
                                                })}
                                                </Form.Control> : null 
                                            }
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Tabs id="uncontrolled-tab-example">
                                            {this.state.selectedForecastContentID && this.state.forecastContents && getSelectedItem(this.state.forecastContents, this.state.selectedForecastContentID).timeTable &&
                                                getSelectedItem(this.state.forecastContents, this.state.selectedForecastContentID).timeTable.map((declinaiton, index) =>(
                                                    <Tab key={index} eventKey={index} title={declinaiton.name}>
                                                        <Form.Group >
                                                            <Form.Label>1ère place : </Form.Label>
                                                            {
                                                                declinaiton.athletes ? 
                                                                <Form.Control
                                                                    as="select"
                                                                    custom
                                                                    onChange={(e) => 
                                                                        this.state.declinations.length > 0 ?
                                                                        this.setState(prevState => {
                                                                            const newItems = [...prevState.declinations];
                                                                            if(!newItems[index]){
                                                                                newItems[index] = {
                                                                                    name:declinaiton.name
                                                                                }
                                                                            }
                                                                            newItems[index].firstPlaceAthlete = e.target.value;
                                                                            return {declinations: newItems};
                                                                        }) : 
                                                                        this.setState({
                                                                            declinations:[...this.state.declinations, {
                                                                                name:declinaiton.name,
                                                                                firstPlaceAthlete: e.target.value
                                                                            }]
                                                                        })
                                                                    }

                                                                    defaultValue={this.state.declinations && this.state.declinations.length > 0 && this.state.declinations[index] && 
                                                                        this.state.declinations[index].firstPlaceAthlete ? this.state.declinations[index].firstPlaceAthlete.id : null}
                                                                >
                                                                    <option >Choix...</option>
                                                                    {
                                                                        declinaiton.athletes.map(forecast => {
                                                                            return(<option value={forecast.id}>{forecast.name} ({forecast.country})</option>)
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                                : <div style={{textAlign: 'center'}}>
                                                                    <Spinner animation="border" role="status">
                                                                        <span className="visually-hidden"></span>
                                                                    </Spinner>
                                                                </div> 
                                                            }
                                                        </Form.Group> 
                                                        <Form.Group >
                                                            <Form.Label>2ème place : </Form.Label>
                                                            {
                                                                declinaiton.athletes ? 
                                                                <Form.Control
                                                                    as="select"
                                                                    custom
                                                                    onChange={(e) => 
                                                                        this.state.declinations.length > 0 ?
                                                                        this.setState(prevState => {
                                                                            const newItems = [...prevState.declinations];
                                                                            if(!newItems[index]){
                                                                                newItems[index] = {
                                                                                    name:declinaiton.name
                                                                                }
                                                                            }
                                                                            newItems[index].secondPlaceAthlete = e.target.value;
                                                                            return {declinations: newItems};
                                                                        }) : 
                                                                        this.setState({
                                                                            declinations:[...this.state.declinations, {
                                                                                name:declinaiton.name,
                                                                                secondPlaceAthlete: e.target.value
                                                                            }]
                                                                        })
                                                                    }
                                                                    defaultValue={this.state.declinations && this.state.declinations.length > 0 && this.state.declinations[index] &&
                                                                        this.state.declinations[index].secondPlaceAthlete ? this.state.declinations[index].secondPlaceAthlete.id : null}
                                                                >
                                                                    <option >Choix...</option>
                                                                    {
                                                                        declinaiton.athletes.map(forecast => {
                                                                            return(<option value={forecast.id}>{forecast.name} ({forecast.country})</option>)
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                                : <div style={{textAlign: 'center'}}>
                                                                    <Spinner animation="border" role="status">
                                                                        <span className="visually-hidden"></span>
                                                                    </Spinner>
                                                                </div> 
                                                            }
                                                        </Form.Group> 
                                                        <Form.Group >
                                                            <Form.Label>3ème place : </Form.Label>
                                                            {
                                                                declinaiton.athletes ? 
                                                                <Form.Control
                                                                    as="select"
                                                                    custom
                                                                    onChange={(e) => 
                                                                        this.state.declinations.length > 0 ?
                                                                        this.setState(prevState => {
                                                                            const newItems = [...prevState.declinations];
                                                                            if(!newItems[index]){
                                                                                newItems[index] = {
                                                                                    name:declinaiton.name
                                                                                }
                                                                            }
                                                                            newItems[index].thirdPlaceAthlete = e.target.value;
                                                                            return {declinations: newItems};
                                                                        }) : 
                                                                        this.setState({
                                                                            declinations:[...this.state.declinations, {
                                                                                name:declinaiton.name,
                                                                                thirdPlaceAthlete: e.target.value
                                                                            }]
                                                                        })
                                                                    }
                                                                    defaultValue={this.state.declinations && this.state.declinations.length > 0 &&  this.state.declinations[index] &&
                                                                        this.state.declinations[index].thirdPlaceAthlete ? this.state.declinations[index].thirdPlaceAthlete.id : null}
                                                                >
                                                                    <option >Choix...</option>
                                                                    {
                                                                        declinaiton.athletes.map(forecast => {
                                                                            return(<option value={forecast.id}>{forecast.name} ({forecast.country})</option>)
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                                : <div style={{textAlign: 'center'}}>
                                                                    <Spinner animation="border" role="status">
                                                                        <span className="visually-hidden"></span>
                                                                    </Spinner>
                                                                </div> 
                                                            }
                                                        </Form.Group> 
                                                    </Tab>
                                            ))}
                                            <Tab eventKey={this.state.selectedForecastContentID && this.state.forecastContents &&
                                                getSelectedItem(this.state.forecastContents, this.state.selectedForecastContentID).timeTable ? 
                                                getSelectedItem(this.state.forecastContents, this.state.selectedForecastContentID).timeTable.length : 0} title={<Button variant= "secondary" onClick={() => this.addNewDeclination(this.props.selectedForecastContent)}>+</Button>}>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItem(items, selectedItemID){
    var output
    if (items && items.length && items.length > 0) {
        items.map(item => {
            if (item.id == selectedItemID){
                output = item
            }
        })
    }

    return output
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}