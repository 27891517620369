import React, {Component} from 'react';
import {
    Badge,
    Table
} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Col, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import ReactPaginate from 'react-paginate';
import {Calendar} from "react-feather";
import {Pie} from "react-chartjs-2";
import unslug from "unslug";

registerLocale('fr', fr)

function getTransactions(pageNumber, startDate, endDate) {
    if (startDate != null && endDate != null){
        return fetch('https://back-api.therunningcollective.fr/detailed-transactions-reporting?beginDate='+startDate.toLocaleDateString()+'&endDate='+endDate.toLocaleDateString(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    }
}

function getUsers() {

    return fetch('https://back-api.therunningcollective.fr/csv-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getBadgeColor(state){
    if (state === 'pending' || state === 'pending_approval' || state === 'Attente' || state === 'waiting'){
        return 'warning'
    } else if (state === 'accepted' || state === 'invoiced' || state === 'approved' || state === 'Valide' || state === 'authorized'){
        return 'success'
    } else if (state === 'rejected' || state === 'rejection' || state === 'Refuse'|| state === 'refused'){
        return 'danger'
    }
}

function getBadgeText(state){
    if (state === 'pending' || state === 'pending_approval' || state === 'Attente' || state === 'waiting'){
        return 'En attente de validation'
    } else if (state === 'accepted' || state === 'invoiced' || state === 'approved' || state === 'Valide' || state === 'authorized'){
        return 'Accepté'
    } else if (state === 'rejected' || state === 'rejection' || state === 'Refuse' || state === 'refused'){
        return 'Rejeté'
    }
}

export const FancyButton = React.forwardRef(({ value, onClick }, ref) => (
    <button style={{border: 'none', backgroundColor: 'white', padding: '5px', boxShadow: '0 0.05rem 0.2rem rgb(0 0 0 / 5%)'}} onClick={onClick} ref={ref}>
        <Calendar className="feather align-middle mt-n1" /> {value}
    </button>
));

function getTransactionsLabels(reports){
    let data = getValueByAffiliate(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getTransactionsValue(reports){
    var values = []
    let data = getValueByAffiliate(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getValueByAffiliate(reports){
    let valuesByAffiliate = new Map()
    reports.map(element => {
        let existingValue = valuesByAffiliate.get(element.affiliateName)
        let total = 1
        if (existingValue) {
            total = existingValue + 1
        }
        valuesByAffiliate.set(element.affiliateName, total)
    })
    return valuesByAffiliate
}

function getVouchersCodeLabels(reports){
    let data = getValueByVoucherCode(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getVouchersCodeValue(reports){
    var values = []
    let data = getValueByVoucherCode(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getValueByVoucherCode(reports){
    let valuesByVoucherCode = new Map()
    reports.map(element => {
        if (element.voucher !== ""){
            let existingValue = valuesByVoucherCode.get(element.voucher+" ("+element.affiliateName+") ")
            let total = 1
            if (existingValue) {
                total = existingValue + 1
            }
            valuesByVoucherCode.set(element.voucher+" ("+element.affiliateName+") ", total)
        }
    })
    return valuesByVoucherCode
}

function getSourceLabels(reports){
    let data = getValueBySource(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getSourceValue(reports){
    var values = []
    let data = getValueBySource(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getProductsLabels(reports){
    let data = getValueByProducts(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getProductsValue(reports){
    var values = []
    let data = getValueByProducts(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getValueByProducts(reports){
    let valuesByAffiliate = new Map()
    reports.map(element => {
        if(element.productsName){
            getUnique(element.productsName).map(element => {
                let existingValue = valuesByAffiliate.get(element)
                let total = 1
                if (existingValue) {
                    total = existingValue + 1
                }
                valuesByAffiliate.set(element, total)
            })
        }

    })
    return valuesByAffiliate
}

function getValueBySource(reports){
    let valuesByAffiliate = new Map()
    reports.map(element => {
        if (element.source === "") {
            element.source = "unknown"
        }
        if(element.source){
            let existingValue = valuesByAffiliate.get(element.source)
            let total = 1
            if (existingValue) {
                total = existingValue + 1
            }
            valuesByAffiliate.set(element.source, total)
        }

    })
    return valuesByAffiliate
}

function getDeviceTypeLabels(reports){
    let data = getValueByDeviceType(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getDeviceTypeValue(reports){
    var values = []
    let data = getValueByDeviceType(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getValueByStatus(reports){
    let valuesByStatus = new Map()
    reports.map(element => {
        if (element.status !== ""){
            let status = getBadgeText(element.status)
            let existingValue = valuesByStatus.get(status)
            let total = 1
            if (existingValue) {
                total = existingValue + 1
            }
            valuesByStatus.set(status, total)
        }
    })
    return valuesByStatus
}

function getStatusLabels(reports){
    let data = getValueByStatus(reports)

    var labels = []
    data.forEach((value, key)=>{
        labels.push(key)
    })

    return labels
}

function getStatusValue(reports){
    var values = []
    let data = getValueByStatus(reports)

    data.forEach((value)=>{
        values.push(value)
    })
    return values
}

function getValueByDeviceType(reports){
    let valuesByDeviceType = new Map()
    reports.map(element => {
        if (element.deviceType !== ""){
            let device = getDeviceType(element.deviceType)
            let existingValue = valuesByDeviceType.get(device)
            let total = 1
            if (existingValue) {
                total = existingValue + 1
            }
            valuesByDeviceType.set(device, total)
        }
    })
    return valuesByDeviceType
}

function getPieStatusData(transactions){
    let labels = getStatusLabels(transactions)
    let colors = []
    labels.forEach((label) => {
        if(label === "En attente de validation"){
            let color = "orange"
            colors.push(color)
        } else if(label === "Accepté"){
            let color = "green"
            colors.push(color)
        } else if(label === "Rejeté"){
            let color = "red"
            colors.push(color)
        }

    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getStatusValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getPieSourceData(transactions){
    let labels = getSourceLabels(transactions)
    let colors = []
    labels.forEach(() => {
        let color = getRandomColor()
        colors.push(color)
    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getSourceValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getPieProductsData(transactions){
    let labels = getProductsLabels(transactions)
    let colors = []
    labels.forEach(() => {
        let color = getRandomColor()
        colors.push(color)
    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getProductsValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getPieVouchersCodeData(transactions){
    let labels = getVouchersCodeLabels(transactions)
    let colors = []
    labels.forEach(() => {
        let color = getRandomColor()
        colors.push(color)
    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getVouchersCodeValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getPieTransactionData(transactions){
    let labels = getTransactionsLabels(transactions)
    let colors = []
    labels.forEach(() => {
        let color = getRandomColor()
        colors.push(color)
    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getTransactionsValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getPieDeviceTypeData(transactions){
    let labels = getDeviceTypeLabels(transactions)
    let colors = []
    labels.forEach(() => {
        let color = getRandomColor()
        colors.push(color)
    })
    return {
        labels: labels,
        className: ["text-primary", "text-primary", "text-warning", "text-danger", "text-dark", "text-success",  "text-info", "text-tertiary"],
        datasets: [
            {
                data: getDeviceTypeValue(transactions),
                backgroundColor: colors,
                borderWidth: 5
            }
        ]
    }
}

function getClientNameByUserID(userID, users) {
    if (userID != "" && users && users.length > 0) {
        let user = users.filter(user => user.userID == userID)
        if (user.length > 0){
            return user[0].firstName + " " + user[0].name
        }
    }
}

export class DetailedReporting extends Component {

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.renderRefusalReason = this.renderRefusalReason.bind(this);
    }

    state = {
        users: null,
        currentItems: null,
        errors: null,
        pageNumber: 1,
        startDate: new Date(),
        endDate: new Date(),
        pageCount: 0,
        itemOffset: 0,
        itemsPerPage: 10,
        currentsItems: null,
        options: {
            maintainAspectRatio: false,
            cutoutPercentage: 70,
            legend: {
                display: false
            }
        }
    }

    async componentDidMount() {
        await getUsers().then(data => {
            if(data){
                const uniqueNames = data
                .map(e => e['email'])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => data[e]).map(e => data[e]);
            this.setState({users: uniqueNames})
            }
            
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );

        await getTransactions(this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
            this.setState({transactions: data})
            if (data){
                const endOffset = this.state.itemOffset +  this.state.itemsPerPage;
                this.setState({currentItems : data.slice(this.state.itemOffset, endOffset)});
                this.setState({pageCount : Math.ceil(data.length / this.state.itemsPerPage)});
                this.setState({pieChartTransactionsData: getPieTransactionData(data)})
                this.setState({pieChartProductsData: getPieProductsData(data)})
                this.setState({pieChartVouchersCodeData: getPieVouchersCodeData(data)})
                this.setState({pieChartDeviceTypeCodeData: getPieDeviceTypeData(data)})
                this.setState({pieChartStatusData: getPieStatusData(data)})
                this.setState({pieChartSourceData: getPieSourceData(data)})
            }
        }).catch(err =>{

                this.setState({errors: "error while getting transactions"})
        }
        );
    };

    async componentDidUpdate(prevProps, prevState) {
        if(prevState.itemOffset !== this.state.itemOffset){
            const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
            console.log(`1 Loading items from ${ this.state.itemOffset} to ${endOffset2}`);
            this.setState({currentItems : this.state.transactions.slice(this.state.itemOffset, endOffset2)});
            this.setState({pageCount : Math.ceil(this.state.transactions.length / this.state.itemsPerPage)});
        }
        if ((prevState.pageNumber !== this.state.pageNumber) || (prevState.startDate !== this.state.startDate) || (prevState.endDate !== this.state.endDate))  {
            if (this.state.startDate != null && this.state.endDate != null){
                await getTransactions(this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
                    this.setState({transactions: data})
                    if(data){
                        const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
                        this.setState({currentItems : data.slice(this.state.itemOffset, endOffset2)});
                        this.setState({pageCount : Math.ceil(data.length / this.state.itemsPerPage)});
                        this.setState({pieChartTransactionsData: getPieTransactionData(data)})
                        this.setState({pieChartProductsData: getPieProductsData(data)})
                        this.setState({pieChartVouchersCodeData: getPieVouchersCodeData(data)})
                        this.setState({pieChartDeviceTypeCodeData: getPieDeviceTypeData(data)})
                        this.setState({pieChartStatusData: getPieStatusData(data)})
                        this.setState({pieChartSourceData: getPieSourceData(data)})
                    }
                }).catch(err =>
                    this.setState({errors: "error while getting transactions"})
                );
            }
        }
    }


    handlePageClick(event){
        const newOffset = (event.selected * this.state.itemsPerPage) % this.state.transactions.length;
        this.setState({itemOffset: newOffset});
    }

    renderRefusalReason = (refusalReason) => (
        <Tooltip id="button-tooltip">
            <div key={refusalReason}>{refusalReason}</div>
        </Tooltip>
    );



    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1>Non autorisé</h1>
            </div>
        }

        return (
            <div >
                <Row>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartTransactionsData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Transactions par partenaire
                        </div>
                        {this.state.transactions ?
                            <div style={{textAlign: 'center'}}>Total : {this.state.transactions.length}</div> :
                            null }
                    </Col>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartVouchersCodeData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Codes promotionnels utilisés
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartProductsData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Produits
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartDeviceTypeCodeData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Appareils utilisés
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartStatusData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Statut des ventes
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="chart chart-xs">
                            <Pie data={this.state.pieChartSourceData} options={this.state.options} />
                        </div>
                        <div style={{textAlign: 'center'}}>
                            Sources
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={8}>
                        <DatePicker
                            selectsRange={true}
                            todayButton="Aujourd'hui"
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(update) => {
                                this.setState({startDate: update[0], endDate: update[1]});
                            }}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <FancyButton/>
                            }/>

                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des transactions"</p> : null}

                { (!this.state.currentItems && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive className="products-list">
                        <thead>
                        <tr>
                            <th>Revendeur</th>
                            <th>Produit(s)</th>
                            <th>Valeur</th>
                            <th>Commission</th>
                            <th>Code promo</th>
                            <th>User ID</th>
                            <th>Source</th>
                            <th>Appareil</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.currentItems ? this.state.currentItems.map(transaction => (
                            <tr>

                                <td>{transaction.affiliateName}</td>
                                <td>{transaction.productsName ?
                                    getUnique(transaction.productsName).map(product => {
                                        return (<div>{unslug(product)}</div>)
                                    })
                                : null }</td>
                                <td>{transaction.totalValue.toFixed(2)}€</td>
                                <td>{transaction.commission.toFixed(2)}€</td>
                                <td>{transaction.voucher}</td>
                                <td>{getClientNameByUserID(transaction.userId, this.state.users)}</td>
                                <td>{transaction.source}</td>
                                <td>{getDeviceType(transaction.deviceType)}</td>
                                <td>
                                        {
                                            transaction.status === 'rejected'
                                            ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={this.renderRefusalReason(transaction.refusalReason)}

                                                >
                                                    <Badge key={transaction.id} color={getBadgeColor(transaction.status)} className="mr-1 mb-1" style={{cursor: 'pointer'}}>
                                                    {getBadgeText(transaction.status)}
                                                    </Badge>
                                                </OverlayTrigger>

                                                :
                                                    <Badge key={transaction.id} color={getBadgeColor(transaction.status)} className="mr-1 mb-1">
                                                        {getBadgeText(transaction.status)}
                                                    </Badge>
                                        }

                                </td>
                                <td>{new Date(transaction.conversionDate).toLocaleDateString()} à {new Date(transaction.conversionDate).toLocaleTimeString()}</td>
                            </tr>
                        )) : null}
                        </tbody>
                    </Table>
                }
            <div style={{'display': 'flex', 'justify-content': 'center'}}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="suivant >"
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={this.state.pageCount}
                    previousLabel="< précédent"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </div>
            </div>

        );

    }
}

export function getUnique(array){
    var uniqueArray = [];

    // Loop through array values
    for(var value of array){
        if(uniqueArray.indexOf(value) === -1){
            uniqueArray.push(value);
        }
    }
    return uniqueArray;
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getDeviceType(deviceType){
    let device = ""
    if(deviceType === "desktop" || deviceType === "Windows" || deviceType === "Mac" || deviceType === "Apple Mac" || deviceType === "Linux"){
        device = "Ordinateur"
    } else if (deviceType === "smartphone" || deviceType === "iPhone" || deviceType === "Android Mobile"){
        device = "Mobile"
    } else {
        device = "Inconnu"
    }
    return device
}