import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Moment from "moment";
import DeletePodcastModal from "./modals/DeletePodcastModal";
import AddPodcastModal from "./modals/AddPodcastModal";

function getPodcasts() {

    return fetch('https://back-api.therunningcollective.fr/podcasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class PodcastsList extends Component{
    state = {
        podcasts: null,
        isOpen: false,
        errors: null,
        selectedPodcast: {
            name : "",
            description: "",
            image: "",
            appleLink: "",
            amazonLink: "",
            deezerLink: "",
            googleLink: "",
            spotify: ""
        }
    }

    async componentDidMount() {
        await getPodcasts().then(data => {
            this.setState({ podcasts: data.reverse() })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting podcasts"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedPodcast: {
            name : "",
            description: "",
            image: "",
            appleLink: "",
            amazonLink: "",
            deezerLink: "",
            googleLink: "",
            spotify: ""
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un podcast</Button>
                    </Col>
                </Row>
                { (!this.state.podcasts) ? <p> Aucun podcast disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Auteur</th>
                            <th>Catégories</th>
                            <th>Date de création</th>
                            <th>Lien Apple : </th>
                            <th>Lien Amazon : </th>
                            <th>Lien Deeezer : </th>
                            <th>Lien Google : </th>
                            <th>Lien Spotify : </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.podcasts.map(podcast => (
                            <tr key={podcast.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={this.renderTooltipImage(podcast)}
                                >
                                    <td>{podcast.name}</td>
                                </OverlayTrigger>
                                <td>{podcast.author}</td>
                                <td>{podcast.tags ? podcast.tags.map((tag, index) => {
                                    return(
                                        <span>{index == 0 ? "": ", "}{tag}</span>
                                    )
                                }) : null}</td>

                                <td>{Moment(podcast.createdDate).format('YYYY-MM-DDTHH:mm').toString()}</td>
                                <td>{podcast.appleLink && podcast.appleLink != "" ? "oui" : "non"}</td>
                                <td>{podcast.amazonLink && podcast.amazonLink != "" ? "oui" : "non"}</td>
                                <td>{podcast.deezerLink && podcast.deezerLink != "" ? "oui" : "non"}</td>
                                <td>{podcast.googleLink && podcast.googleLink != "" ? "oui" : "non"}</td>
                                <td>{podcast.spotifyLink && podcast.spotifyLink != "" ? "oui" : "non"}</td>

                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedPodcast : podcast})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedPodcast: podcast})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddPodcastModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedPodcast={this.state.selectedPodcast}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeletePodcastModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedPodcast}
                        selectedCategory="podcast"
                    />
                    :
                    null
                }
            </div>
        );

    }

    renderTooltipImage = (podcast) => (
        <Tooltip id="button-tooltip" >
            {podcast ?
                <div key={podcast.id}>
                    <div>
                        <img className="imgToDisplay" alt="Couverture actuelle" src={podcast.image}/>
                    </div>
                    <br/>
                </div>
                : "Indisponible" }
        </Tooltip>
    );

}
