import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteBackUserModal from "./modals/DeleteBackUserModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Moment from "react-moment";

function getUsers() {

    return fetch('https://back-api.therunningcollective.fr/back-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(user, checked) {
    user.enable = checked
    user.password = ""
    return fetch('https://back-api.therunningcollective.fr/back-user', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class UsersList extends Component {
    state = {
        isDelete: false,
        errors: null,
        selectedUser: {
            login: "",
            email: "",
            password: "",
            name: "",
            phoneNumber: "",
        }
    }

    async componentDidMount() {
        await getUsers().then(data => {
            if(data.message == "token is expired"){
                window.location.href="/logout";
              }
            this.setState({users: data})
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false, selectedUser: {
            login: "",
            email: "",
            password: "",
            name: "",
        }
    });


    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1> Non autorisé</h1>
            </div>
        }

        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter un utilisateur</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des utilisateurs, veuillez vous reconnecter"</p> : null}

                { (!this.state.users && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Login</th>
                            <th>Email</th>
                            <th>Téléphone</th>
                            <th>Role</th>
                            <th>Dernière connexion</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map(user => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le produit sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={user.enable}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(user, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{user.name}</td>
                                <td>{user.firstName}</td>
                                <td>{user.login}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.role}</td>
                                <td><Moment to={user.lastConnexion} locale="fr">{Date.now()}</Moment></td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18}
                                           onClick={(e) => this.setState({isOpen: true, selectedUser: user})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}
                                           onClick={(e) => this.setState({isDelete: true, selectedUser: user})}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddUserModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedUser={this.state.selectedUser}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteBackUserModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedUser}
                        selectedCategory="user"
                    />
                    :
                    null
                }
            </div>
        );

    }
}