import React, {Component} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {Alert, Nav, Tab} from "react-bootstrap";
import avatar from "../../assets/img/avatars/avatar.jpg";
import {sleep} from "../../components/modals/AddBrandModal";

function getProfile() {
  return fetch('https://back-api.therunningcollective.fr/back-user?login='+JSON.parse(localStorage.getItem('token')).login, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
    },
  })
      .then(data => data.json())
}

const Navigation = () => (
    <Nav variant="pills" className="flex-column">
      <Nav.Item>
        <Nav.Link eventKey="profile">Informations</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="password">Mot de passe</Nav.Link>
      </Nav.Item>
    </Nav>
);

export class PrivateInfo extends Component{

  handleClose = () => (
    fetch('https://back-api.therunningcollective.fr/back-user', {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(this.state.profile)
  }))
      .then( () => (
        this.setState({success : "informations modifiées avec succès"}),
        sleep(2000).then(() =>(
          this.props.closeModal,
          window.location.reload(false)
           ))))
      .catch( err =>
          this.setState({errors: "erreur lors de la modification des informations "})
      );

  state = {
    profile: null,
    success: false,
    errors: null,
  }

  async componentDidMount() {
    await getProfile().then(data => {
      if(data.message == "token is expired"){
        window.location.href="/logout";
      }
      this.setState({ profile: data })
    });
  };

  render(){
    if (this.state.profile == null){
      return <p> Chargement du profil ...</p>
    }

    return (
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0">
              Informations du compte
            </CardTitle>
          </CardHeader>
          <div style={{textAlign: 'center'}}>
            {this.state.profile.img ?
                <img
                    className="rounded-circle mr-3"
                    alt="Couverture actuelle"
                    src={this.state.profile.img}
                    width="200"
                    height="200"
                />
                :
                <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="200"
                    height="200"
                />
            }

          </div>
          <CardBody>
            {this.state.success != null ?
                <Alert role={"alert"} variant={"success"}>
                  {this.state.success}
                </Alert>
                : null}
            {this.state.errors != null ?
                <Alert role={"alert"} variant={"danger"}>
                  {this.state.errors}
                </Alert>
                : null}
            <Form>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="firstName">Prénom</Label>
                    <Input
                        type="text"
                        name="text"
                        id="firstName"
                        placeholder="Prénom..."
                        defaultValue={this.state.profile.firstName}
                        onChange={(e) => this.setState({profile : {...this.state.profile, firstName : e.target.value}})}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="lastName">Nom</Label>
                    <Input
                        type="text"
                        name="text"
                        id="lastName"
                        placeholder="Nom..."
                        defaultValue={this.state.profile.name}
                        onChange={(e) => this.setState({profile : {...this.state.profile, name : e.target.value}})}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="login">Login</Label>
                <Input type="text" name="login" id="login" placeholder="Login" disabled
                       onChange={(e) => console.log("raté")}
                       defaultValue={this.state.profile.login}/>
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="email" id="email" placeholder="Email"
                       onChange={(e) => this.setState({profile : {...this.state.profile, email : e.target.value}})}
                       defaultValue={this.state.profile.email}/>
              </FormGroup>
              <FormGroup>
                <Label for="role">Role</Label>
                <Input plaintext value={getRole(this.state.profile.role)} />
              </FormGroup>
              <Button variant="primary" onClick={this.handleClose}>Sauvegarder</Button>
            </Form>
          </CardBody>
        </Card>
    );
  }
}

export class Password extends Component{

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: null,
      password: null,
      secondPassword: null,
      isValid: true,
      errors: null,
      success: null,
      profile: {
        login: "",
        email: "",
        name:"",
        firstName:"",
        password:""
      }
    }
  };

  async componentDidMount() {
    await getProfile().then(data => {
      if(data.message == "token is expired"){
        window.location.href="/logout";
      }
      this.setState({ profile: data })
    });
  };

  checkPassword = () => {
    if ((this.state.oldPassword !== this.state.password) && (this.state.oldPassword !== this.state.secondPassword) && (this.state.password === this.state.secondPassword)){
      this.setState({isValid: true})
      let newProfile = this.state.profile
      newProfile.password = this.state.password
      newProfile.oldPassword = this.state.oldPassword
      this.setState({profile: newProfile})

      fetch('https://back-api.therunningcollective.fr/renew-password-back-user', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.profile)
      })
          .then( ()=>(
            this.setState({ success : "Mot de passe modifié avec succès"}),
            sleep(2000).then(() => {
                window.location.reload(false)
            })
            //window.location.reload(false)
          )
            )
          .catch( err =>
              console.log(err),
              this.setState({ errors : " Erreur lors de la modificiation du mot de passe"})
          );
    } else {
      this.setState({isValid: false})
    }
  }


  render(){

    return (
        <Card>
          {this.state.success != null ?
              <Alert role={"alert"} variant={"success"}>
                {this.state.success}
              </Alert>
              : null}
          {this.state.errors != null ?
              <Alert role={"alert"} variant={"danger"}>
                {this.state.errors}
              </Alert>
              : null}
          <CardHeader>
            <CardTitle tag="h5" className="mb-0">
              Modifier le mot de passe
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="login">Ancien mot de passe</Label>
                <Input type="password" name="password" id="old-password" placeholder="Tapez votre ancien mot de passe"
                       defaultValue={this.state.oldPassword}
                       onChange={(e) => this.setState({oldPassword : e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label for="login">Nouveau mot de passe</Label>
                <Input type="password" name="password" id="password" placeholder="Tapez votre nouveau mot de passe"
                       defaultValue={this.state.password}
                       onChange={(e) => this.setState({password : e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Retapez le nouveau mot de passe</Label>
                <Input type="password" name="secondPassword" id="second-password" placeholder="Retapez votre nouveau mot de passe"
                       defaultValue={this.state.secondPassword}
                       onChange={(e) => this.setState({secondPassword : e.target.value})}
                />
                {!this.state.isValid ?
                    <div className="text-danger">Mots de passe différents</div>
                    : null}
              </FormGroup>
              <Button color="primary" onClick={this.checkPassword}>Sauvegarder</Button>
            </Form>
          </CardBody>
        </Card>
    );
  }
}

function Settings() {

  return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
        <h1 className="h3 mb-3">Mon Profil</h1>
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <PrivateInfo/>
              </Tab.Pane>
              <Tab.Pane eventKey="password">
                <Password/>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
  );
}

function getRole(role){
  switch (role) {
    case "superadmin":
      return "Super Administrateur"
    case "admin":
      return "Administrateur"
    case "user":
      return "Utilisateur"
    default:
      return role
  }
}


export default Settings;
