import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteGameModal from "./modals/DeleteGameModal";
import AddGameModal from "./modals/AddGameModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import LaunchGame from "./modals/LaunchGame";
import {ReactSortable} from "react-sortablejs";

function getGames() {

    return fetch('https://back-api.therunningcollective.fr/games', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(game, checked) {
    game.active = checked
    return fetch('https://back-api.therunningcollective.fr/game', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(game)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class GamesList extends Component {
    state = {
        sortingSlides: null,
        errors: null,
        selectedGame: {
            login: "",
            email: "",
            password: "",
            name: "",
            phoneNumber: "",
        }
    }

    async componentDidMount() {
        await getGames().then(data => {
            this.setState({games: data})
        }).catch(err =>
            this.setState({errors: "error while getting games"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedGame: {
            name: "",
            description: "",
            beginDate: "",
            endDate: "",
            logo: "",
            cover: ""
        }
    });

    handleDropGames(list) {
        if (JSON.stringify(list) !== JSON.stringify(this.state.sortingSlides)) {
           this.setState({sortingSlides: list});

           const newBoxState = list.map((game, index) => {
            game.order = index

            fetch('https://back-api.therunningcollective.fr/game', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(game)
            }).then(this.props.closeModal)
                .catch( err =>
                    console.log(err)
                )

            return game;
        });

        this.setState({games : newBoxState});
         }
       }


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter un jeu concours</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.games && this.state.games.length > 0 && this.state.errors == null) ? <span>{this.state.games.length} jeux concours</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des jeux concours, veuillez vous reconnecter"</p> : null}

                { (!this.state.games && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <tbody>
                        <ReactSortable list={this.state.games} setList={(list) => this.handleDropGames(list)}>
                        {this.state.games.map(game => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Afficher le jeu concours sur le site</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={game.active}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(game, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{game.name}</td>
                                <td>{game.description}</td>
                                <td>{game.beginDate}</td>
                                <td>{game.endDate}</td>
                                <td>{game.onlyMobile ? "Oui" : "Non"}</td>
                                <td>{game.winnersCount}</td>
                                <td>{game.attendeesCount ? game.attendeesCount : 0}</td>
                                {
                                    game.winners && game.winners.length > 0 ?
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">
                                                <div>
                                                    {
                                                        game.winners.length > 0 ?
                                                            game.winners.map(winner => {
                                                                return(
                                                                    <div>- {winner.name} {winner.firstName} ({winner.email})</div>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </Tooltip>}
                                        >
                                            <td className="table-action" style={{cursor: "pointer"}}>
                                                <td>{game.winners.length > 0 ? "Oui" : "Non"}</td>
                                            </td>
                                        </OverlayTrigger>
                                        :
                                        <td>{game.winners && game.winners.length > 0 ? "Oui" : "Non"}</td>
                                }
                                {game.winners && game.winners.length > 0 ?
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Tirage au sort déjà effectué</Tooltip>}
                                    >
                                        <td className="table-action" style={{cursor: 'not-allowed', opacity: '0.33'}}>
                                            <Filter className="align-middle mr-1" size={18}
                                                    onClick={(e) => this.setState({isDrawing: true, selectedGame: game})}/>
                                        </td>
                                    </OverlayTrigger>
                                    :
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Lancer le tirage au sort</Tooltip>}
                                    >
                                        <td className="table-action" style={{cursor: "pointer"}}>
                                            <Filter className="align-middle mr-1" size={18}
                                                    onClick={(e) => this.setState({isDrawing: true, selectedGame: game})}/>                                        </td>
                                    </OverlayTrigger>
                                }

                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Modifier le jeu concours</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                               onClick={(e) => this.setState({isOpen: true, selectedGame: game})}/>
                                    </td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer le jeu concours</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedGame: game})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </ReactSortable>
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddGameModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedGame={this.state.selectedGame}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteGameModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedGame}
                        selectedCategory="game"
                    />
                    :
                    null
                }
                {this.state.isDrawing ?
                    <LaunchGame
                        closeModal={this.closeModal}
                        isOpen={this.state.isDrawing}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedGame}
                        selectedCategory="game"
                    />
                    :
                    null
                }
            </div>
        );

    }
}