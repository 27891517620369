import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddColorModalForm from "./modals/AddColorModal";
import DeleteModal from "./modals/DeleteModal";
import Logout from "./Logout";

function getDeclinationHistoryPrices() {

    return fetch('https://backapi.therunningcollective.fr/api/protected/products-history', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class DeclinationHistoryPricesList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            declinations: null,
            errors: null,
        }
    }
    async componentDidMount() {
        await getDeclinationHistoryPrices().then(data => {
            this.setState({ declinations: data })
        }).catch(err =>
            this.setState({errors: "error while getting declinations"})
        );
    };
    
    render(){

        return(
            <div className="products-list">
                

                { !this.state.declinations ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Reference</th>
                            <th>Prix</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.declinations.map(declination => (
                            <tr>
                                <td>{declination.name}</td>
                                <td>{declination.reference}</td>
                                <td>{declination.bestPrice}</td>
                                <td>{new Date(declination.historyDate).toLocaleDateString()} à {new Date(declination.historyDate).toLocaleTimeString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }
            </div>
        );

    }

}