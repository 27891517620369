import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import {SketchPicker} from "react-color";
import reactCSS from 'reactcss'


export default class AddColorModalForm extends Component {

    state = {
        displayColorPicker: false,
        color: {
            r: '',
            g: '',
            b: '',
            a: '',
        },
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleCloseColor = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.props.selectedColor.hexaColor = color.rgb
        this.setState({ color: color.rgb })
    };


    handleClose = () => fetch('https://back-products-api.therunningcollective.fr/color', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedColor)
    })
        .then( () => (
            this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

    render(){
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `rgba(${ this.props.selectedColor.hexaColor.r }, ${ this.props.selectedColor.hexaColor.g }, ${ this.props.selectedColor.hexaColor.b }, ${ this.props.selectedColor.hexaColor.a })`,
                },
                swatch: {
                    padding: '5px',
                    marginLeft: '15px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une couleur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedColor.name = e.target.value} defaultValue={this.props.selectedColor.name} placeholder="Ex : Bleu"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Couleur :
                                                <div style={ styles.swatch } onClick={ this.handleClick }>
                                                    <div style={ styles.color } />
                                                </div>
                                                { this.state.displayColorPicker ? <div style={ styles.popover }>
                                                    <div style={ styles.cover } onClick={ this.handleCloseColor }/>
                                                    <SketchPicker color={ this.props.selectedColor.hexaColor } onChange={ this.handleChange } />
                                                </div> : null }
                                            </Form.Label>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}