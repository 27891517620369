import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Moment from "moment";
import DeleteLandingPageModal from "./modals/DeleteLandingPageModal";
import AddLandingPageModal from "./modals/AddLandingPageModal";

function getLandingPages() {

    return fetch('https://back-api.therunningcollective.fr/landing-pages', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getProductsCategories() {

    return fetch('https://back-products-api.therunningcollective.fr/categories?headCategory=/shoes', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getBlogCategories() {

    return fetch('https://public-front-api.therunningcollective.fr/blog-categories', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class LandingPagesList extends Component{
    state = {
        landingPages: null,
        blogCategories: null,
        productCategories: null,
        isOpen: false,
        errors: null,
        selectedLandingPage: {
            title : "",
            description: "",
            slug: "",
            keywords: ""
        }
    }

    async componentDidMount() {
        await getLandingPages().then(data => {
            this.setState({ landingPages: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting landingPages"})
        );
        await getBlogCategories().then(data => {
            this.setState({ blogCategories: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting blogCategories"})
        );
        await getProductsCategories().then(data => {
            const uniqueIds = [];

            const productCategories = data.filter(element => {
                const isDuplicate = uniqueIds.includes(element);

                if (!isDuplicate) {
                    uniqueIds.push(element);

                    return true;
                }

                return false;
            });
            this.setState({ productCategories: productCategories })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting productCategories"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedLandingPage: {
            title : "",
            description: "",
            slug: "",
            keywords: "",
        } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une landing page</Button>
                    </Col>
                </Row>
                { (!this.state.landingPages) ? <p> Aucune landing page disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Description</th>
                            <th>Catégorie de produits</th>
                            <th>Catégorie word press</th>
                            <th>Keywords</th>
                            <th>Slug</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.landingPages.map(landingPage => (
                            <tr key={landingPage.id}>
                                <td>{landingPage.title}</td>
                                <td>{landingPage.description}</td>
                                <td>{landingPage.productsCategory}</td>
                                <td>{landingPage.blogCategory}</td>
                                <td>{landingPage.keywords}</td>
                                <td>{landingPage.slug}</td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedLandingPage : landingPage})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedLandingPage: landingPage})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddLandingPageModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedLandingPage={this.state.selectedLandingPage}
                        blogCategories={this.state.blogCategories}
                        productCategories={this.state.productCategories}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteLandingPageModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedLandingPage}
                        selectedCategory="landing-page"
                    />
                    :
                    null
                }
            </div>
        );

    }

}
