import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Accordion, Tabs} from "react-bootstrap";
import {sleep} from "./AddBrandModal";
import {Trash} from "react-feather";
import Moment from "moment";
import {SketchPicker} from "react-color";

export default class UpdateWheelSpinModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    rewardSelection = (e) => {
        if (this.props.availableRewards){
            this.props.availableRewards.map(reward => {
                if (reward.id == e.target.value){
                    this.props.selectedWheelSpin.associatedReward = reward
                }
            })
        }
    }

        handleClose = () => (
        fetch('https://back-api.therunningcollective.fr/wheel-spin', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedWheelSpin)
        })
            .then(async response =>
                await sleep(2000).then(
                    () => (
                        this.props.closeModal,
                        window.location.reload(false)
                        )
                ),
            )
            .catch( err =>
                console.log(err)
            )

        );

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifier un croissant de la roue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedWheelSpin.name = e.target.value} defaultValue={this.props.selectedWheelSpin.name} placeholder="Ex : Buff TRC"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Couleur : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedWheelSpin.color = e.target.value}
                                                defaultValue={this.props.selectedWheelSpin.color}
                                            >
                                                <option value="">...</option>
                                                <option value="black">Noir</option>
                                                <option value="white">Blanc</option>
                                                <option value="red">Rouge</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Couleur du fond : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedWheelSpin.backgroundColor = e.target.value}
                                                defaultValue={this.props.selectedWheelSpin.backgroundColor}
                                            >
                                                <option value="">...</option>
                                                <option value="black">Noir</option>
                                                <option value="white">Blanc</option>
                                                <option value="red">Rouge</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Récompense associée : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.rewardSelection(e)}
                                                defaultValue={this.props.selectedWheelSpin.associatedReward ? this.props.selectedWheelSpin.associatedReward.id : null}
                                            >
                                                <option value="">...</option>
                                                {
                                                    this.props.availableRewards && this.props.availableRewards.length > 0 ?
                                                        this.props.availableRewards.map(reward => {
                                                            return(
                                                                <option value={reward.id}>{reward.description}</option>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}
