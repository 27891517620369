import React, {Component} from 'react';
import {
    Badge,
    Table
} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Col, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import ReactPaginate from 'react-paginate';
import {FancyButton} from "./DetailedReportingList";
import { Select, MenuItem } from '@material-ui/core';

registerLocale('fr', fr)

function getTransactions(selectedPartner, pageNumber, startDate, endDate) {
    if (selectedPartner !== "Tous" && startDate != null && endDate != null){
        return fetch('https://back-api.therunningcollective.fr/products-detailed-reporting?merchandName='+selectedPartner+'&beginDate='+startDate.toLocaleDateString()+'&endDate='+endDate.toLocaleDateString(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    } else if (startDate != null && endDate != null){
        return fetch('https://back-api.therunningcollective.fr/products-detailed-reporting?beginDate='+startDate.toLocaleDateString()+'&endDate='+endDate.toLocaleDateString(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    }
}

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class DetailedProductsReporting extends Component {

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.renderRefusalReason = this.renderRefusalReason.bind(this);
    }

    state = {
        users: null,
        merchands: null,
        selectedPartner: "Tous",
        currentItems: null,
        errors: null,
        pageNumber: 1,
        startDate: new Date(),
        endDate: new Date(),
        pageCount: 0,
        itemOffset: 0,
        itemsPerPage: 15,
        currentsItems: null,
        options: {
            maintainAspectRatio: false,
            cutoutPercentage: 70,
            legend: {
                display: false
            }
        }
    }

    async componentDidMount() {
        await getMerchands().then(data => {
            this.setState({ merchands: data })
        }).catch(err =>
            this.setState({errors: "error while getting merchands"})
        );

        await getTransactions(this.state.selectedPartner, this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
            this.setState({transactions: data})
            if (data){
                const endOffset = this.state.itemOffset +  this.state.itemsPerPage;
                this.setState({currentItems : data.slice(this.state.itemOffset, endOffset)});
                this.setState({pageCount : Math.ceil(data.length / this.state.itemsPerPage)});
            }
        }).catch(err =>{

                this.setState({errors: "error while getting transactions"})
        }
        );
    };

    async componentDidUpdate(prevProps, prevState) {
        if(prevState.itemOffset !== this.state.itemOffset){
            const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
            console.log(`1 Loading items from ${ this.state.itemOffset} to ${endOffset2}`);
            this.setState({currentItems : this.state.transactions.slice(this.state.itemOffset, endOffset2)});
            this.setState({pageCount : Math.ceil(this.state.transactions.length / this.state.itemsPerPage)});
        }
        if ((prevState.selectedPartner !== this.state.selectedPartner) || (prevState.pageNumber !== this.state.pageNumber) || (prevState.startDate !== this.state.startDate) || (prevState.endDate !== this.state.endDate))  {
            if (this.state.startDate != null && this.state.endDate != null){
                await getTransactions(this.state.selectedPartner, this.state.pageNumber, this.state.startDate, this.state.endDate).then(data => {
                    this.setState({transactions: data})
                    if(data){
                        const endOffset2 = this.state.itemOffset +  this.state.itemsPerPage;
                        this.setState({currentItems : data.slice(this.state.itemOffset, endOffset2)});
                        this.setState({pageCount : Math.ceil(data.length / this.state.itemsPerPage)});
                    }
                }).catch(err =>
                    this.setState({errors: "error while getting transactions"})
                );
            }
        }
    }


    handlePageClick(event){
        const newOffset = (event.selected * this.state.itemsPerPage) % this.state.transactions.length;
        this.setState({itemOffset: newOffset});
    }

    renderRefusalReason = (refusalReason) => (
        <Tooltip id="button-tooltip">
            <div key={refusalReason}>{refusalReason}</div>
        </Tooltip>
    );

    selectionChangeHandler = (event) => {
        this.setState({selectedPartner : event.target.value});
    };

    render() {

        if (JSON.parse(localStorage.getItem('token')).role !== "superadmin") {
            return <div>
                <h1>Non autorisé</h1>
            </div>
        }

        return (
            <div >
                <br/>
                <Row>
                    <Col md={4}>
                        <DatePicker
                            selectsRange={true}
                            todayButton="Aujourd'hui"
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={(update) => {
                                this.setState({startDate: update[0], endDate: update[1]});
                            }}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <FancyButton/>
                            }/>

                    </Col>
                    <Col md={4}>
                        <Select value={this.state.selectedPartner} onChange={(event) => {
                            this.setState({selectedPartner: event.target.value});
                        }}>
                            <MenuItem value={"Tous"}>Tous</MenuItem>
                            <MenuItem value={"Nike_Monotote"}>Nike_Monotote</MenuItem>
                            {
                                this.state.merchands && this.state.merchands.length > 0 ?
                                    this.state.merchands.map(merchand => {
                                        return(
                                            <MenuItem value={merchand.name}>{merchand.name}</MenuItem>
                                        )
                                    })
                                    : null
                            }
                        </Select>
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des transactions, veuillez vous reconnecter"</p> : null}

                { (!this.state.currentItems && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive className="products-list">
                        <thead>
                        <tr>
                            <th>Nombre du produit</th>
                            <th>Nombres vendus</th>
                            {
                                this.state.selectedPartner === "Tous" ?
                                    <th>Par distributeurs</th>
                                    : null
                            }

                        </tr>
                        </thead>
                        <tbody>
                        {this.state.currentItems.map(transaction => (
                            <tr>

                                <td>{transaction.key}</td>
                                <td>{transaction.value}</td>
                                {
                                    this.state.selectedPartner === "Tous" ?
                                        <td>{transaction.partners ?
                                            transaction.partners.map(p => {
                                                return <div>{p.key} : {p.value}</div>
                                            }) : null}</td>
                                        : null
                                }

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
            <div style={{'display': 'flex', 'justify-content': 'center'}}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="suivant >"
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={this.state.pageCount}
                    previousLabel="< précédent"
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </div>
            </div>

        );

    }
}