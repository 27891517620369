import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import {sliceIntoChunks} from "./AddCoinsModal";
import sleep from "./AddBrandModal"

export default class AddNotificationModal extends Component {
    constructor(props) {
        super(props);
    }

    typeSelection = (e) => {
        this.props.selectedNotification.types = [...e.target.selectedOptions].map(o => this.props.availableTypes.find(type =>  type === o.value));
        if(this.props.availableUsers && this.props.selectedNotification.types) {
            let selectedUsers = []
            this.props.availableUsers.map(user => {
                this.props.selectedNotification.types.map(type => {
                    if (user.type == type) {
                        selectedUsers.push(user)
                    }
                })
            })
            this.props.selectedNotification.users = selectedUsers
        }
    }

    handleClose = () =>{
        if (this.props.selectedNotification && this.props.selectedNotification.users && this.props.selectedNotification.users.length > 500){
            let arrayOfUsers = sliceIntoChunks(this.props.selectedNotification.users, 200)
            if (arrayOfUsers.length > 0) {
                for (let i=0; i < arrayOfUsers.length; i++){
                    let users = arrayOfUsers[i]
                    let notification = this.props.selectedNotification
                    notification.users = users
                    notification.id = ""

                    if (i == (arrayOfUsers.length-1)){
                        fetch('https://back-api.therunningcollective.fr/push-notification', {
                            method: 'PUT',
                            headers: {
                                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(notification)
                        })
                            .then(() => (this.props.closeModal))
                            .catch( err =>
                                console.log(err)
                            );
                    } else {
                        fetch('https://back-api.therunningcollective.fr/push-notification', {
                            method: 'PUT',
                            headers: {
                                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(notification)
                        })
                            .then(() => (
                                sleep(3000).then(() => (this.props.closeModal, window.location.reload(true)))))
                            .catch( err =>
                                console.log(err)
                            );
                    }

                }
            }

        } else {
            fetch('https://back-api.therunningcollective.fr/push-notification', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.props.selectedNotification)
            })
                .then( () => (this.props.closeModal, window.location.reload(false)))
                .catch( err =>
                    console.log(err)
                );
        }

    }
    
    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Programmer l'envoi d'une notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Titre : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedNotification.title = e.target.value} defaultValue={this.props.selectedNotification.title} placeholder="Nouveauté"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Corps de texte : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedNotification.body = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedNotification.body ? this.props.selectedNotification.body : this.props.selectedNotification.body} placeholder="Les Asics Gel Kayano 29 sont enfin disponibles"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien de redirection : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedNotification.link = e.target.value} defaultValue={this.props.selectedNotification.link} placeholder="https://links.therunningcollective.fr/blabla"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date d'envoi : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedNotification.sendDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedNotification.sendDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Profils concernés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.typeSelection(e) }}
                                                defaultValue={getSelectedItems(this.props.selectedNotification.types)}
                                            >
                                                {this.props.availableTypes.map((product, index) =>
                                                    <option key={product} value={product}>{product}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Utilisateurs concernés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.props.selectedNotification.users = [...e.target.selectedOptions].map(o => this.props.availableUsers.find(user =>  user.id === o.value))}}
                                                defaultValue={getSelectedItems(this.props.selectedNotification.users)}
                                            >
                                                {this.props.availableUsers.map((user, index) =>
                                                    <option key={user.id} value={user.id}>{user.firstName + " " + user.name}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => this.handleClose()}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.id)
            )
        )
    }

    return arrayToReturn
}