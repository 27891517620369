import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {HiOutlineViewList} from "react-icons/hi";
import {Edit2, Trash} from "react-feather";
import {Col, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddColorModalForm from "./modals/AddColorModal";
import DeleteColorModal from "./modals/DeleteColorModal";
import Logout from "./Logout";

function getColors() {

    return fetch('https://back-products-api.therunningcollective.fr/colors', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class ColorsList extends Component{
    state = {
        categories: null,
        isOpen: false,
        errors: null,
        isDelete: false,
        selectedColor: {
            name : "",
            hexaColor: {
                r: '',
                g: '',
                b: '',
                a: '',
            }
        }
    }

    async componentDidMount() {
        await getColors().then(data => {
            this.setState({ colors: data })
        }).catch(err =>
            this.setState({errors: "error while getting colors"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedColor: this.state.selectedColor });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une couleur</Button>
                    </Col>
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup  value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode : e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                

                { !this.state.colors ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Couleur</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.colors.map(color => (
                            <tr>
                                <td>{color.name}</td>
                                <td> <div style={{
                                    width: '36px',
                                    height: '14px',
                                    borderRadius: '2px',
                                    backgroundColor: `rgba(${ color.hexaColor.r }, ${ color.hexaColor.g }, ${ color.hexaColor.b }, ${ color.hexaColor.a })`,
                                }}></div></td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedColor : color})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18} onClick={(e) => this.setState({isDelete : true, selectedColor : color})}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isOpen ?
                    <AddColorModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedColor={this.state.selectedColor}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteColorModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedColor}
                        selectedCategory="color"
                    />
                    :
                    null
                }
            </div>
        );

    }

}