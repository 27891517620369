import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export default class AddManualReporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploaded: false,
            manualReporting: {
                affiliateName: "",  
                externalId: uuidv4(),
                commission: 0,
                totalValue: 0,
                status : "accepted",
                conversionDate: new Date()
            },
            availableMerchands: [],
        }
    }

    async componentDidMount() {
        await getMerchands().then(data => {
            this.setState({ availableMerchands: data })
        })
    };


    handleClose = () => fetch('https://back-api.therunningcollective.fr/transaction-reporting', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.manualReporting)
    }).then((res) => (
        this.props.closeModal,
        window.location.reload(false)
        ))
        .catch( err =>
            console.log(err)
        )

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un reporting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <Form.Group >
                                        <Form.Label>Marchand : </Form.Label>
                                        <Form.Control
                                            as="select"
                                            defaultValue={this.state.manualReporting.affiliateName}
                                            onChange={(e) => this.setState(prevState => ({
                                                manualReporting: {                   // object that we want to update
                                                    ...prevState.manualReporting,    // keep all other key-value pairs
                                                    affiliateName: e.target.value      // update the value of specific key
                                                }
                                            }))}                                             
                                        >
                                            <option >Ajouter...</option>
                                            {this.state.availableMerchands.map(merchand =>
                                                <option key={merchand.name} value={merchand.name}>{merchand.name}</option>
                                            )}
                                        </Form.Control>
                                        <Form.Group >
                                            <Form.Label>Valeur de la vente : </Form.Label>
                                            <Form.Control type="text" 
                                            onChange={(e) => this.setState(prevState => ({
                                                manualReporting: {                   // object that we want to update
                                                    ...prevState.manualReporting,    // keep all other key-value pairs
                                                    totalValue: parseInt(e.target.value,10)     // update the value of specific key
                                                }
                                            }))}    
                                            defaultValue={this.state.manualReporting.totalValue} placeholder="100"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Commission : </Form.Label>
                                            <Form.Control type="text" 
                                            onChange={(e) => this.setState(prevState => ({
                                                manualReporting: {                   // object that we want to update
                                                    ...prevState.manualReporting,    // keep all other key-value pairs
                                                    commission: parseInt(e.target.value,10)     // update the value of specific key
                                                }
                                            }))}    
                                            defaultValue={this.state.manualReporting.commission} placeholder="10"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de la vente : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                defaultValue={Moment(this.state.manualReporting.conversionDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                onChange={(e) => this.setState(prevState => ({
                                                    manualReporting: {                   // object that we want to update
                                                        ...prevState.manualReporting,    // keep all other key-value pairs
                                                        conversionDate:  new Date(e.target.value).toISOString()     // update the value of specific key
                                                    }
                                                }))}   
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}