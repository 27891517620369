import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Col, Row} from "react-bootstrap";
import Moment from 'moment';
import { gzip } from 'pako';

function buildSelectedVoucher(selectedVoucher) {
    var returnedProducts = []
    if (selectedVoucher.products && selectedVoucher.products.length > 0) {
        selectedVoucher.products.map(product => {
            returnedProducts.push(
                {
                    name: product.name,
                    headCategory: product.headCategory,
                }
            )
        })
    }
    selectedVoucher.products = returnedProducts
    return selectedVoucher
}

export default class AddVoucherModalForm extends Component {
    handleClose = () => (
        console.log(this.props.selectedVoucher),
        fetch('https://back-products-api.therunningcollective.fr/voucher', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(buildSelectedVoucher(this.props.selectedVoucher))
    }).then(() => (
        this.props.closeModal,
        window.location.reload(false))
        )
        .catch( err =>
            console.log(err)
        ))

    brandSelection = (e) => {
        this.props.selectedVoucher.brands = [...e.target.selectedOptions].map(o => this.props.availableBrands.find(brand =>  brand.name === o.value));
        if(this.props.availableProducts && this.props.selectedVoucher.brands) {
            let selectedProducts = []
            this.props.availableProducts.map(product => {
                this.props.selectedVoucher.brands.map(brand => {
                    if (product.brand == brand.name) {

                        selectedProducts.push(product)
                    }
                })
            })
            this.props.selectedVoucher.products = selectedProducts
        }
    }

    categorySelection = (e) => {
        this.props.selectedVoucher.categories = [...e.target.selectedOptions].map(o => this.props.availableHeadCategories.find(category =>  category.id === o.value));
        if(this.props.availableProducts && this.props.selectedVoucher.categories) {
            let selectedProducts = []
            this.props.availableProducts.map(product => {
                this.props.selectedVoucher.categories.map(brand => {
                    if (product.headCategory == brand.id) {
                        selectedProducts.push(product)
                    }
                })
            })
            this.props.selectedVoucher.products = selectedProducts
        }
    }

    typesSelection = (e) => {
        this.props.selectedVoucher.types = [...e.target.selectedOptions].map(o => this.props.availableTypes.find(category =>  category === o.value));
        if(this.props.availableProducts && this.props.selectedVoucher.types) {
            let selectedProducts = []
            this.props.availableProducts.map(product => {
                this.props.selectedVoucher.types.map(brand => {
                    if (product.shopType){
                        product.shopType.map(type => {
                            if (type == brand) {
                                selectedProducts.push(product)
                            }
                        })
                    }
                })
            })
            this.props.selectedVoucher.products = selectedProducts
        }
    }

    render(){
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un coupon de réduction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group >
                        <Form.Label>Code : </Form.Label>
                        <Form.Control type="text" onChange={(e) => this.props.selectedVoucher.name = e.target.value} defaultValue={this.props.selectedVoucher.name} placeholder="Ex : TRC5"/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Marchand : </Form.Label>
                        <Form.Control
                            as="select"
                            defaultValue={this.props.selectedVoucher.merchantName}
                            onChange={(e) => this.props.selectedVoucher.merchantName = e.target.value}
                        >
                            <option >Ajouter...</option>
                            {this.props.availableMerchands.map(merchand =>
                                <option key={merchand.name} value={merchand.name}>{merchand.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check onChange={(e) => this.props.selectedVoucher.notOnSale = e.target.checked}
                                    defaultChecked={this.props.selectedVoucher.notOnSale}
                                    type="checkbox" label="Valable uniquement sur les nouveautés" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckboxOnlyOnSale">
                        <Form.Check onChange={(e) => this.props.selectedVoucher.onlyOnSale = e.target.checked}
                                    defaultChecked={this.props.selectedVoucher.onlyOnSale}
                                    type="checkbox" label="Valable uniquement sur les promotions" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckboxForceToCompare">
                        <Form.Check onChange={(e) => this.props.selectedVoucher.forceToCompare = e.target.checked}
                                    defaultChecked={this.props.selectedVoucher.forceToCompare}
                                    type="checkbox" label="Forcer le coupon" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckboxIsTemporary">
                        <Form.Check onChange={(e) => this.props.selectedVoucher.isTemporary = e.target.checked}
                                    defaultChecked={this.props.selectedVoucher.isTemporary}
                                    type="checkbox" label="Coupon temporaire" />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Date de début : </Form.Label>
                                <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                              defaultValue={Moment(this.props.selectedVoucher.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                              onChange={(e) => this.props.selectedVoucher.beginDate = new Date(e.target.value).toISOString()}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Date de fin : </Form.Label>
                                <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                              defaultValue={Moment(this.props.selectedVoucher.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                              onChange={(e) => this.props.selectedVoucher.endDate = new Date(e.target.value).toISOString()}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group >
                        <Row>
                            <Col>
                                <Form.Label>Réduction applicable de : </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type="text" defaultValue={this.props.selectedVoucher.reductionAmount}
                                              onChange={(e) => this.props.selectedVoucher.reductionAmount = parseFloat(e.target.value)}
                                              placeholder="Ex : 10"/>
                            </Col>
                            <Col>
                                <Form.Control
                                    as="select"
                                    defaultValue={this.props.selectedVoucher.voucherType}
                                    onChange={(e) => this.props.selectedVoucher.voucherType = e.target.value}
                                >
                                    <option >Ajouter...</option>
                                    <option value="%">%</option>
                                    <option value="€">€</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group >
                    <Form.Group >
                        <Row>
                        <Col>
                            <Form.Label>Palier minimum (€) : </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control type="text" defaultValue={this.props.selectedVoucher.minimumPrice}
                                          onChange={(e) => this.props.selectedVoucher.minimumPrice = parseFloat(e.target.value)}
                                          placeholder="Ex : 10"/>
                        </Col>
                        <Col>
                            <Form.Label>Palier maximum (€) : </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control type="text" defaultValue={this.props.selectedVoucher.maximumPrice}
                                          onChange={(e) => this.props.selectedVoucher.maximumPrice = parseFloat(e.target.value)}
                                          placeholder="Ex : 10"/>
                        </Col>
                    </Row>
                    </Form.Group >
                    <Form.Group >
                        <Form.Label>Marques éligibles : </Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            onChange={(e) => {this.brandSelection(e) }}
                            defaultValue={getSelectedItems(this.props.selectedVoucher.brands)}
                        >
                            {this.props.availableBrands.map((product, index) =>
                                <option key={product.id} value={product.id}>{product.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Catégories éligibles : </Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            onChange={(e) => {this.categorySelection(e) }}
                            defaultValue={getSelectedItems(this.props.selectedVoucher.brands)}
                        >
                            {this.props.availableHeadCategories.map((product, index) =>
                                <option key={product.id} value={product.id}>{product.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type de produit : </Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            onChange={(e) => {this.typesSelection(e) }}
                            defaultValue={getSelectedItems(this.props.selectedVoucher.brands)}
                        >
                            {this.props.availableTypes && this.props.availableTypes.map((type, index) =>
                                <option key={index} value={type}>{type}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Produits éligibles : </Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            onChange={(e) => {this.props.selectedVoucher.products = [...e.target.selectedOptions].map(o => this.props.availableProducts.find(product =>  product.name === o.value))}}
                            defaultValue={getSelectedItems(this.props.selectedVoucher.products)}
                        >
                            {this.props.availableProducts.map((product, index) =>
                                <option key={index} value={product.name}>{product.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.name)
            )
        )
    }

    return arrayToReturn
}

function cumulativeVouchersFilter(selectedVoucher, availableVouchers){
    var arrayToReturn = []

    if (availableVouchers) {
        for (let i=0; i<availableVouchers.length;i++){
            if (availableVouchers[i].id !== selectedVoucher.id){
                arrayToReturn.push(availableVouchers[i])
            }
        }
    }
    return arrayToReturn
}