import React, {Component, forwardRef} from 'react';
import {Col, Row, Table} from "reactstrap";
import Moment from "react-moment";
import 'moment/locale/fr';
import './Products.css'
import UpdateModalForm from "./modals/UpdateProductModal";
import {Activity, ChevronDown, ChevronUp, Copy, Edit2, Power, Shuffle, Star, Trash} from "react-feather";
import DeleteProductModal from "./modals/DeleteProductModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {Badge, FormControl, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Logout from "./Logout";
import {ReactSortable} from "react-sortablejs";
import 'moment/locale/fr';
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from 'lodash/debounce'

function getPosts() {

    return fetch('https://back-api.therunningcollective.fr/blog-posts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getSearchProducts(headCategory, searchProduct) {
    let body = null

    return fetch('https://back-products-api.therunningcollective.fr/products?headCategory='+headCategory+'&keywords='+searchProduct.replaceAll(" ", "-"), {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
        body: body,
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getProducts(headCategory, lastEvaluatedKey) {
    let body = null
    if (lastEvaluatedKey) {
        body = JSON.stringify(lastEvaluatedKey)
    }

    return fetch('https://back-products-api.therunningcollective.fr/products?headCategory='+headCategory, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
        body: body,
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getBrands() {
    return fetch('https://back-products-api.therunningcollective.fr/brands', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getMerchands() {

    return fetch('https://back-products-api.therunningcollective.fr/merchants', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getColors() {

    return fetch('https://back-products-api.therunningcollective.fr/colors', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getCategories(headCategory) {

    let parentCategory = headCategory.split("/products").pop()
    return fetch('https://back-products-api.therunningcollective.fr/categories?headCategory='+parentCategory, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(product, checked) {
    product.enable = checked
    return fetch('https://back-products-api.therunningcollective.fr/product', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

function forceCompare(product) {
    return fetch('https://back-products-api.therunningcollective.fr/compare-product', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
    }).then(() => document.location.reload())
        .catch( err =>
            document.location.reload()
        )
}

export class ProductsList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            errors: null,
            loading: true,
            isOpen: false,
            isDelete: false,
            lastEvaluatedKey: null,
            hasMore: true,
            selectedProduct: {
                name : "",
                description: "",
                gender: "",
                price: 0.0,
                brand: "",
                order: 0,
                categories: [],
                shopType: [],
                declinations: [],
                metaTitle:"",
                metaDescription:"",
                showProduct: true,
            },
            availableBrands: null,
            availablePosts: null,
            availableMerchands: null,
            availableColors: null,
            availableTypes: ["athletisme", "running", "trail"],
            availableCategories: null,
            initialProducts: null,
            headCategory: this.props.location.pathname,
            dragId: null,
        }
        this.filterList = this.filterList.bind(this);
        this.sortList = this.sortList.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.fetchMoreProducts = this.fetchMoreProducts.bind(this)
    }

    async componentDidMount() {
        await getProducts(this.state.headCategory).then(data => {
            this.setState({ initialProducts: data.products, products: data.products, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        }).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
            }
        );
        await getBrands().then(data => {
            this.setState({ availableBrands: data, errors : "" })
        }).catch(err =>
            this.setState({errors: "error while getting brands"})
        );
        await getMerchands().then(data => {
            this.setState({ availableMerchands: data, errors : "" })
        }).catch(err =>
            this.setState({errors: "error while getting merchands"})
        );
        await getColors().then(data => {
            this.setState({ availableColors: data, errors : "" })
        }).catch(err =>
            this.setState({errors: "error while getting colors"})
        );
        await getCategories(this.state.headCategory).then(data => {
            this.setState({ availableCategories: data, errors : "" })
        }).catch(err =>
            this.setState({errors: "error while getting categories"})
        );
        await getPosts().then(data => {
            this.setState({ availablePosts: data, errors : "" })
        }).catch(err =>
            this.setState({errors: "error while getting posts"})
        );
    };

    async filterList(keyword){
        const excludeColumns = ["id", "color", "posts"];
        const lowercasedValue = keyword.toLowerCase().trim();

        if(lowercasedValue.length > 5){
            this.setState({initialProducts: null, products: null, loading: true, lastEvaluatedKey: null, hasMoreValue: false});
            await getSearchProducts(this.props.location.pathname, lowercasedValue).then((data) => {
                this.setState({initialProducts: data.products, loading: false, products: data.products, lastEvaluatedKey: null, hasMoreValue: false});
            })
        } else if (lowercasedValue == ""){
            await getProducts(this.state.headCategory).then(data => {
                this.setState({ initialProducts: data.products, products: data.products, lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
            }).catch(err =>{
                //localStorage.removeItem('token');
                //window.location.href="/";
                this.setState({errors: "error while getting products"})
                }
            );
        }
    }

    searchWithDebounce = debounce(this.filterList, 500)

    async fetchMoreProducts(headCategory, lastEvaluatedKey){
        console.log(lastEvaluatedKey)
        await getProducts(headCategory, lastEvaluatedKey).then(data => (  
            this.setState({ products: [...this.state.products, ...data.products ], lastEvaluatedKey: data.lastEvaluatedKey, hasMoreValue: data.lastEvaluatedKey ? true : false, errors : "", loading: false })
        )).catch(err =>{
            //localStorage.removeItem('token');
            //window.location.href="/";
            this.setState({errors: "error while getting products"})
            }
        );
    }

    sortList(type){
        let sortedList = this.state.products;
        switch (type) {
            case "asc":
                sortedList = sortedList.sort(function(a,b){
                    return a.name.localeCompare(b.name);
                })

                this.setState({products: sortedList});
                break;
            case "desc":
                sortedList = sortedList = sortedList.sort(function(a,b){
                    return b.name.localeCompare(a.name);
                })
                this.setState({products: sortedList});
                break;

            default:
                this.setState({products: sortedList});
        }
    }

    closeModal = () => this.setState({ isOpen: false, selectedProduct: {
            name : "",
            description: "",
            gender: "",
            price: 0.0,
            brand: "",
            categories: [],
            declinations: [],
            metaTitle:"",
            metaDescription:"",
        } });

    handleDrag = (ev) => {
        this.setState({dragId : ev.currentTarget.id});
    };

    handleDrop = (list) => {
        let now = new Date()
        now.setHours(now.getHours()-2)
        console.log(now)
        let i = 0
        const newBoxState = list.map((product, index) => {
            product.order = index

            /*if (product && product.name && (product.name.includes("chaussure") || product.name.includes("Chaussure"))){
                fetch('https://backapi.therunningcollective.fr/api/protected/'+"new-product", {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(product)
                })
                    .then(data => data.json())
                    .catch( err =>
                        console.log(err)
                    )
            }

            /*if (product.categories){
                product.categories = product.categories.filter(item => item !== "Black Friday")
            } else {
                console.log(product)
            }*/

            /*let toPost = false
            if (product.categories && product.enable && product.bestDeclinations.bestRetailer.amount > 29){
                if (product.categories.indexOf("Black Friday") > -1) {
                    //In the array!
                } else {
                    //Not in the array
                    let cat = product.categories.concat(['Black Friday'])
                const uniqueNames = cat.filter((val,id,array) => array.indexOf(val) == id);

                product.categories = uniqueNames
                toPost = true
                }
                

            }
            if (product.enable == false && product.brand == "Mizuno") {
                fetch('https://backapi.therunningcollective.fr/api/protected/'+"new-product", {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(product)
                })
                    .then(data => data.json())
                    .catch( err =>
                        console.log(err)
                    )
            }*/

            /*let toPost = false
            let declinations = []
            product.declinations.map(d => {
                let newD = d
                if (d.price == 0) {
                    d.analysedRetailers && d.analysedRetailers.map(r => {
                        if(r.retailerName == "HokaOneOne"){
                            newD.price = r.price
                            toPost = true
                        }
                    })
                }
                declinations.push(newD)
            })
            product.declinations = declinations

            if (toPost){
                console.log(product.name)
                fetch('https://backapi.therunningcollective.fr/api/protected/'+"new-product", {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(product)
                })
                    .then(data => data.json())
                    .catch( err =>
                        console.log(err)
                    )
            }*/
            

            return product;
        });



        /*fetch('https://back-products-api.therunningcollective.fr/product-order', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newBoxState)
        }).then(this.props.closeModal)
            .catch( err =>
                console.log(err)
            )*/

        this.setState({products : newBoxState});

    };

    renderTooltip = (sizes) => (
        <Tooltip id="button-tooltip" >
            {sizes ?
                sizes.map((size) => {return (<div key={size}>{size}</div>)})
                : "Indisponible" }
        </Tooltip>
    );
    renderTooltipColor = (declinations) => (
        <Tooltip id="button-tooltip">
            {declinations ?
                declinations.map((declination) => {return (<div key={declination.reference}>{declination.color}</div>)})
                : "Indisponible" }
        </Tooltip>
    );
    renderTooltipBestDeclination = (bestDeclinations) => (
        <Tooltip id="button-tooltip" >
            {bestDeclinations ?
                 <div key={bestDeclinations.reference}>
                     <div>Référence : {bestDeclinations.reference}</div>
                     <div>Coloris : {bestDeclinations.color}</div>
                     <div>
                         <img className="imgToDisplay" alt="Couverture actuelle" src={bestDeclinations.firstImg}/>
                     </div>
                     <br/>
                 </div>
                : "Indisponible" }
        </Tooltip>
    );

    render(){
        return(
            <div className="products-list">
                <Row>
                    <Col sm={8} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un produit</Button>
                    </Col>
                    <Col sm={4}>
                        <FormControl type="text" placeholder="Rechercher par nom..." onChange={(e) => this.searchWithDebounce(e.target.value)}/>
                    </Col>
                </Row>
                {this.state.products == null ? <p> Chargement ...</p> :

                <div>
                    <Row>
                        <Col sm={8} className="products-buttons">
                            <span>Nombre de produits : {this.state.products.length}</span>
                        </Col>
                        
                    </Row>
                    <Table responsive>

                        <tbody>

                        

                        {(this.state.loading !== true)
                            ?

                            <InfiniteScroll
                                scrollThreshold="50%"
                                style={{overflow: "inherit"}}
                                dataLength={this.state.products.length}
                                next={() => this.fetchMoreProducts(this.state.headCategory, this.state.lastEvaluatedKey)}
                                hasMore={this.state.hasMoreValue}
                                loader={<div style={{textAlign: 'center'}}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>}
                            >
                                <tr
                                        key={0}
                                        id="begin"
                                        style={{textAlign: 'center', fontWeight: 800}}
                                    >
                                        <td>Prioritaire</td>
                                        <td>Actif</td>
                                        <td>Nom</td>
                                        <td>Marque</td>
                                        <td>Sexe</td>
                                        <td>Prix Public</td>
                                        <td>Couleurs</td>
                                        <td>Meilleur revendeur</td>
                                        <td>Meilleur prix</td>
                                        <td>Réduction</td>
                                        <td>Tailles dispos</td>
                                        <td>Poids</td>
                                        <td>Drop</td>
                                        <td>Stack</td>
                                        <td>Max Kms</td>
                                        <td>Intégré au blog ?</td>
                                        <td>Heure de comparaison</td>
                                        <td>Afficher sur le site</td>
                                        <td>Forcer la comparaison</td>
                                        <td>Modifier</td>
                                        <td>Supprimer</td>
                                    </tr>
                                {!this.state.loading ? this.state.products.map((product) => (
                                    <tr
                                        key={product.order}
                                        id={product.id}
                                        style={{textAlign: 'center'}}
                                    >
                                        <td>
                                        {product.priorityProduct ?
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Produit prioritaire</Tooltip>}
                                            >
                                                <Star/>
                                            </OverlayTrigger>
                                             : null}</td>
                                        <td>{product.active === true ? <Badge pill variant="success">
                                            Actif
                                        </Badge> : <Badge pill variant="danger">
                                            Inactif
                                        </Badge>}</td>
                                        <td>{product.name}</td>
                                        <td>{product.brand}</td>
                                        <td>{product.gender}</td>
                                        <td>{product.bestDeclinations.price}€</td>

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={this.renderTooltipColor(product.declinations)}
                                        >
                                            <td>{product.declinations ? product.declinations.length : 0 }</td>
                                        </OverlayTrigger>
                                        <td>{product.bestDeclinations.bestRetailer.retailerName}</td>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={this.renderTooltipBestDeclination(product.bestDeclinations)}
                                        >
                                            <td>{product.bestDeclinations.bestRetailer.price}€</td>
                                        </OverlayTrigger>
                                        <td>{Math.round((product.bestDeclinations.price - product.bestDeclinations.bestRetailer.price) / product.bestDeclinations.price * 100)}%</td>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={this.renderTooltip(product.bestDeclinations.bestRetailer.sizes)}
                                        >
                                            <td>{product.bestDeclinations.bestRetailer.sizes ? product.bestDeclinations.bestRetailer.sizes.length : 0 }</td>
                                        </OverlayTrigger>
                                        <td>{product.weigh != "" ? product.weigh : ""}</td>
                                        <td>{product.drop != "" ? product.drop : ""}</td>
                                        <td>{product.stack != "" ? product.stack : ""}</td>
                                        <td>{product.maxkm != "" ? product.maxkm : ""}</td>
                                        <td>{product.blogIntegratedShortURL != "" ? "Article intégré" : ""}</td>
                                        <td><Moment to={product.updatedDate} locale="fr">{Date.now()}</Moment></td>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Afficher le produit sur le site</Tooltip>}
                                        >
                                            <td><BootstrapSwitchButton
                                                checked={product.enable}
                                                onlabel='oui'
                                                onstyle='success'
                                                offlabel='non'
                                                offstyle='danger'
                                                size='xs'
                                                onChange={(checked: boolean) => {
                                                    updateState(product, checked)
                                                }}
                                            /></td>
                                        </OverlayTrigger>

                                        {product.isComparing
                                        ?
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Comparaison du produit en cours</Tooltip>}
                                            >
                                                <td className="table-action" style={{cursor: 'not-allowed', opacity: '0.33'}}>
                                                    <Activity className="align-middle mr-1" size={18} onClick={(e) => forceCompare(product)}/>
                                                </td>
                                            </OverlayTrigger>
                                        :
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Forcer la comparaison du produit</Tooltip>}
                                            >
                                                <td className="table-action" style={{cursor: 'pointer'}}>
                                                    <Shuffle className="align-middle mr-1" size={18} onClick={(e) => forceCompare(product)}/>
                                                </td>
                                            </OverlayTrigger>
                                            }
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Modifier le produit</Tooltip>}
                                        >
                                            <td className="table-action" style={{cursor: 'pointer'}}>
                                                <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedProduct : product})}/>
                                            </td>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Supprimer le produit</Tooltip>}
                                        >
                                            <td className="table-action" style={{cursor: 'pointer'}}>
                                                <Trash className="align-middle" size={18} onClick={(e) => this.setState({isDelete : true, selectedProduct : product})} />
                                            </td>
                                        </OverlayTrigger>



                                    </tr>
                                )) : null }
                            </InfiniteScroll>
                        :
                        null }
                        </tbody>
                    </Table>
                </div>
                }
                { this.state.isOpen ?
                    <UpdateModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedProduct={this.state.selectedProduct}
                        availableBrands={this.state.availableBrands}
                        availableMerchands={this.state.availableMerchands}
                        availableColors={this.state.availableColors}
                        availableCategories={this.state.availableCategories}
                        availableTypes={this.state.availableTypes}
                        availablePosts={this.state.availablePosts}
                        headCategory={this.state.headCategory}
                        order={this.state.selectedProduct.order !== 0 ? this.state.selectedProduct.order : (this.state.products ? this.state.products.length+1 : 1)}
                    />
                    :
                    null
                }
                { this.state.isDelete ?
                    <DeleteProductModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedProduct}
                    />
                    :
                    null
                }
            </div>
        );
    }
}