import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component, useEffect, useState }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';
import { Spinner } from 'reactstrap';

function AddAmbassadorModal({isOpen, closeModal}) {
    const [selectedUser, setSelectedUser] = useState(null)
    const [searchedUsers, setSearchededUsers] = useState([])
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [searchedData, setSearchedData] = useState(null)
    const [loadingData, setLoadingData] = useState(false)
    const [containsData, setContainsData] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const onTagsChange = (values) => {
        if (values){
            setLoadingData(true);
            fetch('https://back-api.therunningcollective.fr/searched-front-users?searched_data=' + values, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => { return response.json(); })
            .then(responseData => {return responseData;})
            .then(data => {console.log(data); setSearchededUsers(data); setLoadingData(false); setContainsData(true)})
            .catch( err =>
                console.log(err)
            );
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onTagsChange(searchedData)
        }, 2000)

        return () => clearTimeout(delayDebounceFn)

    }, [searchedData])

    const handleClose = (selectedUser) => {
        setLoadingSubmit(true)
        let user = {}
        user.isAmbassadorApproved = true
        user.email = selectedUser
        return fetch('https://back-api.therunningcollective.fr/ambassador-approved-front-user', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(() => {
            setLoadingSubmit(false);
            window.location.reload();
        })
            .catch( err =>
                setLoadingSubmit(false)
            )
    }


    return(
        <Modal
            show={isOpen}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un ambassadeur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Form.Group >
                                        <Form.Group >
                                            <Form.Label>Rechercher un utilisateur : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => setSearchedData(e.target.value)} placeholder="antoine"/>
                                        </Form.Group>
                                    </Form.Group>
                                    {
                                        loadingData ?
                                        <Row style={{marginTop: "30px", textAlign:"center"}}>
                                            <Col>
                                                <div style={{textAlign: 'center'}}>
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden"></span>
                                                    </Spinner>
                                                </div>
                                            </Col>
                                        </Row>
                                        : containsData ?
                                        <Form.Group >
                                            <Form.Label>Liste des utilisateurs trouvés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => setSelectedUser(e.target.value)}
                                            >
                                                <option >Ajouter...</option>
                                                {
                                                    searchedUsers && searchedUsers.length > 0 && searchedUsers.map(user => {
                                                        return(
                                                            <option key={user.email} value={user.email}>{user.firstName} {user.name} ({user.email})</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group> : null
                                    }
                                    
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                {
                    loadingSubmit ?
                    <Row style={{textAlign:"center"}}>
                        <Col>
                            <div style={{textAlign: 'center'}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>
                    :
                    <>
                        <Button variant="secondary" type="cancel" onClick={closeModal}>
                            Annuler
                        </Button>
                        {
                            selectedUser ?
                                <Button variant="primary" type="submit" onClick={() => handleClose(selectedUser)}>
                                    Valider
                                </Button>
                                : <Button variant="primary" type="submit" disabled>
                                    Valider
                                </Button>

                        }
                    </>
                
            }
                
            </Modal.Footer>
        </Modal>
    )
}

export default AddAmbassadorModal;

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.userID)
            )
        )
    }

    return arrayToReturn
}

function getSelectedItem(item){
    var arrayToReturn = []
    if(item && item.userID && item.userID != ""){
        arrayToReturn.push(item.userID)
    }
    return arrayToReturn
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}