import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Accordion, Tabs} from "react-bootstrap";
import {sleep} from "./AddBrandModal";
import {Trash} from "react-feather";
import Moment from "moment";

export default class UpdateRaceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstImg: null,
            imgsSelected: [],
            selectedRaceToModify: null,
            newDeclinaisonMerchandName: "",
            newDeclinaisonMerchandUrl: "",
            newDeclination: {
                label : "",
                price: 0,
            },
            numberOfCharMetaTitle: 0,
            numberOfCharMetaDescription:0,
            numberOfNewDeclination: 1
        };
        this.uploadFirstImg = this.uploadFirstImg.bind(this);
    }

    handleClose = () => (
        fetch('https://back-api.therunningcollective.fr/race', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedRace)
        })
            .then(async response =>
                await sleep(2000).then(
                    () => (this.props.closeModal,
                    window.location.reload(false))
                ),
            )
            .catch( err =>
                console.log(err)
            )

        );

    addNewMerchandDeclinaison(declinaiton){
        declinaiton.retailers.push({
            retailerName: this.state.newDeclinaisonMerchandName,
            url: this.state.newDeclinaisonMerchandUrl
        })
        this.setState({newDeclinaisonMerchandName: ""})
        this.setState({newDeclinaisonMerchandUrl: ""})
    }

    removeMerchandDeclinaison(declinaiton, name){
        let list = declinaiton.retailers.filter((retailer, index) => retailer.retailerName !== name)
        declinaiton.retailers = list
        this.setState({newDeclinaisonMerchandName: ""})
        this.setState({newDeclinaisonMerchandUrl: ""})
    }

    uploadFirstImg = async (e, declinaiton) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "races/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        declinaiton.image = result.message
                        sleep(1000)
                        this.setState({firstImg:  result.message});
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    addNewDeclination(race){
        race.events.push(this.state.newDeclination)
        this.setState({numberOfNewDeclination: race.events.length})
    }

    removeDeclination(product, reference){
        let list = product.events.filter((declination, index) => declination.label !== reference)
        product.events = list
        let nb = this.state.numberOfNewDeclination
        nb--
        this.setState({numberOfNewDeclination: nb})
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifier une course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Informations générales</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Distances </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">SEO </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.name = e.target.value, this.props.selectedRace.shortURL = slugify(e.target.value))} defaultValue={this.props.selectedRace.name} placeholder="Ex : Le marathon vert"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => this.props.selectedRace.description = e.target.value} defaultValue={this.props.selectedRace.description} placeholder="Cette course est ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-first-img-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => {this.uploadFirstImg(e, this.props.selectedRace)}}
                                                custom
                                            />
                                            {this.props.selectedRace.image && this.props.selectedRace.image != "" ?
                                                <img className="imgToDisplay" alt="Couverture actuelle" src={this.props.selectedRace.image}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Type de course : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedRace.type = e.target.value}
                                                defaultValue={this.props.selectedRace.type}
                                            >
                                                <option key="" value=""></option>
                                                <option key="athletisme" value="athletisme">Athlétisme</option>
                                                <option key="running" value="running">Running</option>
                                                <option key="trail" value="trail">Trail</option>
                                                <option key="triathlon" value="triathlon">Triathlon</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Course sponsorisée : </Form.Label>
                                            <Form.Check aria-label="showProduct"
                                                        defaultChecked={this.props.selectedRace.isSponsored}
                                                        onChange={(e) => this.props.selectedRace.isSponsored = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Sous-Catégorie : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedRace.subCategory = e.target.value}
                                                defaultValue={this.props.selectedRace.subCategory}
                                            >
                                                <option key="" value=""></option>
                                                <option key="0" value="Cross">Cross</option>
                                                <option key="1" value="Piste">Piste</option>
                                                <option key="2" value="Salle">Salle</option>
                                                <option key="3" value="Duathlon">Duathlon</option>
                                                <option key="4" value="Swin Run">Swin Run</option>
                                                <option key="5" value="Aquathlon">Aquathlon</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Label : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedRace.label = e.target.value}
                                                defaultValue={this.props.selectedRace.label}
                                            >
                                                <option key="" value=""></option>
                                                <option key="0" value="Label régional">Label régional</option>
                                                <option key="1" value="Label national">Label national</option>
                                                <option key="2" value="Label national">Label international</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Ranking : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedRace.ranking = e.target.value}
                                                defaultValue={this.props.selectedRace.ranking}
                                            >
                                                <option key="" value=""></option>
                                                <option key="0" value="Ranking F">Ranking F</option>
                                                <option key="1" value="Ranking E">Ranking E</option>
                                                <option key="2" value="Ranking D">Ranking D</option>
                                                <option key="3" value="Ranking C">Ranking C</option>
                                                <option key="4" value="Ranking B">Ranking B</option>
                                                <option key="5" value="Ranking A">Ranking A</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Ville : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.town = e.target.value)} defaultValue={this.props.selectedRace.town} placeholder="Ex : Rennes"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Code postal : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.zipCode = e.target.value)} defaultValue={this.props.selectedRace.zipCode} placeholder="Ex : 35000"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Département (automatique) : </Form.Label>
                                            <Form.Control disabled type="text" onChange={(e) => (this.props.selectedRace.department = e.target.value)} defaultValue={this.props.selectedRace.department} placeholder="Ex : Ille-et-Vilaine"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Région (automatique) : </Form.Label>
                                            <Form.Control disabled type="text" onChange={(e) => (this.props.selectedRace.region = e.target.value)} defaultValue={this.props.selectedRace.region} placeholder="Ex : Bretagne"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Pays : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.country = e.target.value)} defaultValue={this.props.selectedRace.country} placeholder="Ex : France"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien de l'organisateur : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.raceLink = e.target.value)} defaultValue={this.props.selectedRace.raceLink} placeholder="Ex : https://...."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien d'inscription : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.registrationLink = e.target.value)} defaultValue={this.props.selectedRace.registrationLink} placeholder="Ex : https://...."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien du live : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.liveLink = e.target.value)} defaultValue={this.props.selectedRace.liveLink} placeholder="Ex : https://...."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Lien des photos de la course : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.racePicturesLink = e.target.value)} defaultValue={this.props.selectedRace.racePicturesLink} placeholder="Ex : https://...."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Identifiant de la course (automatique) : </Form.Label>
                                            <Form.Control disabled type="text" onChange={(e) => (this.props.selectedRace.partnerID = e.target.value)} defaultValue={this.props.selectedRace.partnerID} placeholder="Ex : 123456"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedRace.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedRace.beginDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de fin : </Form.Label>
                                            <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                          defaultValue={Moment(this.props.selectedRace.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedRace.endDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Heure de début : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.beginningHour = e.target.value)} defaultValue={this.props.selectedRace.beginningHour} placeholder="Ex : 10h30"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Prix de la course la moins chers : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedRace.lowPrice = e.target.value)} defaultValue={this.props.selectedRace.lowPrice} placeholder="Ex : 5€"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Tabs id="uncontrolled-tab-example">
                                            {this.props.selectedRace.events && this.props.selectedRace.events.map((declinaiton, index) =>(
                                                <Tab key={index} eventKey={index} title={index}>
                                                    <Form.Group >
                                                        <Form.Label>Distance : </Form.Label>
                                                        <Form.Control type="text" onChange={(e) => declinaiton.distance = e.target.value} defaultValue={declinaiton.distance} placeholder="10km (pour une course) / M (pour un triathlon)"/>
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label>Nom de la course sur cette distance : </Form.Label>
                                                        <Form.Control type="text" onChange={(e) => declinaiton.label = e.target.value} defaultValue={declinaiton.label} placeholder="La course des benjamins"/>
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label>Heure de début : </Form.Label>
                                                        <Form.Control type="text" onChange={(e) => declinaiton.beginningHour = e.target.value} defaultValue={declinaiton.beginningHour} placeholder="10h30"/>
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label>Prix : </Form.Label>
                                                        <Form.Control type="text" onChange={(e) => declinaiton.price = e.target.value} defaultValue={declinaiton.price} placeholder="5"/>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Button variant="secondary" onClick={() => this.removeDeclination(this.props.selectedRace, declinaiton.label)}>Supprimer la distance</Button>
                                                    </Form.Group>
                                                </Tab>
                                            ))}
                                            <Tab eventKey={this.props.selectedRace.events ?
                                                this.props.selectedRace.events.length : 0} title={<Button variant= "secondary" onClick={() => this.addNewDeclination(this.props.selectedRace)}>+</Button>}>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Form.Group >
                                            <Form.Label>Balise Titre : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => {this.props.selectedRace.metaTitle = e.target.value; this.setState({numberOfCharMetaTitle: e.target.value})}} defaultValue={this.props.selectedRace.metaTitle ? this.props.selectedRace.metaTitle : this.props.selectedRace.name} placeholder="Ex : La course des saucisses"/>
                                            <Form.Text className="text-muted">
                                                {this.state.numberOfCharMetaTitle ? this.state.numberOfCharMetaTitle.length : this.props.selectedRace.metaTitle.length} des 70 caractères utilisés (recommandé)
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Meta Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedRace.metaDescription = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedRace.metaDescription ? this.props.selectedRace.metaDescription : this.props.selectedRace.description} placeholder="Cette course est ..."/>
                                            <Form.Text className="text-muted">
                                                {this.state.numberOfCharMetaDescription ? this.state.numberOfCharMetaDescription.length : this.props.selectedRace.metaDescription.length} des 160 caractères utilisés (recommandé)
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL simplifiée : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedRace.shortURL = e.target.value} defaultValue={this.props.selectedRace.shortURL ? this.props.selectedRace.shortURL : slugify(this.props.selectedRace.name)} placeholder="course-des-saucisses"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}
