import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Activity, Edit2, Filter, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HiOutlineViewList} from "react-icons/hi";
import DeleteForecastAthleteModal from "./modals/DeleteForecastAthleteModal";
import AddForecastAthleteModal from "./modals/AddForecastAthleteModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Moment from "moment";

function getForecastAthletesList() {

    return fetch('https://back-api.therunningcollective.fr/forecast-athletes', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getForecasts() {

    return fetch('https://back-api.therunningcollective.fr/forecasts', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}


function updateState(forecastAthlete, checked) {
    forecastAthlete.active = checked
    return fetch('https://back-api.therunningcollective.fr/forecast-athlete', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(forecastAthlete)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

function getForecastName(forecastID, forecasts){
    let output 
    forecasts.map(forecast => {
        if (forecast.id == forecastID){
            output = forecast.name
        }
    })
    //console.log(output)
    return output
}

export class ForecastAthletesList extends Component {
    state = {
        errors: null,
        selectedForecastAthlete: {
            name: "",
            type: "",
            beginDate: "",
            forecastID: "",
            gender: "",
        },
    }

    async componentDidMount() {
        await getForecasts().then(data => {
            this.setState({forecasts: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecasts"})
        );
        await getForecastAthletesList().then(data => {
            this.setState({forecastAthletes: data})
        }).catch(err =>
            this.setState({errors: "error while getting forecastAthletes"})
        );
    };

    closeModal = () => this.setState({
        isOpen: false,
        isDelete: false,
        isDrawing: false,
        selectedForecast: {
            title: "",
            body: "",
        }
    });


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"
                                onClick={(e) => this.setState({isOpen: true})}>Ajouter un(e) athlète</Button>
                    </Col>
                    
                    <Col sm={1} className="products-buttons">
                        <ToggleButtonGroup value={this.state.mode} type="checkbox">
                            <ToggleButton
                                key={2}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={"Liste"}
                                onChange={(e) => this.setState({mode: e.currentTarget.value})}
                            >
                                <HiOutlineViewList/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.forecastAthletes && this.state.forecastAthletes.length > 0 && this.state.errors == null) ? <span>{this.state.forecastAthletes.length} athlète(s)</span> : null }
                    </Col>
                </Row>
                { this.state.errors != null ? <p>"Erreur lors de la récupération des épreuves, veuillez vous reconnecter"</p> : null}

                { (!this.state.forecastAthletes && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Afficher</th>
                            <th>Nom</th>
                            <th>Sexe</th>
                            <th>Anniversaire</th>
                            <th>Discipline / Ranking</th>
                            <th>Nationalité</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.forecastAthletes.map(forecastAthlete => (
                            <tr>
                                <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Afficher l'épreuve sur le site</Tooltip>}
                                        >
                                            <td><BootstrapSwitchButton
                                                checked={forecastAthlete.active}
                                                onlabel='oui'
                                                onstyle='success'
                                                offlabel='non'
                                                offstyle='danger'
                                                size='xs'
                                                onChange={(checked: boolean) => {
                                                    updateState(forecastAthlete, checked)
                                                }}
                                            /></td>
                                        </OverlayTrigger>
                                <td>{forecastAthlete.name}</td>
                                <td>{forecastAthlete.gender}</td>
                                <td>{Moment(forecastAthlete.birthday).format('YYYY-MM-DD').toString()}</td>
                                <td>{forecastAthlete.distances ? forecastAthlete.distances.map(distance => {
                                    return(<div>{distance.distance} / {distance.ranking}</div>)
                                }) : null}</td>

                                <td>
                                    {
                                        forecastAthlete.countryFlag && forecastAthlete.countryFlag != "" ?
                                        <p><img style={{
                                                maxWidth: '100px',
                                            }} src={forecastAthlete.countryFlag}/> {forecastAthlete.country} </p>
                                        : null
                                    }
                                    
                                </td>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Modifier l'athlète</Tooltip>}
                                    >
                                        <td className="table-action" style={{cursor: "pointer"}}>
                                            <Edit2 className="align-middle mr-1" size={18}
                                                onClick={(e) => this.setState({isOpen: true, selectedForecastAthlete: forecastAthlete})}/>
                                        </td>
                                    </OverlayTrigger>
                                
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer l'athlète</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedForecastAthlete: forecastAthlete})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddForecastAthleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedForecastAthlete={this.state.selectedForecastAthlete}
                        availableUsers={this.state.availableUsers}
                        availableTypes={this.state.availableTypes}
                        forecasts={this.state.forecasts}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteForecastAthleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedForecastAthlete}
                        selectedCategory="forecastAthlete"
                    />
                    :
                    null
                }
            </div>
        );

    }
}