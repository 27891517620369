import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeletePhysicalShopOptionModal from "./modals/DeletePhysicalShopOptionModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import AddPhysicalShopOptionModal from "./modals/AddPhysicalShopOptionModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {CiWarning} from "react-icons/ci";

function getPhysicalShopOptions() {

    return fetch('https://back-api.therunningcollective.fr/physical-shop-options', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(physicalShopOption, checked) {
    physicalShopOption.state = checked
    return fetch('https://back-api.therunningcollective.fr/physical-shop-option', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(physicalShopOption)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class PhysicalShopOptionsList extends Component{
    state = {
        physicalShopOptions: [],
        countOfEmptyDescription: 0,
        countOfEmptyEvents: 0,
        isOpen: false,
        isVouchersOpen: false,
        errors: null,
        selectedPhysicalShopOption : {
            name: "",
        }
    }

    async componentDidMount() {
        await getPhysicalShopOptions().then(data => {
            this.setState({ physicalShopOptions: data ? data : [] })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting physicalShopOptions"})
        );
    };

    closeModal = () => this.setState({ isOpen: false, selectedPhysicalShopOption : {
        name: "",
    } });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={9} className="products-buttons">
                        <span>Nombre d'offre : {this.state.physicalShopOptions.length}</span>
                    </Col>
                    

                    <Col sm={3} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter une offre</Button>
                    </Col>
                </Row>
                { (!this.state.physicalShopOptions) ? <p> Aucune offre disponible.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Prix mensuel</th>
                            <th>Prix d'un RDV</th>
                            <th>Taux de commission</th>
                            <th>RDV Gratuits (par mois)</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.physicalShopOptions.length > 0 && this.state.physicalShopOptions.map(physicalShopOption => (
                            <tr key={physicalShopOption.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Activer l'offre</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={physicalShopOption.state}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(physicalShopOption, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{physicalShopOption.name}</td>
                                <td>{physicalShopOption.monthlyPrice}€</td>
                                <td>{physicalShopOption.appointmentPrice}€</td>
                                <td>{physicalShopOption.commission}%</td>
                                <td>{physicalShopOption.freeAppointment}</td>
                                <td className="table-action">
                                    <Edit2 className="align-middle mr-1" size={18} onClick={(e) => this.setState({isOpen : true, selectedPhysicalShopOption : physicalShopOption})}/>
                                </td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedPhysicalShopOption: physicalShopOption})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

    
                { this.state.isOpen ?
                    <AddPhysicalShopOptionModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedPhysicalShopOption={this.state.selectedPhysicalShopOption}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeletePhysicalShopOptionModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedPhysicalShopOption}
                        selectedCategory="physical-shop-option"
                    />
                    :
                    null
                }
            </div>
        );

    }

}