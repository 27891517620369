import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';

export default class AddCoinsModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedUsers: this.props.selectedCoin.user ? [this.props.selectedCoin.user] : null,
        };
    }

    typeSelection = (e) => {
        this.props.selectedCoin.types = [...e.target.selectedOptions].map(o => this.props.availableTypes.find(type =>  type === o.value));
        if(this.props.availableUsers && this.props.selectedCoin.types) {
            let selectedUsers = []
            this.props.availableUsers.map(user => {
                this.props.selectedCoin.types.map(type => {
                    if (user.type == type) {
                        selectedUsers.push(user)
                    }
                })
            })
            this.setState({selectedUsers : selectedUsers})
        }
    }

    handleClose = () => {
        if (this.props.selectedCoin && this.state.selectedUsers && this.state.selectedUsers.length > 0){
            let arrayOfUsers = sliceIntoChunks(this.state.selectedUsers, 1)
            if (arrayOfUsers.length > 0) {
                for (let i=0; i < arrayOfUsers.length; i++){
                    let user = arrayOfUsers[i]
                    let coin = this.props.selectedCoin
                    coin.user = user[0]

                    if (i == (arrayOfUsers.length-1)){
                        fetch('https://back-api.therunningcollective.fr/coin', {
                            method: 'PUT',
                            headers: {
                                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(coin)
                        })
                            .then( this.props.closeModal)
                            .catch( err =>
                                console.log(err)
                            );
                    } else {
                        fetch('https://back-api.therunningcollective.fr/coin', {
                            method: 'PUT',
                            headers: {
                                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(coin)
                        })
                            .catch( err =>
                                console.log(err)
                            );
                    }

                }
            }

        }
    }
    
    render(){
        console.log(this.props.selectedCoin)
        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter des TRCoins à un utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedCoin.name = e.target.value} defaultValue={this.props.selectedCoin.name} placeholder="Ajout de 100 TRCoins"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedCoin.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedCoin.description ? this.props.selectedCoin.description : this.props.selectedCoin.description} placeholder="Pour avoir réalisé cette action"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nombre de TRCoins : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedCoin.count = parseInt(e.target.value,10)} defaultValue={this.props.selectedCoin.count} placeholder="100"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Profils concernés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.typeSelection(e) }}
                                                defaultValue={getSelectedItems(this.props.selectedCoin.types)}
                                            >
                                                {this.props.availableTypes.map((product, index) =>
                                                    <option key={product} value={product}>{product}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Utilisateurs concernés : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => {this.setState({selectedUsers : [...e.target.selectedOptions].map(o => this.props.availableUsers.find(user =>  user.userID === o.value))})}}
                                                defaultValue={getSelectedItem(this.props.selectedCoin.user)}
                                            >
                                                {this.props.availableUsers.map((user, index) =>
                                                    <option key={user.userID} value={user.userID}>{user.firstName + " " + user.name + " (" + user.email + ")"}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => this.handleClose()}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function getSelectedItems(items){
    var arrayToReturn = []

    if (items) {
        items.map(item =>(
                arrayToReturn.push(item.userID)
            )
        )
    }

    return arrayToReturn
}

function getSelectedItem(item){
    var arrayToReturn = []
    if(item && item.userID && item.userID != ""){
        arrayToReturn.push(item.userID)
    }
    return arrayToReturn
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}