import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Badge, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteWheelSpinLinkModal from "./modals/DeleteWheelSpinLinkModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import UpdateRaceForm from "./modals/UpdateRaceModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {CiWarning} from "react-icons/ci";
import UpdateWheelSpinModal from "./modals/UpdateWheelSpinModal";
import UpdateWheelSpinLinkModal from "./modals/UpdateWheelSpinLinkModal";

function getWheelSpinsLinks() {

    return fetch('https://back-api.therunningcollective.fr/wheel-spin-links', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function getUsers() {

    return fetch('https://back-api.therunningcollective.fr/csv-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class WheelSpinLinksList extends Component{
    state = {
        wheelsSpin: [],
        rewards: [],
        isOpen: false,
        errors: null,
        selectedWheelSpin : {

        }
    }

    async componentDidMount() {
        await getUsers().then(data => {
            const uniqueNames = data
                .map(e => e['email'])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => data[e]).map(e => data[e]);
            this.setState({availableUsers: uniqueNames})
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );
        await getWheelSpinsLinks().then(data => {
            this.setState({ wheelsSpin: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting wheelsSpin"})
        );
    };

    closeModal = () => this.setState({ isOpen: false });

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={3} className="products-buttons">
                        <span>Nombre de liens générés : {this.state.wheelsSpin ? this.state.wheelsSpin.length : 0}</span>
                    </Col>

                    {
                        this.state.availableUsers && this.state.availableUsers.length > 0 ?
                            <Col sm={3} className="products-buttons">
                                <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpen : true})}>Ajouter un lien</Button>
                            </Col>
                            : null
                    }

                </Row>
                { (!this.state.wheelsSpin) ? <p> Aucun lien généré.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr> <th></th>
                            <th>Utilisateur</th>
                            <th>Email</th>
                            <th>Date de fin</th>
                            <th>Lien</th>
                            <th>Reward obtenu</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.wheelsSpin.length > 0 && this.state.wheelsSpin.map(wheelSpin => (
                            <tr key={wheelSpin.id}>
                                <td>{wheelSpin.isProcessed === true ? <Badge pill variant="success">
                                    Utilisé
                                </Badge> : <Badge pill variant="danger">
                                    Non utilisé
                                </Badge>}</td>
                                <td>{wheelSpin.user ? wheelSpin.user.firstName : ""} {wheelSpin.user ? wheelSpin.user.name : ""}</td>
                                <td>{wheelSpin.user ? wheelSpin.user.email : ""}</td>
                                <td>{wheelSpin.endDate}</td>
                                <td>https://app.therunningcollective.fr/wheel-spin/{wheelSpin.id}</td>
                                <td>{wheelSpin.associatedReward.description}</td>
                                <td className="table-action">
                                    <Trash className="align-middle" size={18}onClick={(e) => this.setState({isDelete: true, selectedWheelSpin: wheelSpin})} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                { this.state.isOpen ?
                    <UpdateWheelSpinLinkModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedWheelSpin={this.state.selectedWheelSpin}
                        availableUsers={this.state.availableUsers}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteWheelSpinLinkModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedWheelSpin}
                        selectedCategory="wheel-spin-link"
                    />
                    :
                    null
                }
            </div>
        );

    }

}