import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";


export default class AddLanguageModalForm extends Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedLogo: this.props.selectedLanguage.s3Flag
        };
    }

    handleClose = () => fetch('https://rby6ypm4ta.execute-api.eu-west-3.amazonaws.com/prod/language', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.selectedLanguage)
    })
        .then( () => (this.props.closeModal, window.location.reload(false)))
        .catch( err =>
            console.log(err)
        );

        uploadLogo = async (e) => {
            const file = e.target.files[0]
            const fileName = e.target.files[0].name
            const reader = new FileReader()
    
            reader.readAsDataURL(file)
    
            reader.onload = () => {
                fetch('https://back-api.therunningcollective.fr/upload-file', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        blob:reader.result,
                        fileName: "languages/"+fileName
                    })
                }).then((res) =>
                    res.json().then( result =>{
                            sleep(2000)
                            this.props.selectedLanguage.s3Flag = result.message
                            this.setState({selectedLogo: result.message})
                        }
                    )
                )
                    .catch( err =>
                        console.log(err)
                    );
            }
        }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une langue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedLanguage.name = e.target.value} defaultValue={this.props.selectedLanguage.name} placeholder="Ex : fr"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Drapeau : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}